import GaugeChart from '../components/gauge-chart/gauge-chart.vue'
import BarChart from '../components/bar-chart/bar-chart.vue'
import talentUtilizationService from './talent-utilization-service';
import { concatMap } from "rxjs/operators";
import QueryString from 'query-string';
import { copyToClipboard, dummyObservable, formatDateObject } from "../utils/utils";

export default {
  name: 'talent-utilization',
  components: {
    GaugeChart,
    BarChart
  },
  data: function () {
    return {
      talentUtilizationService : talentUtilizationService,
      reportTypeFilter : 'Talent',
      filterLimit : 2,
      filterOptions: [],
      utilizationSummaryDataLoading: false,
      utilizationDetailedDataLoading: false,
      activeTab: '',
      locationFilterOptions: [],
      selectedFilterName : [],
      locationSelected : [],
      selectedDateRange : [],
      viewType : 'Project',
      showLifetime : false,
      location : '',
      threshold : 100,
      gaugeChartConfig : {},
      utilizationSummary : [],
      utilizationDetailed : [],
      yAxisLabel: 'Hours',
      barChartConfig: {},
      datePickerOptions : {},
      currentDetailedCollection: [],
      queryStringPresent: false,
      tableKey: 0
    }
  },
  watch: {
    reportTypeFilter(newVal) {
      this.setChartConfigs();  // Ensure the chart configuration updates when reportTypeFilter changes
    }
  },
  computed: {
    detailedTableHeight: function () {
      this.tableKey++;
      if (this.currentDetailedCollection.length > 7) {
        return 400;
      } else {
        return null;
      }
    },
    filterName() {
      switch (this.reportTypeFilter) {
        case 'Talent':
          return 'Talent';
        case 'Group':
          return 'Department';
        case 'Vertical':
          return 'Function/Portfolio';
        case 'Project':
          return 'Project';
        case 'Roles':
          return 'Roles';
        case 'Location':
          return 'Location';
        default:
          return '';
      }
    },
    utilizationProp() {
      return this.reportTypeFilter === 'Project' ? 'billableUtilization' : 'overallUtilization';
    },
  },
  created() {
    this.setChartConfigs();
    this.setDefaultDataRange();
    this.setDataPickerOptions();
    this.attachLoaderHandlers();
    this.queryStringHandler();
  },
  mounted() {
    if (this.queryStringPresent) {
      this.queryStringInit()
    } else {
      this.defaultInit();
    }
    this.initializeTooltip();
  },
  updated() {
    this.initializeTooltip();
  },
  methods: {
    refresh() {
      this.$nextTick(() => {
        let elTable = this.$children.find(a => a.$vnode.tag.indexOf('ElTable') >= 0);
      
        if (elTable) {
          let bodyAndHeaders = elTable.$children.filter(a => a.$vnode.tag.indexOf('ElTableBody') >= 0 || a.$vnode.tag.indexOf('ElTableHeader') >= 0)
          
          bodyAndHeaders.map(bodyOrHeader => {
            Object.keys(bodyOrHeader._computedWatchers).forEach(k => bodyOrHeader._computedWatchers[k].run())
            this.$nextTick(() => bodyOrHeader.$forceUpdate())
          });
        }
      })
    },
    defaultInit() {

      this.init$ = this.talentUtilizationService
      .getFilterOptions(this.reportTypeFilter)
      .pipe(
        concatMap(filterOptions => {
          this.filterOptions = filterOptions;
          return this.talentUtilizationService.getLocations();
        }),
        concatMap(locationFilterOptions => {
          this.locationFilterOptions = locationFilterOptions;
          return this.talentUtilizationService.getCurrentUser();
        }),
        concatMap(currentUser => {
          this.selectedFilterName = [ currentUser.id ];
          return this.getUtilizationSummary();
        }),
        concatMap(utilizationSummary => {
          this.setUtilizationSummary(utilizationSummary);
          return this.getUtilizationDetailed();
        })
      )
      .subscribe(
        this.setUtilizationDetailed.bind(this),
        error => {
          this.utilizationSummary = [];
          this.currentDetailedCollection = [];
          bootstrapNotify('Error in loading the report.\n Please retry', 'error');
        }
      );
    },
    queryStringInit() {
      let nameFiltersExists = true;

      this.init$ = this.talentUtilizationService
      .getFilterOptions(this.reportTypeFilter)
      .pipe(
        concatMap(filterOptions => {
          this.filterOptions = filterOptions;
          this.selectedFilterName = this.tempFilterName;
          return this.talentUtilizationService.getLocations();
        }),
        concatMap(locationFilterOptions => {
          this.locationFilterOptions = locationFilterOptions;
          nameFiltersExists = this.nameFiltersExists();
          if (nameFiltersExists) {
            return this.getUtilizationSummary();
          } else {
            this.selectedFilterName = [];
            bootstrapNotify(this.reportTypeFilter + ' requested in the URL is currently inactive.', 'info');
            return dummyObservable(null);
          }
        }),
        concatMap(utilizationSummary => {
          if (!nameFiltersExists) {
            return dummyObservable(null);
          }
          this.setUtilizationSummary(utilizationSummary);
          return this.getUtilizationDetailed();
        })
      )
      .subscribe(
        (utilizationDetailed) => {
          if (!nameFiltersExists) {
            return;
          }
          this.setUtilizationDetailed(utilizationDetailed)
        },
        error => {
          this.utilizationSummary = [];
          this.currentDetailedCollection = [];
          bootstrapNotify('Error in loading the report.\n Please retry', 'error');
        }
      );
    },
    nameFiltersExists() {
      let filterObj = [];
      if (this.selectedFilterName.length && this.selectedFilterName.length == 1) {
        filterObj = this.filterOptions.filter(item => {
          return (item.id === this.selectedFilterName[0]);
        })
      } else if (this.selectedFilterName.length && this.selectedFilterName.length == 2) {
        filterObj = this.filterOptions.filter(item => {
          return (item.id === this.selectedFilterName[0]) || (item.id === this.selectedFilterName[1]);
        })
      }
      return filterObj.length ? true : false;
    },
    setChartConfigs() {
      this.barChartSeries = [
        {
          key : 'loggedHours',
          label : 'Logged Hours',
          color : '#FF4200'
        },
        {
          key : 'unbilledHours',
          label : 'Remaining Planned Hours',
          color : '#DDFF20'
        }
      ];
      if (this.reportTypeFilter === 'Project') {
        this.gaugeChartConfig = {
          maxValue: 100,
          category : {
            key : 'name',
            color : '#6100B6'
          },
          bands: [
            {
              key : 'billableUtilization',
              label : 'Logged',
              color : '#31EED1'
            },
            {
              key : 'remainingUtilizationForProjectFilter',
              label : 'Available',
              color : '#050656'
            },
          ]
        }

      }else{
        this.gaugeChartConfig = {
          maxValue: 100,
          category : {
            key : 'name',
            color : '#6100B6'
          },
          bands: [
            {
              key : 'billablePercentage',
              label : 'Billable',
              color : '#FC6674'
            },
            {
              key : 'nonBillablePercentage',
              label : 'Non-Billable',
              color : '#31EED1'
            },
            {
              key : 'remainingUtilization',
              label : 'Available',
              color : '#050656'
            },
          ]
        }
      }
    },
    attachLoaderHandlers() {
      this.talentUtilizationService.utilizationSummaryDataLoading
      .subscribe(
        response => this.utilizationSummaryDataLoading = response
      );
      this.talentUtilizationService.utilizationDetailedDataLoading
      .subscribe(
        response => this.utilizationDetailedDataLoading = response
      );
    },
    setDataPickerOptions() {
      this.datePickerOptions = {
        shortcuts : [{
          text : 'Last week',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(end.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: 'Last month',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(end.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: 'Last 3 months',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(end.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      }
    },
    setDefaultDataRange() {
      const end = new Date();
      const start = new Date();
      start.setTime(end.getTime() - 3600 * 1000 * 24 * 30);
      this.selectedDateRange = [start, end];
    },
    getFilterOptions() {
      this.getFilter$ ? this.getFilter$.unsubscribe() : '';
      this.getFilter$ = this.talentUtilizationService.getFilterOptions(this.reportTypeFilter)
      .subscribe(
        response => {
          this.filterOptions = response;
          this.$refs.selectFilter.focus();
        },
        error => console.log(error)
      );
    },
    onFilterTypeChange(type) {
      if (this.reportTypeFilter === type) {
        return;
      }

      switch (type) {
        case 'Talent':
          this.viewType = 'Project';
          break;
        case 'Group':
        case 'Vertical':
          this.viewType = 'Talent';
          break;
        case 'Project':
          this.viewType = 'Talent';
          break;

        default:
          this.viewType = 'Talent';
          break;
      }
      this.reportTypeFilter = type;
      this.getFilterOptions();
      this.selectedFilterName = [];
    },
    handleViewChange(viewType) {
      if (this.viewType === viewType) {
        return;
      }

      this.viewType = viewType;
      this.handleView$ ? this.handleView$.unsubscribe() : '';
      this.handleView$ = this.getUtilizationDetailed()
      .subscribe(
        this.setUtilizationDetailed.bind(this),
        error => {
          this.currentDetailedCollection = [];
          bootstrapNotify('Error in loading the report.\n Please retry', 'error');
        }
      );
    },
    handleTabChange(tab, event) {
      this.currentDetailedCollection = this.utilizationDetailed[tab.index].collection;
      this.refresh();
    },
    loadUtilizationData() {
      if (this.selectedFilterName.length <= 0) {
        return;
      }
      this.$refs.selectFilter.blur();
      this.loadUtilizationData$ ? this.loadUtilizationData$.unsubscribe() : '';
      this.loadUtilizationData$ = this.getUtilizationSummary()
      .pipe(
        concatMap(
          response => {
            this.setUtilizationSummary(response);
            return this.getUtilizationDetailed();
          }
        )
      )
      .subscribe(
        this.setUtilizationDetailed.bind(this),
        error => {
          this.utilizationSummary = [];
          this.currentDetailedCollection = [];
          bootstrapNotify('Error in loading the report.\n Please retry', 'error');
        }
      );
    },
    getUtilizationSummary() {
      return this.talentUtilizationService.getUtilizationSummary(
        this.reportTypeFilter,
        this.selectedFilterName,
        this.selectedDateRange[0],
        this.selectedDateRange[1],
        this.threshold,
        this.locationSelected
      );
    },
    formatUtilizationSummary(summary) {
      let utilizationSummary = [];
      Object.keys(summary).forEach((key) => {
        let tempObj = {
          name: key,
          id: summary[key].id,
          loggedHours: Number(summary[key].loggedHours),
          billedHours: Number(summary[key].billedHours),
          unbilledHours: Number(summary[key].unbilledHours),
          totalCapacity: Number(summary[key].totalCapacity),
          overallUtilization: Number(summary[key].overallUtilization),
          plannedUtilization: Number(summary[key].plannedUtilization),
          billableUtilization: Number(summary[key].billableUtilization),
          nonBillableUtilization: Number(summary[key].nonBillableUtilization),
          remainingUtilization: Number(summary[key].remainingUtilization),
          billableHours: Number(summary[key].billableHours),
          nonBillableHours: Number(summary[key].nonBillableHours),
          billablePercentage: Number(summary[key].billablePercentage),
          nonBillablePercentage: Number(summary[key].nonBillablePercentage),
          remainingUtilizationForProjectFilter: Math.max(
            0, 
            Number((100 - Number(summary[key].billableUtilization)).toFixed(2))
          ),
        };
        utilizationSummary.push(tempObj);
      })
      return utilizationSummary;
    },
    setUtilizationSummary(utilizationSummary) {
      this.utilizationSummary = this.formatUtilizationSummary(utilizationSummary);
    },
    getUtilizationDetailed() {
      return this.talentUtilizationService.getUtilizationDetailed(
        this.viewType,
        this.reportTypeFilter,
        this.selectedFilterName,
        this.selectedDateRange[0],
        this.selectedDateRange[1],
        this.threshold,
        this.locationSelected
      );
    },
    formatUtilizationDetailed(detailed) {
      let utilizationDetailed = [];
      Object.keys(detailed).forEach((key) => {
        let tempObj = {
          name: key,
          id: detailed[key]['id'],
          collection: detailed[key].collection
        }
        utilizationDetailed.push(tempObj);
      })
      return utilizationDetailed;
    },
    setUtilizationDetailed(utilizationDetailed) {
      this.utilizationDetailed = this.formatUtilizationDetailed(utilizationDetailed);
      this.activeTab = this.utilizationDetailed[0].name;
      this.currentDetailedCollection = this.utilizationDetailed[0].collection;
      this.refresh();
    },
    queryStringHandler() {
      const queryString = QueryString.parse(window.location.search);
      if (!Object.keys(queryString).length) {
        this.queryStringPresent = false;
        return;
      }

      this.queryStringPresent = true;
      this.reportTypeFilter = queryString.reportTypeFilter;
      this.viewType = queryString.reportTypeFilter === 'Project' ? 'Talent' : 'Project';
      this.tempFilterName = (typeof queryString.nameFilters === 'string') ?
      [parseFloat(queryString.nameFilters)] : queryString.nameFilters.map(ele => parseFloat(ele));

      if (queryString.selectedDateRange) {
        this.selectedDateRange[0] = new Date(queryString.selectedDateRange[0]);
        this.selectedDateRange[1] = new Date(queryString.selectedDateRange[1]);
      }

      if (queryString.threshold) {
        this.threshold = parseFloat(queryString.threshold);
      }

      if (queryString.locationSelected) {
        this.locationSelected = (typeof queryString.locationSelected === 'string') ?
        [parseFloat(queryString.locationSelected)] : queryString.locationSelected.map(ele => parseFloat(ele));
      }
    },
    handleNameClick(id) {
      const selectedDateRangeClone = this.selectedDateRange.slice();
      const query = {
        viewType: this.viewType,
        reportTypeFilter: this.viewType,
        nameFilters: [id],
        selectedDateRange: selectedDateRangeClone.map(date => formatDateObject(date, '/')),
      }
      const stringified = QueryString.stringify(query);
      return window.location.origin + window.location.pathname + '?' + stringified;

    },
    copyUtilizationUrl(event) {
      if (event) event.preventDefault();
      const selectedDateRangeClone = this.selectedDateRange.slice();

      const query = {
        viewType: this.viewType,
        reportTypeFilter: this.reportTypeFilter,
        nameFilters: this.selectedFilterName,
        selectedDateRange: selectedDateRangeClone.map(date => formatDateObject(date, '/')),
        threshold: this.threshold,
        locationSelected: this.locationSelected
      }
      const stringified = QueryString.stringify(query);
      copyToClipboard(window.location.origin + window.location.pathname + '?' + stringified);
    },
    getTooltipTitle() {
      if (this.reportTypeFilter === 'Project') {
        return '( logged hours/ planned hours)*100';
      } else {
        return '( logged hours/ totalCapacity)*100';
      }
    },
    initializeTooltip() {
      if (this.$refs.tooltipIcon) {
        $(this.$refs.tooltipIcon).tooltip('dispose');
        $(this.$refs.tooltipIcon).tooltip({
          title: this.getTooltipTitle(),
          placement: 'left',
          trigger: 'hover'
        });
      }
    }
  },
  beforeDestroy() {
    this.init$ ? this.init$.unsubscribe() : '';
    this.getFilter$ ? this.getFilter$.unsubscribe() : '';
    this.handleView$ ? this.handleView$.unsubscribe() : '';
    this.loadUtilizationData$ ? this.loadUtilizationData$.unsubscribe() : '';
  }
}
import EventBus from '../../finance/accruals/project-accrual-report/global-event-bus'
export default {
  name: "el-week-picker",
  data() {
    return {
      week: this.selectedWeek,
      pickerOptions : {
        disabledDate(time) {
          return time.getTime() > Date.now();
        }
      }
    };
  },
  props: {
    weekFormat: {
      type: String,
      required: true
    },
    greyBg: {
      type: Boolean,
      required: false,
      default: false
    },
    selectedWeek: {
      required: true
    }
  },
  created() {
    EventBus.$on('onSnapshotWeekChange', selectedWeek => {
      this.week = selectedWeek;
    });
  },
  mounted() {},
  methods: {
    sendSelectedWeekToParent() {
      this.$emit("update:selectedWeek", this.week);
      this.$emit("updatedWeek", this.week);
    }
  },
  watch: {}
};

import EventBus from '../../finance/accruals/project-accrual-report/global-event-bus'

export default {
    name: 'metronic-table',
    data() {
        return {
            filterParams: {},
            sortOrder: 'asc'
        }
    },
    props: {
        actionBtns: {},
        currentUser: {
            id: null
        },
        headers: {
            type: Array,
            required: true
        },
        loader: {
            type: Boolean,
            required: true
        },
        tableData: {
            type: Array,
            required: true
        },
    },
    created() { },
    watch: {
        // currentUser(val) {
        //     this.currentUser = val;
        // },
        headers(newVal) {
            this.headers = newVal;
        },
        tableData(newVal) {
            if (newVal) {
                this.tableData = newVal;
            } else {
                this.tableData = [];
            }
        },
        loader(newVal) {
            if (newVal) {
                this.loader = newVal;
            } else {
                this.loader = false;
            }
        }
    },
    computed: {
        // updateAllProjectStatus() {
        //     if (this.currentUser && this.currentUser.permissions["AccrualPolicy"] === "modify") {
        //         return true;
        //     } else {
        //         return false;
        //     }
        // }
    },
    mounted() {

    },
    methods: {
        emitEvent(id, value, eventName = null, reload = false) {
            if (eventName) {
                EventBus.$emit(eventName, {
                    'id': id,
                    'value': value,
                    'reload': reload
                });
            }   
        },
        getFilterData(sort = false, sortKey = '', search = false, eventName = '') {
            if (sort) {
                this.filterParams.sort = this.sortColumn(sortKey);
            }
            if (search) {
                this.filterParams.search = this.getSearchFilter()
            }
            eventName = eventName ? eventName : (this.filterParams.sort ? this.filterParams.sort.eventName : '');
            eventName ? EventBus.$emit(eventName, this.filterParams) : '';
        },
        getSearchFilter() {
            let searchAccrualFormData = {};
            this.headers.forEach(element => {
                if (element.search.required) {
                    searchAccrualFormData[element.key] = element.search.type === 'text' ? element.search.defaultValue : element.search.selectedValue;
                }
            });
            return searchAccrualFormData;
        },
        resetFilter(eventName) {
            this.filterParams = {};
            let defaultSearchData = {};
            this.headers.forEach(element => {
                if (element.search.required) {
                    if (element.search.type === 'text') {
                        element.search.defaultValue = null;
                        defaultSearchData[element.key] = null;
                    } else {
                        element.search.selectedValue = element.search.defaultValue;
                        //params
                        defaultSearchData[element.key] = element.search.defaultValue;
                        this.filterParams.search = defaultSearchData;

                    }
                }
            });
            EventBus.$emit(eventName, this.filterParams);
        },
        sortColumn(colName) {
            this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
            let eventName;
            let sort = {};
            this.headers.forEach(element => {
                if (element.key === 'action' && element.buttons) {
                    element.buttons.forEach(item => {
                        if (item.text === 'Search') {
                            eventName = item.eventName;
                        }
                    })
                }
                element.sort.required ? (element.sort.icon = '') : '';
                if (element.key === colName && element.sort.required) {
                    element.sort.icon = this.sortOrder === 'asc' ? 'fa fa-caret-up' : (this.sortOrder === 'desc' ? 'fa fa-caret-down' : '') ;
                }
            });
            sort.col_name = colName;
            sort.sort_order = this.sortOrder;
            sort.eventName = eventName;
            return sort;
        }
    },
}
import BurnUpChart from './burn-up-chart/BurnUpChart.vue'
import DailyBurnChart from './daily-burn-chart/DailyBurnChart.vue'
import {reportService} from '../report.service';

export default {
  props: ['project_id'],
  name: 'ProjectBurnReport',
  components: {BurnUpChart, DailyBurnChart},
  mounted() {
    // Presetting the dates to user selected dates
    const start   = new Date();
    const end     = new Date();
    start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
    var startDate = this.$route.query.start_date ? new Date(this.$route.query.start_date) : start;
    var endDate   = this.$route.query.end_date ? new Date(this.$route.query.end_date) : end;

    this.selected_date_range = [startDate, endDate];
    this.show_dollar_value   = (this.$route.query.type == "dollar");

    // First load of project burn report chart
    this.loadProjectPlans();
    this.loadProjectBurnData();

    // pre-load Project overview
    this.$refs.overviewTrigger.$el.click()
    this.refresh();
    $('.tooltips').tooltip();
  },
  data() {
    return {
      reportService               : reportService,
      recurrence                  : 'weekly',
      loading                     : false,
      projects                    : [],
      selected_project_name: '',
      selected_project            : this.project_id,
      selected_date_range         : [new Date(), new Date()],
      selected_date_range_cache   : [],
      // recurrence_date_range       : [new Date(), new Date()], // Not needed anymore sunce we are updating the seletec_date_range object
      lifetime_planned_vs_logged  : [],
      burn_report_per_talent      : [],
      disable_date_range          : false,
      show_lifetime               : false,
      project_summary             : {planned: 0, logged: 0, planned_till_date: 0, budget: 0},
      show_dollar_value           : false,
      report_type                 : "hours",
      tabPosition                 : 'top',
      activeTabName               : 'daily_burn_chart',
      pickerOptions: {
        shortcuts: [{
          text: 'Last week',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(end.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: 'Last month',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(end.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: 'Last 3 months',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(end.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      }
    }
  },
  computed: {
    // Go through the projects object to get the name of the project which has the id as selected_project
    getSelectedProjectName(){
      var vm = this;
      for (var i = 0; i < vm.projects.length; i++) {
        if (vm.projects[i].id === vm.selected_project){
         this.selected_project_name =  vm.projects[i].name;
         this.refresh();
          return this.selected_project_name;
        }
      }
      return '';
    },
    // Return the inaccuracy in planning per talent along with the other necessary information
    inaccuracyPerTalent() {
      var inaccuracyPerTalent = [];
      if (this.show_lifetime === true){
        this.burn_report_per_talent.forEach(function(row) {
          inaccuracyPerTalent.push({
            talent:           row.talent ? row.talent : "Not Available",
            // since the talents without the role need to be shown at the bottom we are assigning empty string to it.
            role:             row.role != "Not Available" ? row.role : "",
            hourly_rate:      row.hourly_rate,
            difference:       row.total_planned - row.total_logged,
            planned:          row.total_planned,
            logged:           row.total_logged,
            // total_difference: row.total_planned - row.total_logged,
            // total_planned:    row.total_planned,
            // total_logged:     row.total_logged,
            inaccuracy:       (row.total_logged === 0) ? "N/A" : (row.total_logged - row.total_planned)*100/row.total_logged
          })
        });
      }else{
        this.burn_report_per_talent.forEach(function(row) {
          if(row.planned > 0 || row.logged > 0){
            inaccuracyPerTalent.push({
              talent:           row.talent ? row.talent : "Not Available",
              // since the talents without the role need to be shown at the bottom we are assigning empty string to it.
              role:             row.role != "Not Available" ? row.role : "",
              hourly_rate:      row.hourly_rate,
              difference:       row.planned - row.logged,
              planned:          row.planned,
              logged:           row.logged,
              // total_difference: row.total_planned - row.total_logged,
              // total_planned:    row.total_planned,
              // total_logged:     row.total_logged,
              inaccuracy:       (row.logged === 0) ? "N/A" : (row.logged - row.planned)*100/row.logged
            });
          }
        });
      }
      this.refresh();
      return inaccuracyPerTalent;
    },
    // Returns the data for the graphical representation of the burn report per talent.
    talentBurnReportChartData(){
      var vm                   = this;
      var projectBurnPerTalent = [];
      if (vm.show_lifetime === true){
        vm.burn_report_per_talent.forEach(function(row) {
          projectBurnPerTalent.push({
            planned: row.total_planned.toFixed(2),
            logged: row.total_logged.toFixed(2),
            talent: row.talent
          });
        });
      }else{
        vm.burn_report_per_talent.forEach(function(row) {
          if(row.planned > 0 || row.logged > 0){
            projectBurnPerTalent.push({
              planned: row.planned.toFixed(2),
              logged: row.logged.toFixed(2),
              talent: row.talent
            });
          }
        });
      }
      return projectBurnPerTalent
    },
    // Returns the differnce between budget and burn / planned and logged based on the report type
    runningDifference(){
      if (this.show_dollar_value == false){
        return (this.project_summary.planned - this.project_summary.logged)
      }else{
        return (this.project_summary.budget - this.project_summary.logged)
      }
    },
    // Return the ratio of planned_houurs_till_date / total_planned_hours for a project. 
    plannedProgress(){
      return (this.project_summary.planned == 0 ? 0 : (this.project_summary.planned_till_date*100)/this.project_summary.planned)
    },
    trendLineRange(){
      if (this.activeTabName == 'burn_up_chart' && this.projectBurnUpChartData.length > 0){
        return [this.projectBurnUpChartData[0], this.projectBurnUpChartData[this.projectBurnUpChartData.length-1]]
      }else{
        return [{},{}]
      }
    }
    // loggedProgress(){
    //   if(this.project_summary.logged > this.project_summary.planned)
    //     return ((this.project_summary.planned*100)/this.project_summary.logged).toFixed(2)
    //   else
    //     return ((this.project_summary.logged*100)/this.project_summary.planned).toFixed(2)
    // },
    // overburntProgress(){
    //   if(this.project_summary.logged > this.project_summary.planned)
    //     return (100.0 - ((this.project_summary.planned*100)/this.project_summary.logged).toFixed(2))
    //   else
    //     return 0;
    // }
  },
  methods: {
    refresh() {
      if(this.$children.length > 0) {
        let datatable = this.$children.find(a => a.$vnode.tag.indexOf('DataTables') >= 0);
        if (datatable) {
          let elTable = datatable.$children.find(a => a.$vnode.tag.indexOf('ElTable') >= 0)

          Object.keys(elTable._computedWatchers).forEach(k => elTable._computedWatchers[k].run())
          this.$nextTick(() => elTable.$forceUpdate())

          let bodyAndHeaders = elTable.$children.filter(a => a.$vnode.tag.indexOf('ElTableBody') >= 0 || a.$vnode.tag.indexOf('ElTableHeader') >= 0)
          console.log(bodyAndHeaders);

          this.$nextTick(() => {
            bodyAndHeaders.map(bodyOrHeader => {
              Object.keys(bodyOrHeader._computedWatchers).forEach(k => bodyOrHeader._computedWatchers[k].run())
              this.$nextTick(() => bodyOrHeader.$forceUpdate())
            })
          })
          let elPagination = datatable.$children.find(a => a.$vnode.tag.indexOf('ElPagination') >= 0);
          if (elPagination) {
            elPagination.$children.map(item => {
              item._computedWatchers && Object.keys(item._computedWatchers).forEach(k => item._computedWatchers[k].run())

              this.$nextTick(() => item.$forceUpdate());
            })
          }
          datatable.$on('update:currentPage', () => this.refresh());
        }
      }
    },
    // Gets all the active project plans for the select box
    loadProjectPlans () {
      var vm = this;
      // vm.$http.get('/api/v1/project_plans/options_for_select', {
      //   params: {
      //     type: "wip,closed"
      //   }
      // })
      // .then(function(response) {
      //   vm.projects         = response.body.body;
      //   vm.selected_project = Number(vm.project_id);
      // });

      this.reportService.getProjectPlans().subscribe( response => {
        vm.projects = response;
        vm.selected_project = Number(vm.project_id);
      });
    },
    // Fetches all the data needed for the detailed project burn report of a project(timeline chart data, burn report per talent, widget data, adjusted date range).
    loadProjectBurnData: function () {
      if (this.selected_date_range !== null){
        var vm     = this;
        if(vm.selected_project == ''){return};
        vm.loading = true;
        this.$http.get('/api/v1/project_burn_report/'+vm.selected_project, {
          params: {
            start_date      : vm.selected_date_range[0].toLocaleDateString('en-US').split("/").join("-"),
            end_date        : vm.selected_date_range[1].toLocaleDateString('en-US').split("/").join("-"),
            type            : (vm.show_dollar_value == true) ? "dollar" : "hour",
            recurrence_type :  vm.recurrence
          }
        })
        .then(function(response) {
          var response_body               = response.body.body;
          this.lifetime_planned_vs_logged = response_body.lifetime_planned_vs_logged;
          this.burn_report_per_talent     = response_body.burn_report_per_talent;
          this.project_summary            = response_body.project_summary;
          // this.recurrence_date_range[0]   = Date.parse(response_body.recurrence_date_range[0]);
          // this.recurrence_date_range[1]   = Date.parse(response_body.recurrence_date_range[1]);
          this.selected_date_range = [new Date(response_body.recurrence_date_range[0]), new Date(response_body.recurrence_date_range[1])];

          if (this.show_lifetime === true){
            if (this.lifetime_planned_vs_logged.length > 0){
              this.selected_date_range = this.get_max_min_range();
            }
          }
          // this.plotProjectBurnChart();
          this.plotTalentBurnChart();
          vm.refresh();
          vm.loading = false;
        });
      }
    },
    handleTabClick(tab, event) {
      this.loadProjectBurnData();
    },
    plotTalentBurnChart(){
      var vm              = this;
      var talentBurnChart = window.AmCharts.makeChart(("talent_burndown_chart"), {
        "type"              : "serial",
        "theme"             : "light",
        "zoomOutButtonImage": "",
        "zoomOutText"       : "Zoom out",
        "legend"            : {
            "position"    : "bottom",
            "valueWidth"  : 100,
            "valueAlign"  : "left",
            "equalWidths" : false,
            "valueFunction"   : function(graphDataItem, text) {
              var formattedValue = text.replace(/[^0-9\.-]+/g,"");
              if (isNaN(parseFloat(formattedValue)))
                return "";
              else
                return vm.formatCurrency(formattedValue);
            }
        },
        "valueAxes"         : [{"gridAlpha": 0.07, "title": (vm.show_dollar_value == true) ? "$ Value" : "Hours"}],
        "dataProvider"      : this.talentBurnReportChartData,
        "graphs"            : [{
            "fillAlphas"  : 0.8,
            "lineAlpha"   : 0.3,
            "title"       : (vm.show_dollar_value == true) ? "Estimated" : "Planned Hours",
            "type"        : "column",
            "lineColor"   : "#40b6c7",
            "valueField"  : "planned",
            "clustered"   : false,
            "columnWidth" : 0.6,
            "balloonText" : ""
          }, {
            "fillAlphas"  : 0.8,
            "lineAlpha"   : 0.3,
            "title"       : (vm.show_dollar_value == true) ? "Burned" : "Logged Hours",
            "type"        : "column",
            "lineColor"   : "#ed264b",
            "valueField"  : "logged",
            "clustered"   : false,
            "columnWidth" : 0.3,
            "balloon": {
              "adjustBorderColor": true,
              "borderColor": "#000000",
              "fillColor" : "#ffffff",
              "offsetY"   : 50
            },
            "balloonFunction": function(graphDataItem, graph) {
              var chart       = graph.chart;
              var index       = graphDataItem.index;
              var data        = chart.dataProvider;
              var planned     = data[index]["planned"];
              var logged      = data[index]["logged"];
              var difference  = (planned - logged);
              var talent      = data[index]["talent"];
              var inaccuracy  = (logged > 0) ? ((logged - planned)*100/logged).toFixed(2) : "NA"
              return `<table>
                      <tr><td align='left' colspan='3'><b>`+talent+`</b></td></tr>
                      <tr><td align='left'>`+((vm.show_dollar_value == true) ? "Estimated" : "Planned")+`:</td><td></td><td align='right'>`+vm.formatCurrency(planned)+`</td></tr>
                      <tr><td align='left'>`+((vm.show_dollar_value == true) ? "Burned" : "Logged")+`:</td><td></td><td align='right'>`+vm.formatCurrency(logged)+`</td></tr>
                      <tr><td align='left'><b>Difference:</b></td><td>&nbsp</td><td align='right'><b>`+vm.formatCurrency(difference)+`</b></td></tr>
                      <tr><td align='left'><b>Inaccuracy(%):</b></td><td>&nbsp</td><td align='right'><b>`+inaccuracy+`</b></td></tr>
                    </table>`
            }
          }],
        "categoryField" : "talent",
        "categoryAxis"  : {
            "title"            : "Talents",
            "gridPosition"     : "start",
            "axisAlpha"        : 0,
            "gridAlpha"        : 0,
            "position"         : "left",
            "inside"           : true,
            "labelRotation"    : 90,
            "minHorizontalGap" : 0
          },
          "chartCursor": {
            "categoryBalloonColor"    : "#000000",
            "cursorAlpha"             : 0,
            "valueLineAlpha"          : 0,
            "valueLineEnabled"        : false,
            "valueLineBalloonEnabled" : false,
            "valueBalloonsEnabled"    : true,
            "fullWidth"               : false,
            "categoryBalloonEnabled"  : false,
            "inside"                  : false
          }
      });
      this.amChartCheckEmptyData(talentBurnChart);
    },
    amChartCheckEmptyData(chart) {
      if (0 == chart.dataProvider.length) {

        chart.valueAxes[0].minimum = 10;
        chart.valueAxes[0].maximum = 100;

        // add dummy data point
        var dataPoint = {
          dummyValue: 0
        };
        dataPoint[chart.categoryField] = '';
        chart.dataProvider = [dataPoint];

        // add label
        chart.addLabel(0, '50%', 'The chart contains no data', 'center');

        // set opacity of the chart div
        chart.chartDiv.style.opacity = 0.5;

        // redraw it
        chart.validateNow();
      }
    },
    toggleLifetime() {
      var vm = this;
      if (vm.show_lifetime === true){
        if (vm.lifetime_planned_vs_logged.length > 0){
          vm.selected_date_range_cache = vm.selected_date_range; // Storing current state into cache
          vm.selected_date_range       = this.get_max_min_range();
        }
      }else{
        if(vm.selected_date_range_cache.length != 0){
          vm.selected_date_range        = vm.selected_date_range_cache; // Fetching the selected dates from cache
          vm.selected_date_range_cache  = []; // Clear out the cached values
        }
      }
      // this.plotProjectBurnChart();
      this.plotTalentBurnChart();
    },
    // Returns the summed up values per column in the talent burn table
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      const columns_to_exclude = ((this.show_dollar_value === true) ? [1,2,6] : [1,5])
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = 'Total';
          return;
        }
        if (columns_to_exclude.includes(index)) {
          sums[index] = '';
          return;
        }
        const values = data.map(item => Number(item[column.property]));
        if (!values.every(value => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return (prev + curr)
            } else {
              return prev;
            }
          }, 0);
          sums[index] = this.formatCurrency(sums[index]);
        } else {
          sums[index] = '';
        }
      });

      return sums;
    },
    talentTableHeaderRowClass(row, rowIndex){
      return "talent-table-header-row"
    },
    valueBasedFontClass(value, colorize=false) {
      var classes = [];
      if(typeof(value) == "number"){
        var parsedValue = parseFloat(value);
        switch(true){
          case (parsedValue == 0 || isNaN(parsedValue)):
            classes.push("is-zero");
            break;
          case (parsedValue < 0 && colorize==true):
            classes.push("is-negative");
            break;
          case (parsedValue > 0 && colorize==true):
            classes.push("is-positive");
            break;
          default :
            classes.push("");
            break;
        }
      }
      else{
        if(value == 'N/A' || value == 'Not Available' || value == 'Unknown' || value == '')
          classes.push('is-zero')
      }
      return classes;
    },
    conditionalFormatNumber(value){
      if(isNaN(value)){
        return value
      }else{
        return value.toFixed(2);
      }
    },
    sortInaccuracyColumn(a,b){
      var first = isNaN(a.inaccuracy) ? 0 : a.inaccuracy
      var second = isNaN(b.inaccuracy) ? 0 : b.inaccuracy
      return first > second ? 1 : first < second ? -1 : 0
    },
    // Need to overide the default search for including lowercase in the search
    sortTalentColumn(a,b){
      return a.talent.toLowerCase() > b.talent.toLowerCase() ? 1 : a.talent.toLowerCase() < b.talent.toLowerCase() ? -1 : 0
    },
    toggleDollarValue() {
      this.report_type = (this.show_dollar_value == true) ? "dollars" : "hours";
      this.loadProjectBurnData();
    },
    formatCurrency(value){
      value = parseFloat(value);
      if (this.show_dollar_value == true)
        return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' })
      else
        return value.toFixed(1)
    },tableCellClassName({row, column, rowIndex, columnIndex}) {
      // Change the background of first cell of a row(Talent) if the role is 'Not Available' (Unplanned Resource)
      if (columnIndex == 0 && row.role === '')
        return 'warning-cell';
      return '';
    },export_to_doc_url(){
      var start_date = this.selected_date_range[0].toLocaleDateString('en-US').split("/").join("-");
      var end_date   = this.selected_date_range[1].toLocaleDateString('en-US').split("/").join("-");
      return "project_burn_report/" + this.selected_project + "/export?format=docx&start_date="+ start_date +"&end_date="+ end_date;
    },
    get_max_min_range(){
      return [ new Date(this.lifetime_planned_vs_logged.reduce((min, pbpd) => Date.parse(pbpd.date) < min ? Date.parse(pbpd.date) : min, Date.parse(this.lifetime_planned_vs_logged[0].date))),
        new Date(this.lifetime_planned_vs_logged.reduce((max, pbpd) => Date.parse(pbpd.date) > max ? Date.parse(pbpd.date) : max, Date.parse(this.lifetime_planned_vs_logged[0].date))),
      ]
    },
    handleTabClick(tab, event) {
      var vm = this;
      if(vm.activeTabName == 'burn_up_chart'){
        if (vm.show_lifetime != true){
          vm.show_lifetime = true;
          vm.toggleLifetime();
        }
      }
      // vm.plotProjectBurnChart();
    },
    trendLineData(){
      var vm = this;
      if(this.activeTabName == 'burn_up_chart'){
        return [{
          "finalDate"    : vm.trendLineRange[1]['date'],
          "finalValue"   : vm.trendLineRange[1]['planned'],
          "initialDate"  : vm.trendLineRange[0]['date'],
          "initialValue" : vm.trendLineRange[0]['planned'],
          "lineColor"    : "#666"
        }]
      }else{
        return []
      }
    }
  }

}
import { render, staticRenderFns } from "./bar-chart.vue?vue&type=template&id=ad39e286&scoped=true&"
import script from "./bar-chart.js?vue&type=script&lang=js&"
export * from "./bar-chart.js?vue&type=script&lang=js&"
import style0 from "./bar-chart.css?vue&type=style&index=0&id=ad39e286&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ad39e286",
  null
  
)

export default component.exports
import Axios from  'axios-observable';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { formatDateObject } from "../utils/utils";

class TalentUtilizationService {

  utilizationSummaryDataLoading = new BehaviorSubject(false);
  utilizationDetailedDataLoading = new BehaviorSubject(false);

  apiUrlConstants = {
    talentFilterApiUrl : '/api/v1/employees/options_for_select',
    groupFilterApiUrl : '/api/v1/departments/options_for_select',
    verticalFilterApiUrl : '/api/v1/teams/options_for_select',
    projectFilterApiUrl : '/api/v1/project_plans/allProject/options_for_select',
    locationsApiUrl : '/api/v1/locations/options_for_select',
    rolesApiUrl: '/api/v1/employees/capability/options_for_select',
    currentUserApiUrl : '/employees/fetch_current_employee.json',
    summaryApiUrl : '/api/v1/utilization/summary',
    detailedApiUrl : '/api/v1/utilization/detailed',
  
    reportApiUrl: '', 
  }

  constructor() {
    const baseUrl = this.getBaseUrl();
    this.apiUrlConstants.reportApiUrl = `${baseUrl}/api/utilization/generateReport`;
  }

  getBaseUrl() {
    const { hostname } = window.location;
    if (hostname == 'localhost') {
      return 'http://localhost:3080'; 
    } else if (hostname == 'bitool.softwaystaging.com') {
      return 'https://bitool-v2.softwaydev.com';
    } else {
      return 'https://bitool-v2.softway.com';
    }
  }

  getCurrentUser() {
    return Axios.get(this.apiUrlConstants.currentUserApiUrl)
    .pipe(
      map(response => {
        return response.data;
      })
    );
  }

  getFilterOptions(talentReportType) {
    let filterapiUrl = '';
    switch (talentReportType) {
      case 'Talent':
        filterapiUrl = this.apiUrlConstants.talentFilterApiUrl;
        break;
      case 'Group':
        filterapiUrl = this.apiUrlConstants.groupFilterApiUrl;
        break;
      case 'Vertical':
        filterapiUrl = this.apiUrlConstants.verticalFilterApiUrl;
        break;
      case 'Project':
        filterapiUrl = this.apiUrlConstants.projectFilterApiUrl;
        break;
      case 'Roles':
        filterapiUrl = this.apiUrlConstants.rolesApiUrl;
        break;
      case 'Location':
        filterapiUrl = this.apiUrlConstants.locationsApiUrl;
        break;
      default:
        filterapiUrl = '';
        break;
    }

    return Axios.get(filterapiUrl)
    .pipe(
      map(response => response.data.body)
    );
  }

  getLocations() {
    return Axios.get(this.apiUrlConstants.locationsApiUrl)
    .pipe(
      map(response => response.data.body)
    );
  }

  // getUtilizationSummary(reportTypeFilter, selectedFilterName, startDate, endDate, threshold, locations) {
  //   this.utilizationSummaryDataLoading.next(true);
  //   return Axios.get(this.apiUrlConstants.summaryApiUrl, {
  //     params: {
  //      "talent_report_type": reportTypeFilter,
  //       "talent_report_filter": selectedFilterName,
  //       "start_date": formatDateObject(startDate, '-'),
  //       "end_date": formatDateObject(endDate, '-'),
  //       "threshold_value": threshold,
  //       "location_id": locations,
  //     }
  //   })
  //   .pipe(
  //     map(response => {
  //       this.utilizationSummaryDataLoading.next(false);
  //       return response.data.body.utilizationSummary;      
  //     })
  //   );
  // }

  // getUtilizationDetailed(viewType, reportTypeFilter, selectedFilterName, startDate, endDate, threshold, locations) {
  //   this.utilizationDetailedDataLoading.next(true);
  //   return Axios.get(this.apiUrlConstants.detailedApiUrl, {
  //     params: {
  //       "talent_report_type": reportTypeFilter,
  //       "talent_report_filter": selectedFilterName,
  //       "start_date": formatDateObject(startDate, '-'),
  //       "end_date": formatDateObject(endDate, '-'),
  //       "threshold_value": threshold,
  //       "location_id": locations,
  //       "utilization_breakdown": viewType
  //     }
  //   })
  //   .pipe(
  //     map(response => {
  //       this.utilizationDetailedDataLoading.next(false);
  //       return response.data.body.detailedUtilization;
  //     })
  //   );
  // }

  getUtilizationSummary(reportTypeFilter, selectedFilterName, startDate, endDate, threshold, locations) {
    this.utilizationSummaryDataLoading.next(true);
    return Axios.get(this.apiUrlConstants.summaryApiUrl, {
      params: {
       "talentReportType": reportTypeFilter,
        "talentReportFilter": selectedFilterName,
        "startDate": formatDateObject(startDate, '-'),
        "endDate": formatDateObject(endDate, '-'),
        "thresholdValue": threshold,
        "locationId": locations,
        "reportType": "summary",
      }
    })
    .pipe(
      map(response => {
        console.log("response",response)
        this.utilizationSummaryDataLoading.next(false);
        return response.data.body.json.data.summary;     
      })
    );
  }

  getUtilizationDetailed(viewType, reportTypeFilter, selectedFilterName, startDate, endDate, threshold, locations) {
    this.utilizationDetailedDataLoading.next(true);
    return Axios.get(this.apiUrlConstants.detailedApiUrl, {
      params: {
        "talentReportType": reportTypeFilter,
        "talentReportFilter": selectedFilterName,
        "startDate": formatDateObject(startDate, '-'),
        "endDate": formatDateObject(endDate, '-'),
        "thresholdValue": threshold,
        "locationId": locations,
        "utilizationBreakdown": viewType,
        "reportType": "detailed"
      }
    })
    .pipe(
      map(response => {
        this.utilizationDetailedDataLoading.next(false);
        return response.data.body.json.data.detailed.detailedUtilization;
      })
    );
  }

}

export default new TalentUtilizationService();
import Vue from 'vue/dist/vue.esm';
import PipelineImpact from './pipeline-impact';
import Axios from 'axios-observable';
import "xe-utils";
import VXETable from "vxe-table";
import "vxe-table/lib/index.css";
import enUS from 'vxe-table/lib/locale/lang/en-US'
import VueCurrencyFilter from "vue-currency-filter";
import VueCurrencyInput from "vue-currency-input";
import { Loading } from 'element-ui';
import ElementUI from 'element-ui';
import VXETablePluginExportXLSX from 'vxe-table-plugin-export-xlsx'

import 'element-ui/lib/theme-chalk/index.css';

Vue.use(VXETable);
VXETable.setup({
  i18n: (key, value) => XEUtils.get(enUS, key)
})

Vue.use(VXETable);
Vue.use(ElementUI);
Vue.use(Loading.directive);
VXETable.use(VXETablePluginExportXLSX)

Vue.use(VueCurrencyFilter, {
  symbol: "$",
  thousandsSeparator: ",",
  fractionCount: 2,
  fractionSeparator: ".",
  symbolPosition: "front",
  symbolSpacing: false,
});
Vue.use(VueCurrencyInput);

Vue.use(Loading.directive);

Axios.defaults.headers.common["Content-Type"] = "application/json";
Axios.defaults.headers.post["Content-Type"] = "application/json";

if (document.getElementById("pipeline-impacts")) {
  new Vue({ el: "#pipeline-impacts", render: (h) => h(PipelineImpact) });
}
import Vue from 'vue/dist/vue.esm'
import Router from 'vue-router'
import Dashboard from './dashboard/Dashboard'
import GanttCharts from './gantt-chart/gantt-chart-view/GanttChartView'
import ProjectBurnReport from './burn-report/project-burn-report/ProjectBurnReport'


Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/',
      name: 'Dashboard',
      component: Dashboard,
      props: true
    },
    {
      path: '/gantt',
      name: 'GanttCharts',
      component: GanttCharts,
      props: true
    },
    {
      path: '/:project_id/project_burn',
      name: 'ProjectBurnReport',
      component: ProjectBurnReport,
      props: true
    }
  ]
})
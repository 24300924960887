import router from './project-plan-router.js'
import Vue from 'vue/dist/vue.esm'
import vSelect from 'vue-select'
import VueResource from 'vue-resource'; // HTTP client for Vue.js
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
// import "handsontable/dist/handsontable.full.css"
import DataTables from 'vue-data-tables'
import { Loading } from 'element-ui';
import VXETable from "vxe-table";
import "vxe-table/lib/index.css";
import XEUtils from 'xe-utils'
import enUS from 'vxe-table/lib/locale/lang/en-US';
// //L10N (show English in pagination)
// import lang from 'element-ui/lib/locale/lang/en'
// import locale from 'element-ui/lib/locale'
// locale.use(lang)
// // Vue.use(ElementUI, { size: 'medium', locale });

Vue.component('v-select', vSelect)
Vue.use(VueResource);
Vue.use(ElementUI);
Vue.use(DataTables);
Vue.use(Loading.directive);
Vue.use(VXETable);

VXETable.setup({
    i18n: (key, value) => XEUtils.get(enUS, key)
})

// importing the directive to bypass the error -> Failed to resolve directive: popover
// import directive from 'element-ui/packages/popover/src/directive';
// Vue.directive('popover', directive);

var projectDashboardElement = document.getElementById("project_dashboard");
if (projectDashboardElement!= null){
  new Vue({
    el: '#project_dashboard',
    router
  });
}
import XEUtils from 'xe-utils';

export default {
  name: 'resource-summary-panel',
  props: ['startDate', 'endDate', 'project_id', 'modify_permission'],
  mounted() {
    this.loadEmployeesList();
    this.loadResourceSummary();
    this.$root.$on('updateDashboard', () => {
      this.loadEmployeesList();
      this.loadResourceSummary();
    });

    // Add event listener for the confirm modal close
    $('#confirm_modal').on('hidden.bs.modal', this.handleModalClose);
  },
  data() {
    return {
      resourceSummaryData: [],
      editDialogVisible: false,
      employees: [],
      loading: false,
      selectedRole: '',
      selectedEmployeeName: '',
      selectedEmployeeId: '',
      selectedResourceRow: null,
      previousEmployeeId: null,
      confirmed: false, // Track confirmation state
    }
  },
  methods: {
    loadEmployeesList() {
      var vm = this;
      vm.$http.get('/api/v1/employees/options_for_select', {})
        .then(function(response) {
          vm.employees = response.body.body;
        });
    },
    loadResourceSummary(callback) {
      var vm = this;
      vm.loading = true;
      this.$http.get('/api/v1/project_plans/' + vm.project_id + '/resource_summary', {})
        .then(function(response) {
          var response_body = response.body.body;
          response_body.forEach(item => {
            item.originalEmployeeId = item.employee_id;
          });
          vm.resourceSummaryData = response_body;
          vm.loading = false;
          if (typeof callback === "function")
            callback();
        });
    },
    updateResourceSummary() {
      var vm = this;
      var editedSummaryHash = {};

      vm.resourceSummaryData.forEach(function(resource_summary) {
        editedSummaryHash[resource_summary.id] = { employee_id: resource_summary.employee_id }
      });

      this.$http.put('/api/v1/project_plans/' + vm.project_id + '/resource_summary/update', {
        resource_summary_data: editedSummaryHash
      }).then(function(response) {
        var response_body = response.body.body;
        vm.loadResourceSummary(() => { bootstrapNotify('Resource summary has been successfully updated. Project dashboard data sync is in progress', 'success') });
        this.$root.$emit('updateDashboard');
      });
    },
    sortResourceHours(a, b) {
      if (parseFloat(a.hours) < parseFloat(b.hours))
        return -1;
      else if (parseFloat(a.hours) > parseFloat(b.hours))
        return 1;
    },
    valueBasedFontClass(value) {
      if (value == "Unassigned")
        return "is-zero";
      else
        return "break-word";
    },
    footerMethod({ columns, data }) {
      const footerData = [
        columns.map((column, columnIndex) => {
          if (columnIndex === 0) {
            return 'Total';
          }
          if (['hours'].includes(column.property)) {
            return XEUtils.sum(data, 'hours');
          }
          if (['days'].includes(column.property)) {
            return XEUtils.sum(data, 'days');
          }
          return null;
        })
      ];
      return footerData;
    },
    checkAndOpenConfirmModal(role, employeeId, resourceRow) {
      const selectedEmployee = this.employees.find(emp => emp.id === employeeId);
      this.selectedRole = role;
      this.selectedEmployeeName = selectedEmployee ? selectedEmployee.name : '';
      this.selectedEmployeeId = employeeId;
      this.previousEmployeeId = resourceRow.originalEmployeeId;
      this.selectedResourceRow = resourceRow;
      this.confirmed = false; // Reset confirmation state
      this.$http.get(`/api/v1/employees/${employeeId}/checkCapability`, {
        params: { role }
      }).then(response => {
        if (response.body.body.exists) {
          resourceRow.employee_id = employeeId;
        } else {
          $('#confirm_modal').modal('show');
        }
      }).catch(() => {
        resourceRow.employee_id = this.previousEmployeeId;
      });
    },
    handleModalClose() {
      if (!this.confirmed) {
        this.selectedResourceRow.employee_id = this.previousEmployeeId;
        vm.confirmed = false;
      }
    },
    confirmAssignment() {
      var vm = this;
      if (this.selectedEmployeeId && this.selectedRole) {
        this.$http.post(`/api/v1/employees/${this.selectedEmployeeId}/updateCapability`, {
          role: this.selectedRole
        }).then(function(response) {
          if (response.body.status === true) {
            vm.confirmed = true;
            vm.selectedResourceRow.employee_id = vm.selectedEmployeeId; 
          }
        });
      }
      $('#confirm_modal').modal('hide');
    }
  },
  beforeDestroy() {
    // Remove event listener for the confirm modal close
    $('#confirm_modal').off('hidden.bs.modal', this.handleModalClose);
  }
};

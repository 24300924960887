import Vue from 'vue/dist/vue.esm'
// import Vue from 'vue'
import Router from 'vue-router'

// import { DatePicker, Select, Option, Table, TableColumn,
//   Dialog, Button, Carousel, CarouselItem,
// } from 'element-ui';
// import ElementUI from 'ele'
import { Loading } from 'element-ui';
import "xe-utils";
import VXETable from "vxe-table";
import "vxe-table/lib/index.css";

// import TableColumn from 'element-ui/packages/table/src/table-column'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import lang from 'element-ui/lib/locale/lang/en' // L10N
import locale from 'element-ui/lib/locale'
locale.use(lang)


import AccrualsDashboard from './accruals-dashboard/accruals-dashboard.vue'
import ProjectAccrualReport from './project-accrual-report/project-accrual-report.vue'
import VueCurrencyFilter from 'vue-currency-filter'
import VueCurrencyInput from 'vue-currency-input'
// import StackTrace from 'stacktrace-js'


Vue.use(VueCurrencyFilter, {
  symbol: '$',
  thousandsSeparator: ',',
  fractionCount: 2,
  fractionSeparator: '.',
  symbolPosition: 'front',
  symbolSpacing: false
})
Vue.use(VueCurrencyInput)

Vue.use(Router);
// Vue.use(Carousel);
// Vue.use(CarouselItem);

// Vue.use(DatePicker);
// Vue.use(Select);
// Vue.use(Option);
// Vue.use(Table);
// Vue.use(Dialog);
// Vue.use(TableColumn); //Dangerous component
// Vue.use(Button);
Vue.use(ElementUI);
Vue.use(VXETable);

Vue.use(Loading.directive);

const routes = [{
    path: '/',
    name: 'accruals-dashboard',
    component: AccrualsDashboard,
    props: true
  },
  {
    path: '/project/:id',
    name: 'project-accrual-report',
    component: ProjectAccrualReport,
    props: true
  },
]

const router = new Router({
  routes // short for `routes: routes`
})

// StackTrace.get();
// // debugger;

if (document.getElementById("accruals")) {
  new Vue({
    router,
    // render: h => h(AccrualsDashboard)
  }).$mount('#accruals')
}
import MetronicTable from "../metronic-table/metronic-table";
import MetronicPagination from "../metronic-pagination/metronic-pagination";
import EventBus from "../../cards-transaction-event";
import { transactionService } from "../../card-transactions-service";
import { concatMap } from "rxjs/operators";

export default {
  name: "transaction-table",
  components: {
    MetronicTable,
    MetronicPagination
  },
  data() {
    return {
      metronicTableKey: '',
      paginationChangeEventName: "fetchTransactionsForPage",
      eventBus: EventBus,
      loader: false,
      currencyOptions: [{
        id: [-500, 0],
        title: "0 to 500(debit)",
      },
      {
        id: [-1000, -500],
        title: "500 to 1000(debit)",
      },
      {
        id: [0, 1000],
        title: "0 to 1000(credit)",
      }
      ],
      taxPaidOptions: [{
        id: true,
        title: "Yes",
      },
      {
        id: false,
        title: "No",
      }
      ],
      categoryOptions: [],
      expenseTypeOptions: [],
      cardOptions: [],
      transactions: null,
      paginationConfig: {
        currentPage: 1,
        itemsPerPage: 10,
        maxLength: 9,
        totalNoOfProjects: null,
        name: 'transactions',
      },
      tableHeaders: [],
      tranactionQuery: {}
    };
  },
  created() {
    this.loader = true;
    this.handlers();
    this.setDefaultTransactionQuery();
    transactionService.getStaticData()
      .subscribe(staticData => {
        this.categoryOptions = staticData.expense_categories;
        this.expenseTypeOptions = staticData.expense_types;
        this.cardOptions = staticData.cards.map(item => {
          return { 'id': item, 'title': item }
        });
        this.setTableHeaders();
      });
    this.getAllTransactions();
  },
  computed: {
    headers() {
      this.metronicTableKey++;
      return this.tableHeaders;
    },
  },
  watch: {},
  methods: {
    handlers() {
      this.eventBus.$on(this.paginationChangeEventName, pageNum => {
        if (this.paginationConfig.currentPageNum !== pageNum) {
          this.tranactionQuery.page = pageNum
          this.getAllTransactions(this.tranactionQuery);
          this.paginationConfig.currentPageNum = pageNum;
        }
      });
      this.eventBus.$on('refreshTransactions', res => {
        this.setDefaultTransactionQuery();
        this.getAllTransactions(this.tranactionQuery);
      });
    },
    setDefaultTransactionQuery() {
      this.tranactionQuery = {
        "page": this.paginationConfig.currentPage,
        "items_per_page": this.paginationConfig.itemsPerPage,
        "includes": {
          "name": "",
          "description": ""
        },
        "meets": {
          "tax_paid": null,
          "expense_category_id": null,
          "expense_type_id": null,
        },
        "range": {
          "amount": []
        },
        "dates": {
          "date": []
        },
        "associations": [{
          "modelAs": "card",
          "meets": {
            "number": ""
          }
        }]
      }
    },
    setTableHeaders() {
      this.tableHeaders = [{
        type: 'text',
        key: "name",
        label: "Employee",
        width: '100px',
        sort: {
          required: false,
          icon: ""
        },
        search: {
          required: true,
          type: "text",
          placeholder: "Name",
          defaultValue: null,
          eventName: "fetchTransactionData"
        }
      },
      {
        type: 'card',
        key: "card",
        label: "Card no",
        width: '135px',
        sort: {
          required: false,
          icon: ""
        },
        search: {
          required: true,
          type: "dropdown",
          defaultValue: null,
          selectedValue: null,
          placeholder: "Card",
          options: [{
            id: null,
            title: "All",
          },
          ...this.cardOptions
          ],
          eventName: "fetchTransactionData",
        }
      },
      {
        type: 'date',
        key: "date",
        label: "Transaction date",
        width: '60px',
        sort: {
          required: false,
          icon: ""
        },
        search: {
          required: true,
          type: "daterange",
          selectedValue: null,
          placeholder: 'Date',
          eventName: "fetchTransactionData"
        }
      },
      {
        type: 'textarea',
        key: "description",
        label: "Description",
        width: '100px',
        sort: {
          required: false,
          icon: ""
        },
        search: {
          required: true,
          type: "text",
          placeholder: "Keyword",
          defaultValue: null,
          eventName: "fetchTransactionData"
        }
      },
      {
        type: 'currency',
        key: "amount",
        label: "Amount",
        editOptions: this.currencyOptions,
        width: '40px',
        sort: {
          required: false,
          icon: ""
        },
        search: {
          required: true,
          type: "dropdown",
          defaultValue: null,
          selectedValue: null,
          placeholder: "Amount",
          options: [{
            id: null,
            title: "All",
          },
          ...this.currencyOptions
          ],
          eventName: "fetchTransactionData"
        }
      },
      {
        type: 'dropdown',
        key: "tax_paid",
        label: "Sales tax paid",
        editOptions: this.taxPaidOptions,
        width: '40px',
        sort: {
          required: false,
          icon: ""
        },
        search: {
          required: true,
          type: "dropdown",
          defaultValue: null,
          placeholder: "Paid",
          selectedValue: null,
          options: [{
            id: null,
            title: "All",
          },
          ...this.taxPaidOptions
          ],
          eventName: "fetchTransactionData"
        }
      },
      {
        type: 'dropdown',
        key: "expense_category_id",
        label: "Category",
        editOptions: this.categoryOptions,
        width: '50px',
        sort: {
          required: false,
          icon: ""
        },
        search: {
          required: true,
          type: "dropdown",
          defaultValue: null,
          placeholder: "Category",
          selectedValue: null,
          options: [{
            id: null,
            title: "All"
          },
          ...this.categoryOptions
          ],
          eventName: "fetchTransactionData"
        }
      },
      {
        type: 'dropdown-custom',
        key: "expense_type_id",
        label: "Expense type",
        width: '80px',
        editOptions: this.expenseTypeOptions,
        sort: {
          required: false,
          icon: ""
        },
        search: {
          required: true,
          type: "dropdown",
          defaultValue: null,
          selectedValue: null,
          placeholder: "Expense type",
          options: [{
            id: null,
            title: "All",
          },
          ...this.expenseTypeOptions
          ],
          eventName: "fetchTransactionData"
        }
      },
      {
        type: 'action-buttons',
        key: "action-buttons",
        label: "Actions",
        width: '100px',
        sort: {
          required: false,
          icon: ""
        },
        search: {
          required: true,
          type: "action-buttons",
          buttons: [{
            type: "submit",
            label: "Search",
            class: "btn btn-outline-success btn-sm",
            eventName: "fetchTransactionData"
          },
          {
            type: "reset",
            label: "Reset",
            class: "btn btn-outline-danger btn-sm",
            eventName: "resetTransactionFilters"
          }
          ],
        },
      }
      ];
    },
    getAllTransactions() {
      this.loader = true;
      transactionService.query(this.tranactionQuery)
        .subscribe(
          transactions => {
            this.transactions = transactions.rows;
            this.paginationConfig.totalNoOfProjects = transactions.count;
            this.loader = false;
          }
        );
    },
    rowDataUpdated(body) {
      this.loader = true;
      transactionService.updateTransaction(body)
        .subscribe(res => this.loader = false);
    },
    searchQuery(queryParams) {
      if (!queryParams) {
        this.setDefaultTransactionQuery();
        this.getAllTransactions();
        return;
      }
      Object.keys(queryParams.search)
        .forEach(
          key => {
            const value = queryParams.search[key]
            switch (key) {
              case 'name':
              case 'description':
                this.tranactionQuery.includes[key] = value;
                break;
              case 'card':
                this.tranactionQuery.associations = [{
                  "modelAs": "card",
                  "meets": {
                    "number": value
                  }
                }];
                break;
              case 'tax_paid':
              case 'expense_category_id':
              case 'expense_type_id':
                this.tranactionQuery.meets[key] = value;
                break;
              case 'date':
                this.tranactionQuery.dates[key] = value;
                break;
              case 'amount':
                this.tranactionQuery.range[key] = value;
                break;
              default:
                break;
            }
          }
        )
      this.getAllTransactions();
    },
  }
};
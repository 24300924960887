export default {
  name: 'sidebar',
  props: [],
  data() {
    return {
    }
  },
  computed: {
  },
  mounted() {
    // FIX: tooltip not working in sidebar when we add condition that holds boolean value cus the sidebar isn't loaded till the time hence initialization dint happen
    $('.sidebar [data-toggle="m-tooltip"]').each(function () {
      let el = $(this);
      var skin = el.data('skin') ? 'm-tooltip--skin-' + el.data('skin') : '';
      var width = el.data('width') == 'auto' ? 'm-tooltop--auto-width' : '';
      var triggerValue = el.data('trigger') ? el.data('trigger') : 'hover';
      var placement = el.data('placement') ? el.data('placement') : 'left';

      el.tooltip({
        trigger: triggerValue,
        template: '<div class="m-tooltip ' + skin + ' ' + width + ' tooltip" role="tooltip">\
                <div class="arrow"></div>\
                <div class="tooltip-inner"></div>\
            </div>'
      });
    });
  },
  watch: {
  },
  methods: {
  }
}
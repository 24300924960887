import Vue from 'vue/dist/vue.esm';
import { Form, FormItem, Input } from 'element-ui';
import EmployeeSkillsets from './employee-skillsets/employee-skillsets';
import EmployeeCapabilitysets from './employee-capabilitysets/employee-capabilitysets';


Vue.use(Form);
Vue.use(FormItem);
Vue.use(Input);

if (document.getElementById("employee-skillsets")) {
  new Vue({
    el: '#employee-skillsets',
    render: h => h(EmployeeSkillsets)
  })
}

if (document.getElementById("employee-capabilitysets")) {
  new Vue({
    el: '#employee-capabilitysets',
    render: h => h(EmployeeCapabilitysets)
  })
}
import ElYearPicker from "../../../../../components/el-year-picker/el-year-picker.vue";
import snapshotClosureService from "../../snapshot-closure-service";
import { strToDate } from "../../../project-accrual-report/utils/utils";
import { formatDateObject } from "../../../../../utils/utils";

export default {
  name: 'snapshot-month-closure',
  components: {
    ElYearPicker,
  },
  filters: {
    strToDate: strToDate
  },
  props: {
    open: {
      type: Boolean,
      required: true,
      default: false
    },
  },
  data() {
    return {
      snapshotClosureService: snapshotClosureService,
      open_: false,
      selectedYear: new Date(),
      snapshots: [],
      snapshotsMonthTableKey: 0,
    }
  },
  watch: {
    open(value) {
      this.open_ = value;
      this.refresh();
    },
  },
  created() {
    this.getSnapshotClosureMonths().subscribe(months => {
      this.snapshotsMonthTableKey++;
      this.snapshots = months;
    });
  },
  mounted() {
    this.init();
  },
  computed: {},
  methods: {
    init() {},
    refresh() {
      this.$nextTick(() => {
        let elTable = this.$children[0].$children.find(a => a.$vnode.tag.indexOf('ElTable') >= 0);
        if (elTable) {
          let bodyAndHeaders = elTable.$children.filter(a => a.$vnode.tag.indexOf('ElTableBody') >= 0 || a.$vnode.tag.indexOf('ElTableHeader') >= 0)
          bodyAndHeaders.map(bodyOrHeader => {
            Object.keys(bodyOrHeader._computedWatchers).forEach(k => bodyOrHeader._computedWatchers[k].run())
            this.$nextTick(() => bodyOrHeader.$forceUpdate())
          })
        }
      })
    },
    updatedYearHandler() {
      let vm  = this;
      this.getSnapshotClosureMonths().subscribe(months => {
        this.snapshotsMonthTableKey++;
        this.snapshots = months
        vm.refresh();
      });
    },
    getSnapshotClosureMonths() {
      const date = formatDateObject(this.selectedYear, '/');
      return this.snapshotClosureService.getSnapshotClosureMonths(date);
    },
    updateSnapshotStatus(id, status) {
      this.snapshotClosureService.updateSnapshotMonthStatus(id, status).subscribe();
    },
    emitClose(event) {
      this.open_ = false;
      this.$emit('update:open', this.open_);
    },
  }
}


export default {
  name: 'notes-modal',
  props: {
    open: {
      type: Boolean,
      required: true,
      default: false
    },
    notes: {
      type: String,
      required: true
    },
  },
  data() {
    return {
      open_: false,
      notes_: '',
    }
  },
  watch: {
    open(value) {
      this.open_ = value;
    },
    notes(value) {
      this.notes_ = value;
      this.oldNotes_ = this.notes_;
    },
  },
  created() {},
  mounted() {},
  computed: {},
  methods: {
    emitClose(event) {
      this.open_ = false;
      this.$emit('update:open', this.open_);
    },
    notesUpdated() {
      this.open_ = false;
      this.$emit('update:notes', this.notes_);
      this.$emit('notesUpdated', this.notes_);
    },
    notesUpdateCanceled() {
      this.emitClose();
      this.notes_ = this.oldNotes_;
    }
  }
}

import Vue from 'vue/dist/vue.esm';
import TalentForecastReport from './talent-forecast-report/talent-forecast-report';
import ElementUI from 'element-ui';
import "xe-utils";
import VXETable from "vxe-table";
import "vxe-table/lib/index.css";
import enUS from 'vxe-table/lib/locale/lang/en-US'
import VXETablePluginExportXLSX from 'vxe-table-plugin-export-xlsx'

Vue.use(ElementUI);

Vue.use(VXETable);
VXETable.setup({
    i18n: (key, value) => XEUtils.get(enUS, key)
})

Vue.use(VXETable);
VXETable.use(VXETablePluginExportXLSX)

var burnReportElement = document.getElementById("talent_forecast");
if (burnReportElement != null) {
    new Vue({
        el: '#talent_forecast',
        render: h => h(TalentForecastReport)
    });
}
/**
 * Purpose: Bar chart component(Re-usable)
 * Usage: <bar-chart :config="config" :data="data"/>
 * Input:
    config: {
      categoryKey: 'projectName',
      entities: [
        {
          key: 'billed_hours',
          label: 'Billed Hours',
          color: ''
        },
        {
          key: 'unbilled_hours',
          label: 'Unbilled Hours',
          color: ''
        }
      ]
    }
    data: [{
      "projectName": "XOM 3DS (Data Aggregator) 1",
      "billed_hours": 56,
      "unbilled_hours": 0
    },{
      "projectName": "Softway Solutions BI Tools 1",
      "billed_hours": 0,
      "unbilled_hours": 51,
    }]
 */

export default {
  name: 'bar-chart',
  data: function () {
    return {
      chart: {},
    };
  },
  props: {
    series: {
      type: Array,
      required: true
    },
    categoryKey: {
      type: String,
      required: true
    },
    data: {
      type: Array,
      required: true
    },
    xAxisLabel: {
      type: String,
      required: true
    },
    yAxisLabel: {
      type: String,
      required: true
    },
  },
  watch: {
    data: function (val) {
      this.chart.dataProvider = val;
      // set fixedColumnWidth for data entries < 7
      for (let i = 0; i < this.series.length; i++) {
        this.chart.graphs[i].fixedColumnWidth = val.length <= 7 ? 60 : undefined;
      }
      this.chart.validateData();
    },
    xAxisLabel: function (val) {
      this.chart.categoryAxis.title = val;
    },
    yAxisLabel: function (val) {
      this.chart.valueAxes[0].title = val;
    }
  },
  mounted() {
    this.initChart();
    this.handleZoomEvent();
  },
  methods: {
    initChart() {
      this.chart = AmCharts.makeChart(this.$refs.chartdiv, {
        "type": "serial",
        "addClassNames": true,
        "pathToImages": "/amCharts/images/",
        "theme": "light",
        "legend": this.setChartLegend(),
        "dataProvider": this.data,
        "valueAxes": this.setValueAxes(),
        "graphs": this.setSeries(),
        "categoryField": this.categoryKey,
        "categoryAxis": this.setCategoryAxis(),
        "chartCursor": this.setChartCursor(),
        "chartScrollbar": {
          // "graph": "graphs1",
          "position": "top",
          "oppositeAxis":false,
          "offset":30,
          "scrollbarHeight": 30,
          "backgroundColor": "#0000FF",
          "backgroundAlpha": 0.2, 
          "selectedBackgroundColor": "#0000FF", 
          "selectedBackgroundAlpha": 0.5 
        },
      });
    },
    handleZoomEvent() {
      const self = this;
      this.chart.addListener("zoomed", function(event) {
        // check if column width needs to be applied
        let fixedColumnWidth;
        if ((event.endIndex - event.startIndex) < 10) {
          fixedColumnWidth = 60;
        } else {
          fixedColumnWidth = undefined;
        }

        for (let i = 0; i < self.series.length; i++) {
          event.chart.graphs[i].fixedColumnWidth = fixedColumnWidth;
        }
      });
    },
    setChartLegend() {
      return {
        "horizontalGap": 10,
        "maxColumns": 2,
        "position": "bottom",
        "useGraphSettings": true,
        "align": 'center',
        "markerSize": 12
      }
    },
    setValueAxes() {
      return [{
        "title": this.yAxisLabel,
        "minimum": 0,
        "stackType": "regular",
        "axisAlpha": 0.3,
        "gridAlpha": 0.07
      }];
    },
    setSeries() {
      let graphs = [];
      for (let i = 0; i < this.series.length; i++) {
        const entity = this.series[i];
        graphs.push({
          "id": "graphs" + i,
          "balloonText": "[[category]]<br><span style='font-size:14px'>[[title]]:<b>[[value]]</b></span>",
          "fillAlphas": 0.8,
          "lineAlpha": 0.3,
          "title": entity.label,
          "type": "column",
          "color": entity.color,
          "fillColors": entity.color,
          "valueField": entity.key
        })
      }
      return graphs;
    },
    setCategoryAxis() {
      return {
        "title": this.xAxisLabel,
        "gridPosition": "start",
        "axisAlpha": 0,
        "gridAlpha": 0,
        "position": "left",
        "inside": true,
        "labelRotation": 90,
        "minHorizontalGap": 20
      }
    },
    setChartCursor() {
      return {
        "categoryBalloonColor": "#000000",
        "cursorAlpha": 0,
        "valueLineAlpha": 0,
        "valueLineEnabled": false,
        "valueLineBalloonEnabled": false,
        "valueBalloonsEnabled": true,
        "fullWidth": false,
        "categoryBalloonEnabled": false,
        "inside": true,
        "valueZoomable": true
      }
    }
  },
  beforeDestroy() {
  }
}
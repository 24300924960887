<template>
  <HotTable :settings="resource_gantt_settings" ref="hotTableComponent"></HotTable>
</template>

<script>
import { HotTable } from "@handsontable/vue";
import Handsontable from "handsontable";
export default {
  name: "resource-gantt",
  data() {
    return {};
  },
  props: [
    "resource_gantt_data",
    "resource_row",
    "timeline_filter",
    "ref_resource_data"
  ],
  watch: {
    resource_row: function(newVal, oldVal) {
      this.$refs.hotTableComponent.hotInstance.render();
    }
  },
  components: { HotTable },
  methods: {
    navigateToSpecificArea() {
      if (this.timeline_filter !== "Day") {
        return;
      }
      if (this.resource_gantt_data.length > 0 && this.resource_gantt_data[0][4]) {
        var autoRowSize_plugin = this.$refs.hotTableComponent.hotInstance.getPlugin(
          "autoRowSize"
        );
        var first_visible_row = autoRowSize_plugin.getFirstVisibleRow();

        let curDate = moment()
          .startOf("isoweek")
          .format("MMM DD, YYYY");

        let weekOfYear = moment().week();

        let currWeekStr = `Week ${weekOfYear} (${curDate})`;
        // checks start date is future if true then return, does nothing
        let isStartDateIsFutureDate = moment(
          this.resource_gantt_data[0][4].match(/\(([^)]+)\)/)[1]
        ).isAfter();
        if (isStartDateIsFutureDate) {
          return;
        }
        let index;
        let lastIndex = this.resource_gantt_data[0].length - 1;
        for (; lastIndex >= 0; lastIndex--) {
          let item = this.resource_gantt_data[0][lastIndex];
          if (item && item.startsWith("Week")) {
            index = lastIndex;
            break;
          }
        }
        let isEndDateIsPastDate = moment(
          this.resource_gantt_data[0][index].match(/\(([^)]+)\)/)[1]
        ).isBefore();
        //check if End date is pastDate then directly scroll to End of the Page
        if (!isEndDateIsPastDate) {
          // checks for the record for current date if matches then scrolls to that area
          index = this.resource_gantt_data[0].findIndex((item, index) => {
            if (currWeekStr === item) {
              return index;
            }
          });
        }
        this.$refs.hotTableComponent.hotInstance.scrollViewportTo(
          first_visible_row,
          index
        );
      }
    }
  },
  computed: {
    // returns the utilization threhold depemding on the duration filter selected
    overutilization_threshold() {
      var vm = this;
      var threshold;
      switch (this.timeline_filter) {
        case "Day":
          threshold = 8;
          break;
        case "Week":
          threshold = 40;
          break;
        case "Month":
          threshold = 200;
          break;
      }
      return threshold;
    },
    // setting up the HandsonTable component with relevant params
    resource_gantt_settings() {
      var vm = this;
      return {
        data: [[]],
        rowHeights: 27,
        colHeaders: false,
        rowHeaders: true,
        rowHeaderWidth: 0,
        viewportRowRenderingOffset: 10,
        viewportColumnRenderingOffset: 10,
        colWidths: function(index) {
          var INDEX_WIDTH_MAP = [80, 270, 75, 75];
          if (INDEX_WIDTH_MAP[index]) return INDEX_WIDTH_MAP[index];
          else return 35;
        },
        readOnly: true,
        className: "htLeft htBottom",
        fixedColumnsLeft: 4,
        fixedRowsTop: 2,
        mergeCells: [],
        currentRowClassName: "currentRow",
        autoRowSize: false,
        cells: function(row, col) {
          var cellProperties = {};
          function colorRenderer(
            instance,
            td,
            row,
            col,
            prop,
            value,
            cellProperties
          ) {
            if (row === 0 && col < 4) {
              Handsontable.renderers.TextRenderer.apply(this, arguments);
              td.style.fontWeight = "bold";
              td.style.fontSize = "12px";
              td.style.height = "55px";
              td.style.color = "#000";
              if (col < 3)
                td.className = "htCenter htMiddle removeBorderRight colHeader";
              else td.className = "htCenter htMiddle colHeader";
            } else if (row < 2 && col > 3) {
              Handsontable.renderers.TextRenderer.apply(this, arguments);
              td.className = "htCenter htMiddle colHeader leftHeader";
              td.style.fontSize = "12px";
              td.style.color = "#a7a7a7";
              td.style.height = "28px";
              td.style.fontWeight = "lighter";
            } else if (
              row > 1 &&
              col < 4 &&
              vm.resource_row.indexOf(row) > -1
            ) {
              Handsontable.renderers.TextRenderer.apply(this, arguments);
              td.style.background = "#060553"; // set the background color
              td.style.color = "#FFFFFF";
              td.style.fontSize = "12px";
              td.style.cursor = "pointer";
              td.style.fontWeight = "bold";
              if (col === 0) {
                if (
                  vm.ref_resource_data[instance.getDataAtCell(row, col + 1)]
                ) {
                  if (
                    vm.ref_resource_data[instance.getDataAtCell(row, col + 1)]
                      .open === true
                  ) {
                    td.className =
                      "htLeft htMiddle removeBorderRight removeBorderBottom openResource";
                  } else {
                    td.className =
                      "htLeft htMiddle removeBorderRight removeBorderBottom closedResource";
                  }
                }
              } else if (col > 0 && col < 3) {
                td.className =
                  "htLeft htMiddle removeBorderRight removeBorderBottom";
                if (col == 1) {
                  td.setAttribute("data-toggle", "m-tooltip");
                  td.setAttribute("data-placement", "right");
                  td.title = td.innerText;
                }
              } else if (col === 3)
                td.className = "htLeft htMiddle removeBorderBottom";
              else td.className = "htLeft htMiddle";
            } else if (row > 1 && col < 4 && vm.resource_row.indexOf(row) < 0) {
              Handsontable.renderers.TextRenderer.apply(this, arguments);
              td.style.background = "#c4c5d6"; // set the background color // #eaeaea
              td.style.fontSize = "12px";
              td.style.cursor = "pointer";
              if (col === 0)
                td.className =
                  "htLeft htMiddle removeBorderRight removeBorderBottom task";
              else if (col < 3) {
                td.className =
                  "htLeft htMiddle removeBorderRight removeBorderBottom";
                if (col == 1) {
                  td.setAttribute("data-toggle", "m-tooltip");
                  td.setAttribute("data-placement", "right");
                  td.title = td.innerText;
                }
              } else if (col === 3)
                td.className = "htLeft htMiddle removeBorderBottom";
              else td.className = "htLeft htMiddle";
            } else if (
              row > 1 &&
              col > 3 &&
              vm.resource_row.indexOf(row) > -1 &&
              value > 0
            ) {
              Handsontable.renderers.TextRenderer.apply(this, arguments);
              value > vm.overutilization_threshold
                ? (td.style.background = "#e66c73")
                : (td.style.background = "#060553"); // set the background color
              td.style.color = "#FFFFFF";
              td.style.fontSize = "12px";
              td.className = "htRight htMiddle";
            } else if (
              row > 1 &&
              col > 3 &&
              vm.resource_row.indexOf(row) < 0 &&
              value > 0
            ) {
              Handsontable.renderers.TextRenderer.apply(this, arguments);
              td.style.background = "#c4c5d6"; // set the background color
              td.style.fontSize = "12px";
              td.className = "htRight htMiddle";
            } else if (
              row > 1 &&
              col > 3 &&
              (value === "IN" ||
                value === "V" ||
                value === "US" ||
                value === "All")
            ) {
              Handsontable.renderers.TextRenderer.apply(this, arguments);
              td.className = "htRight htMiddle";
              td.style.background = "#939393";
              td.style.color = "#FFFFFF";
              td.style.fontSize = "12px";
            } else {
              Handsontable.renderers.TextRenderer.apply(this, arguments);
              isNaN(value)
                ? (td.className = "htLeft htMiddle")
                : (td.className = "htRight htMiddle");
              td.style.fontSize = "12px";
            }
          }
          cellProperties.renderer = colorRenderer;
          return cellProperties;
        },
        afterOnCellMouseUp: function(event, coords, td) {
          var now = new Date().getTime();
          var autoRowSize_plugin = vm.$refs.hotTableComponent.hotInstance.getPlugin(
            "autoRowSize"
          );
          // check if a double click was trigerred and invoke respective handler
          if (td.lastClick && now - td.lastClick < 200) {
            if (
              (td.innerText === "Resource" ||
                vm.resource_row.indexOf(coords.row) > -1) &&
              coords.col < 4
            ) {
              vm.$emit("dblClickResource", coords);
            }
          }
          // if single click was trigerred , scroll to the first cell which has task hours
          else {
            setTimeout(() => {
              if (coords.col < 4 && coords.row > 1) {
                var task_details = vm.resource_gantt_data[coords.row];
                // scroll to the first cell in a row only if the row is a task row. Skip for the resource row.
                if (task_details && task_details[0] != "Resource") {
                  // find the date column which has hours for the task
                  var index = task_details.findIndex(function(val, index) {
                    if (index > 3 && parseFloat(val)) {
                      return index;
                    }
                  });
                  if (index >= 4) {
                    if (index >= 6) {
                      index -= 2; // scroll to 2 cells before the column which has hours for the task
                    }
                    var first_visible_row = autoRowSize_plugin.getFirstVisibleRow();
                    vm.$refs.hotTableComponent.hotInstance.scrollViewportTo(
                      first_visible_row,
                      index
                    );
                  }
                }
              }
              td.lastClick = now;
            }, 200);
            td.lastClick = now;
          }
        },

        afterLoadData: function() {
          vm.$emit("after:resourceGanttDataLoaded");
        },

        afterRender: function(isForced) {
          $('[data-toggle="m-tooltip"]').tooltip({
            template:
              '<div class="m-tooltip right' +
              ' tooltip" role="tooltip">\
                            <div class="arrow"></div>\
                            <div class="tooltip-inner"></div>\
                        </div>'
          });
        },

        beforeRender: function(isForced) {
          $('[data-toggle="m-tooltip"]').tooltip("dispose");
        }
      };
    }
  }
};
</script>

<style>
  .handsontable .removeBorderRight {
    border-right: none;
  }
  .handsontable .removeBorderBottom {
    border-bottom: none;
  }
  .handsontable .htCore td {
    white-space:nowrap;
  }
  /*to set icon for open resources*/
  .openResource:before {
    font: normal normal normal 16px/1 "LineAwesome";
    content: "\f290";
    font-size: 12px;
    color: white;
  }
  /*to set icons for closed resources*/
  .closedResource:before {
    font: normal normal normal 16px/1 "LineAwesome";
    content: "\f2c4";
    font-size: 12px;
    color: white;
  }
  /*to change the color of the icon on selection*/
  .openResource.currentRow:before, .openResource.current:before,.closedResource.currentRow:before, .closedResource.current:before {
    color: black;
  }
  /*to set background color for the current row and cell*/
  .currentRow, .handsontable td.current{
    background-color: #cee74a !important;
    color: #000 !important;
  }
  /*to remove background color for the current row and cell in header*/
  .currentRow.colHeader, .current.colHeader {
    background-color: #fff !important;
  }
  /*to remove the text color for the current row and cell in the left grid of the header*/
  .currentRow.colHeader.leftHeader, .current.colHeader.leftHeader {
    color: #a7a7a7 !important;
  }
  /*to remove the selection border*/
  .wtBorder.current {
    display: none !important;
  }
  /*to add padding to the task cell*/
  .handsontable .task {
    padding-left: 15px;
  }
</style>

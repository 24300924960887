import ProjectGantt from '../project-gantt/project-gantt.vue'
import ResourceGantt from '../resource-gantt/resource-gantt.vue'
import NoDataHandler from '../no-data-handler/no-data-handler.vue'
// import HotSettings from '../resource-gantt/resource-gantt.js'
import {
  HotTable
} from '@handsontable/vue';
import Handsontable from 'handsontable';
import Sidebar from '../../sidebar/sidebar.vue'



export default {
  name: 'GanttCharts',
  components: {
    ProjectGantt,
    ResourceGantt,
    NoDataHandler,
    Sidebar
  },
  mounted() {
    // Presetting the dates to user selected dates
    const start = new Date();
    const end = new Date();
    end.setTime(end.getTime() + 3600 * 1000 * 24 * 30);
    // comment
    var startDate = this.$route.query.start_date ? new Date(this.$route.query.start_date) : start;
    var endDate = this.$route.query.end_date ? new Date(this.$route.query.end_date) : end;
    this.selected_date_range = [startDate, endDate];

    this.check_autherization();
    this.loadProjectPlans();
    this.selected_project = Number(window.location.pathname.split('/')[2]);

    this.refreshGanttData();

    // to dynamically append gantt-controls to tab view of gantt charts
    $('.gantt-charts .el-tabs__nav-scroll').append($('.gantt-controls'));

    var vm = this;
    // to render gantt charts on fullscreen
    var observer3 = new MutationObserver(function (mutations) {
      mutations.forEach(function (mutationRecord) {
        vm.renderGanttChart();
      });
    });
    var target3 = document.getElementById('gantt_full_screen');
    observer3.observe(target3, {
      attributes: true,
      attributeFilter: ['class']
    });


    //to render project gantt chart on tab change
    var observer1 = new MutationObserver(function (mutations) {
      mutations.forEach(function (mutationRecord) {
        gantt.render();
      });
    });
    var target1 = document.getElementById('pane-project-gantt-tab');
    observer1.observe(target1, {
      attributes: true,
      attributeFilter: ['style']
    });

    // to update merge cells on tab change
    var observer2 = new MutationObserver(function (mutations) {
      mutations.forEach(function (mutationRecord) {
        if (vm.resource_gantt_data.length > 2)
          vm.updateMergeCells();
      });
    });
    var target2 = document.getElementById('pane-resource-gantt-tab');
    observer2.observe(target2, {
      attributes: true,
      attributeFilter: ['style']
    });

    // Fullscreen by default
    mPortlet("fullscreen-body").fullscreen();

    // listens to the afterLoadData even for resource gantt
    // unsets the loading after the  data passed to hansontable is loaded
    vm.$refs.resource_gantt.$on('after:resourceGanttDataLoaded', function () {
      vm.loading = false;
    })
    // unsets the loading after the  data passed to dhtmlx gantt is rendered
    vm.$refs.gantt_chart.$on('after:projectGanttRendered', function () {
      vm.loading = false;
    })

    if (window.location.href.split("=")[1] == 'resource#/')
      vm.activeTabName = 'resource-gantt-tab'
    else if (window.location.href.split("=")[1] == 'project#/')
      vm.activeTabName = 'project-gantt-tab';

    new Clipboard('[data-clipboard=true]', {
      target: function (trigger) {
        return vm.copyProjectGanttData();
      }
    }).on('success', function (e) {
      bootstrapNotify('Copied', 'success');
      document.body.removeChild($('#copy-table')[0]);
    });

    vm.$root.$on('projectSynced', () => {
      vm.refreshGanttData();
    });
  },
  data() {
    return {
      holidays: {},
      resource_gantt_data: [
        []
      ],
      ref_resource_data: {},
      resource_list: [],
      resource_row: [],
      visible_res_data: [],
      project_gantt_data: {
        data: [],
        links: []
      },
      recurrence: 'weekly',
      loading: true,
      gantt_loading: false,
      projects: [],
      modify_permission: false,
      selected_project: Number(window.location.pathname.split('/')[2]), // project id window.location.pathname.split('/')[2]
      selected_date_range: [new Date(), new Date()],
      selected_date_range_cache: [],
      show_lifetime: true,
      tabPosition: 'top',
      activeTabName: 'project-gantt-tab',
      selected_filter_project: 'Task',
      selected_filter_resource: 'Resource',
      syncActive: false,
      dialogFormVisible: false, //syncDialog
      resource_col_width: 35,
      options: [{
        value: 'Day',
        label: 'Day'
      }, {
        value: 'Week',
        label: 'Week'
      }, {
        value: 'Month',
        label: 'Month'
      }],
      selected_timeline_filter: 'Day',
      pickerOptions: {
        shortcuts: [{
          text: 'Next week',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            end.setTime(end.getTime() + 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: 'Next month',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            end.setTime(end.getTime() + 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: 'Next 3 months',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            end.setTime(end.getTime() + 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      }
    }
  },
  computed: {
    // verify once
    getSelectedProjectName() {
      var vm = this;
      for (var i = 0; i < vm.projects.length; i++) {
        if (vm.projects[i].id === vm.selected_project) {
          return vm.projects[i].name;
        }
      }
      return '';
    },
    merge_cells() {
      if (this.resource_gantt_data.length > 1) {
        var merge_cells = [{
            row: 0,
            col: 0,
            rowspan: 2,
            colspan: 1
          },
          {
            row: 0,
            col: 1,
            rowspan: 2,
            colspan: 1
          },
          {
            row: 0,
            col: 2,
            rowspan: 2,
            colspan: 1
          },
          {
            row: 0,
            col: 3,
            rowspan: 2,
            colspan: 1
          }
        ]

        if (this.selected_timeline_filter == "Day") {
          var col = this.resource_gantt_data[0];
          var length = col ? col.length : 0;
          for (var i = 4; i < length; i += 5) {
            merge_cells.push({
              row: 0,
              col: i,
              rowspan: 1,
              colspan: 5
            });
          }
        }
        return merge_cells;
      } else {
        return [];
      }
    },
    resource_col_widths() {
      var col_widths = [80, 270, 75, 75];
      var col = this.resource_gantt_data[0];
      var length = col ? col.length : 0;
      if (this.selected_timeline_filter == 'Day') {
        for (var i = 4; i < length; i++) {
          col_widths.push(35);
        }
      } else {
        for (var i = 4; i < length; i++) {
          col_widths.push(105);
        }
      }
      return col_widths;
    },
    resource_gantt_visibilty() {
      if (this.resource_gantt_data.length > 2) {
        return true;
      } else {
        return false;
      }
    },
    project_gantt_visibilty() {
      if (this.project_gantt_data['data'].length != 0) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    check_autherization() {
      var vm = this;
      vm.loading = true;
      this.$http.get('/authorization/api_autherization/check_permissions', {
          params: {
            page: "estimo, estimate",
            function: "create?"
          }
        })
        .then(function (response) {
          this.modify_permission = response.body.permit;
          if (this.modify_permission === true)
            bootstrapNotify('Please sync project plans before each use to utilize the latest information.', 'info', 10000);
        });
    },
    toggleLifetime() {
      var vm = this;
      vm.loadProjectGanttData();
      vm.loadResourceGanttData();
    },
    applyFilterProject: function () {
      var vm = this;
      vm.loading = true;
      vm.$refs.gantt_chart.applyfilter();
    },
    applyFilterResource: function () {
      var vm = this;
      vm.loading = true;
      vm.resource_list = Object.keys(vm.ref_resource_data);
      if (vm.selected_filter_resource === "Resource") {
        for (var i = 0; i < vm.resource_list.length; i++) {
          vm.ref_resource_data[vm.resource_list[i]].open = false;
        }
      } else {
        for (var i = 0; i < vm.resource_list.length; i++) {
          vm.ref_resource_data[vm.resource_list[i]].open = true;
        }
      }
      vm.create_resource_data();
      //This below code is to scroll to specificView in resource gantt chart page 
      setTimeout(() => {
        vm.$refs.resource_gantt.navigateToSpecificArea();
      }, 100)
    },
    updateMergeCells: function () {
      this.$refs.resource_gantt.$refs.hotTableComponent.hotInstance.updateSettings({
        mergeCells: this.merge_cells,
        colWidths: this.resource_col_widths
      });
    },
    // get data for project gantt chart
    loadProjectGanttData: function () {
      var vm = this;
      vm.loading = true;
      this.$http.get('/api/v1/project_plans/' + vm.selected_project + '/project_gantt_report', {
          params: {
            start_date: vm.selected_date_range[0].toLocaleDateString('en-US'),
            end_date: vm.selected_date_range[1].toLocaleDateString('en-US'),
            lifetime: vm.show_lifetime
          }
        })
        .then(function (response) {
          var response_body = response.body.body;
          this.project_gantt_data = {
            data: JSON.parse(response_body),
            links: []
          };
          gantt.clearAll();
          gantt.parse(this.project_gantt_data);
          vm.loading = false;
          vm.$refs.gantt_chart.applyfilter();
          vm.$refs.gantt_chart.scrollToDate(vm.selected_date_range[0]);
        });
    },
    // get data for resource gantt chart
    loadResourceGanttData: function () {
      var vm = this;
      vm.loading = true;
      // move the url to a method
      this.$http.get('/api/v1/project_plans/' + vm.selected_project + '/resource_gantt_report', {
          params: {
            start_date: vm.selected_date_range[0].toLocaleDateString('en-US'),
            end_date: vm.selected_date_range[1].toLocaleDateString('en-US'),
            interval: vm.selected_timeline_filter,
            lifetime: vm.show_lifetime
          }
        })
        .then(function (response) {
          var response_body = response.body.body;
          this.resource_gantt_data = JSON.parse(response_body);
          this.create_ref_res_data();
          this.applyFilterResource();
          // Handsonable table renders only if the Tab is visible.
          if (this.activeTabName === 'resource-gantt-tab') {
            this.updateMergeCells();
          }
          vm.loading = false;
        });
    },
    // Gets all the active project plans for the select box
    loadProjectPlans: function () {
      var vm = this;
      vm.loading = true;
      vm.$http.get('/api/v1/project_plans/options_for_select')
        .then(function (response) {
          vm.projects = response.body.body;
          vm.loading = false;
        });
    },
    projectSelectionHandler() {
      var vm = this;
      // vm.getGsheetUrl();
      vm.loadProjectGanttData();
      vm.loadResourceGanttData();
    },
    refreshGanttData() {
      var vm = this;
      vm.loadProjectGanttData();
      vm.loadResourceGanttData();
    },
    timelineSelectionHandler() {
      var vm = this;
      vm.loadResourceGanttData();
    },
    handleTabClick(tab, event) {
      let vm = this;
      if (this.activeTabName === "resource-gantt-tab") {
        setTimeout(() => {
          vm.$refs.resource_gantt.navigateToSpecificArea();
        }, 100)
      }
    },
    renderGanttChart: function () {
      if (this.activeTabName === 'project-gantt-tab') {
        gantt.render();
      } else {
        this.$refs.resource_gantt.$refs.hotTableComponent.hotInstance.render();
      }
      return true;
    },
    onDblClickResource: function (coords) {
      var selected_res = this.$refs.resource_gantt.$refs.hotTableComponent.hotInstance.getDataAtCell(coords.row, 1);
      this.ref_resource_data[selected_res].open = !this.ref_resource_data[selected_res].open;
      this.create_resource_data();
    },
    create_ref_res_data: function () {
      var vm = this;
      var index;
      vm.ref_resource_data = {};
      vm.resource_list = Object.keys(vm.ref_resource_data);
      for (var i = 2; i < vm.resource_gantt_data.length; i++) {
        if (vm.resource_gantt_data[i][0] === "Resource") {
          vm.ref_resource_data[vm.resource_gantt_data[i][1]] = {
            row: i,
            open: true,
            children: []
          };
          index = vm.resource_list.push(vm.resource_gantt_data[i][1]);
        } else {
          vm.ref_resource_data[vm.resource_list[index - 1]].children.push(i);
        }
      }
    },
    create_resource_data: function () {
      // debugger;
      var vm = this;
      vm.visible_res_data = [];
      vm.resource_row = [];
      vm.resource_list = Object.keys(vm.ref_resource_data);
      var res_row_index;
      var child_task;
      vm.visible_res_data.push(vm.resource_gantt_data[0]);
      vm.visible_res_data.push(vm.resource_gantt_data[1]);
      for (var i = 0; i < vm.resource_list.length; i++) {
        res_row_index = vm.visible_res_data.push(vm.resource_gantt_data[vm.ref_resource_data[vm.resource_list[i]].row]);
        vm.resource_row.push(res_row_index - 1);
        if (vm.ref_resource_data[vm.resource_list[i]].open === true) {
          child_task = vm.ref_resource_data[vm.resource_list[i]].children;
          child_task.forEach(function (index) {
            vm.visible_res_data.push(vm.resource_gantt_data[index]);
          });
        }
      }
      vm.$refs.resource_gantt.$refs.hotTableComponent.hotInstance.loadData(vm.visible_res_data);
      // once all resources are collapsed, the View filter should also toggle to 'Resource' from 'Task'
      // to check if all the resources are collapsed, compare the number of visible rows with the  number of resource rows
      if (vm.visible_res_data.length == (vm.resource_list.length + 2)) {
        // select "Resource" filter
        vm.selected_filter_resource = "Resource"
      }
      // once all resources are expanded, the View filter should also toggle to 'Task' from 'Resource'
      // to check if all the resources are expanded, compare the number of visible rows with the number of resource gantt data rows
      else if (vm.visible_res_data.length == vm.resource_gantt_data.length) {
        // select "Task" filter
        vm.selected_filter_resource = "Task"
      }
    },
    export_url(format) {
      return ("/estimates/" + this.selected_project + "/gant_chart_export." + format)
    },
    copyProjectGanttData() {
      var vm = this;
      var json_data = vm.project_gantt_data.data;
      var table = document.createElement("table");
      table.style.position = 'absolute';
      table.style.left = '-99999px';
      table.setAttribute('id', 'copy-table');
      var tr = table.insertRow(-1); // TABLE ROW.
      var col = ['Task ID', 'Task Description', 'Start Date', 'End Date'];
      for (var i = 0; i < col.length; i++) {
        var th = document.createElement("th"); // TABLE HEADER.
        th.innerHTML = col[i];
        tr.appendChild(th);
      }
      json_data.forEach(function (task_row) {
        tr = table.insertRow(-1);
        for (var index in task_row) {
          if (index == 'task_id' || index == 'text') {
            var tabCell = tr.insertCell(-1);
            tabCell.innerHTML = task_row[index];
          } else if (index == 'start_date') {
            var tabCell = tr.insertCell(-1);
            tabCell.innerHTML = task_row[index].toLocaleDateString('en-US');
          } else if (index == 'end_date') {
            var tabCell = tr.insertCell(-1);
            var end_date = new Date(task_row[index]);
            end_date.setDate(end_date.getDate() - 1);
            tabCell.innerHTML = end_date.toLocaleDateString('en-US');
          }
        }
      });
      document.body.appendChild(table);
      return $('#copy-table')[0];
    }
  }
}
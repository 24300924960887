import Axios from 'axios-observable';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { formatDateObject } from "../../utils/utils";

class talentForecastService {

  forcastDataloading = new BehaviorSubject(false);
  detailedForecastDataloading = new BehaviorSubject(false);

  apiUrlConstants = {
    talentFilterApiUrl: '/api/v1/employees/options_for_select',
    groupFilterApiUrl: '/api/v1/departments/options_for_select',
    verticalFilterApiUrl: '/api/v1/teams/options_for_select',
    projectFilterApiUrl: '/api/v1/project_plans/unclosed/options_for_select',
    locationsApiUrl: '/api/v1/locations/options_for_select',
    capabilityApiUrl: '/api/v1/employees/capability/options_for_select',
    currentUserApiUrl: '/employees/fetch_current_employee.json',
    summaryApiUrl: '/api/v1/forecast/total_report',
    detailedApiUrl: '/api/v1/forecast/detailed_report',
  }

  constructor() { }

  getCurrentUser() {
    return Axios.get(this.apiUrlConstants.currentUserApiUrl)
      .pipe(
        map(response => {
          return response.data;
        })
      );
  }

  getFilterOptions(talentReportType) {
    let filterapiUrl = '';
    switch (talentReportType) {
      case 'Talent':
        filterapiUrl = this.apiUrlConstants.talentFilterApiUrl;
        break;
      case 'Group':
        filterapiUrl = this.apiUrlConstants.groupFilterApiUrl;
        break;
      case 'Vertical':
        filterapiUrl = this.apiUrlConstants.verticalFilterApiUrl;
        break;
      case 'Project':
        filterapiUrl = this.apiUrlConstants.projectFilterApiUrl;
        break;
      case 'Location':
        filterapiUrl = this.apiUrlConstants.locationsApiUrl;
        break;
      case 'Capability':
        filterapiUrl = this.apiUrlConstants.capabilityApiUrl;
        break;
      default:
        filterapiUrl = '';
        break;
    }

    return Axios.get(filterapiUrl)
      .pipe(
        map(response => response.data.body)
      );
  }

  getLocations() {
    return Axios.get(this.apiUrlConstants.locationsApiUrl)
      .pipe(
        map(response => response.data.body)
      );
  }

  getRoles() {
    return Axios.get(this.apiUrlConstants.capabilityApiUrl)
      .pipe(
        map(response => response.data.body)
      );
  }

  loadForecastData(talentReportType, startDate, endDate, recurrenceType, estimateStatus, selectedName, threshold, locations, roles, projectType, selectedPlannedHourOption) {
    this.forcastDataloading.next(true);
    return Axios.get(this.apiUrlConstants.summaryApiUrl, {
      params: {
        "talent_report_type": talentReportType,
        "start_date": formatDateObject(startDate, '/'),
        "end_date": formatDateObject(endDate, '/'),
        "recurrence_type": recurrenceType,
        "estimate_status": estimateStatus,
        "talent_report_filter": selectedName,
        "threshold_value": threshold,
        "location_id": locations,
        "project_type": projectType,
        "roles": roles,
        "planned_hour_type": selectedPlannedHourOption
      }
    })
      .pipe(
        map(response => {
          this.forcastDataloading.next(false);
          return response.data.body;
        })
      );
  }

  loadDetailedForecastData(talentReportType, startDate, endDate, recurrence, estimateStatus, talentReportFilter, threshold, locations, roles, viewType, projectType, selectedPlannedHourOption) {
    this.detailedForecastDataloading.next(true);
    return Axios.get(this.apiUrlConstants.detailedApiUrl, {
      params: {
        "talent_report_type": talentReportType,
        "start_date": formatDateObject(startDate, '/'),
        "end_date": formatDateObject(endDate, '/'),
        "recurrence_type": recurrence,
        "estimate_status": estimateStatus,
        "talent_report_filter": talentReportFilter,
        "threshold_value": threshold,
        "location_id": locations,
        "forecast_breakdown": viewType,
        "project_type": projectType,
        "roles": roles,
        "planned_hour_type": selectedPlannedHourOption
      }
    })
      .pipe(
        map(response => {
          this.detailedForecastDataloading.next(false);
          return response.data.body;
        })
      );
  }

}

export default new talentForecastService();
import accrualsDashboardService from "../../accruals-dashboard-service";
import metronicTable from "../../../../../components/metronic-table/metronic-table";
import metronicPagination from "../../../../../components/metronic-pagination/metronic-pagination";
import ProjectAccrualCreateService from "../../../project-accrual-create/project-accrual-create-service";
import EventBus from "../../../project-accrual-report/global-event-bus";

export default {
  name: "project-list-table",
  components: {
    metronicTable,
    metronicPagination
  },
  data() {
    return {
      accrualsDashboardService: accrualsDashboardService,
      ProjectAccrualCreateService: ProjectAccrualCreateService,
      employeeSelectOptions: [],
      projectManagerFilterOptions: [],
      metronicTableKey: '',
      actionBtns: {
        mainLabel: "View",
        link: true,
        name: "project-accrual-report",
        param: 'id',
        options: [
          {
            label: 'Status',
            link: false,
            param: 'active',
            checkPermission: true,
            eventName: 'updateAccrualStatus',
            reload: true
          }
          //example to dynamically configure options in dropdown
          // {
          //   label: 'View',
          //   link: true,
          //   param: 'active',
          //   name: "project-accrual-report",
          //   param: 'id'
          // }
        ]
      },
      tableHeaders: [{
          key: "name",
          label: "Project Name",
          sort: {
            required: true,
            icon: ""
          },
          search: {
            required: true,
            type: "text",
            placeholder: "Search by name",
            defaultValue: null,
            eventName: "fetchAccrualFilterData"
          }
        },
        {
          key: "account_manager_name",
          label: "Account Manager",
          sort: {
            required: true,
            icon: ""
          },
          search: {
            required: true,
            filterable: true,
            type: "dropdown",
            defaultValue: -1,
            selectedValue: -1,
            eventName: "fetchAccrualFilterData"
          }
        },
        {
          key: "project_manager_name",
          label: "Project Manager",
          sort: {
            required: true,
            icon: ""
          },
          search: {
            required: true,
            type: "dropdown",
            defaultValue: -1,
            eventName: "fetchAccrualFilterData"
          }
        },
        {
          key: "jira_code",
          label: "Jira Code",
          sort: {
            required: true,
            icon: ""
          },
          search: {
            required: true,
            type: "text",
            placeholder: "Search by code",
            defaultValue: null,
            eventName: "fetchAccrualFilterData"
          }
        },
        {
          key: "active",
          label: "Status",
          isStatus: true,
          sort: {
            required: false,
            icon: ""
          },
          search: {
            required: true,
            type: "dropdown",
            defaultValue: 'all',
            selectedValue: 'all',
            eventName: "fetchAccrualFilterData",
            options: [{
                name: "All",
                id: "all"
              },
              {
                name: "Active",
                id: true
              },
              {
                name: "Inactive",
                id: false
              }
            ]
          }
        },
        {
          key: "action",
          label: "Actions",
          isAction: true,
          sort: {
            required: false,
            icon: ""
          },
          search: {
            required: false
          },
          buttons: [
            {
              type: "text",
              text: "Search",
              class: "btn btn-outline-success btn-sm",
              eventName: "fetchAccrualFilterData"
            },
            {
              type: "anchor",
              text: "Reset",
              class: "btn btn-outline-danger btn-sm",
              eventName: "resetAccrualListView"
            }
          ],
          // actionBtns: {
          //     mainLabel: "Viewss",
          //     link: true,
          //     name: "project-accrual-report",
          //     param: 'id',
          //     checkPermission: false,
          //     options: [
          //       {
          //         label: 'Status',
          //         link: false,
          //         param: 'active',
          //         checkPermission: true,
          //         eventName: 'updateAccrualStatus'
          //       }
          //     ]
          //   }
        }
      ]
    };
  },

  props: {
    currentUser: {
      id: null
    },
    loader: {
      type: Boolean,
      required: true
    },
    projectListData: {
      type: Array
    },
    selectedPM: {
      type: Object
    }
  },
  created() {
    EventBus.$on("sortAccruals", sortParams => {
      let iconClass = "";
      this.tableHeaders.forEach((header, index) => {
        header.sort.icon = "";
      });
      if (sortParams.sort_order === "asc") {
        iconClass = "fa fa-caret-up";
      }
      if (sortParams.sort_order === "desc") {
        iconClass = "fa fa-caret-down";
      }
      let index = this.tableHeaders.findIndex(item => {
        if (item.key == sortParams.col_name) {
          return this.tableHeaders.indexOf(item.key);
        }
      });
      this.tableHeaders[index].sort.icon = iconClass;
    });

    this.ProjectAccrualCreateService.getEmployeeFilterOptions().subscribe(response => {
      return this.employeeSelectOptions = response;
    });

    this.accrualsDashboardService.getProjectManagersList().subscribe(response => {
        return this.projectManagerFilterOptions = response;
    });
  },
  computed: {
    employees() {
      this.employeeSelectOptions.unshift({
        id: -1,
        name: "All Account Managers"
      });
      return this.employeeSelectOptions;
    },
    formatedProjectManagerFilterOptions() {
      this.projectManagerFilterOptions.unshift({
        id: -1,
        name: "All Project Managers"
      });
      return this.projectManagerFilterOptions;
    },
    headers() {
      this.tableHeaders.forEach((item, index) => {
        if (item.search.required) {
          if (item.key === 'account_manager_name') {
            
            this.tableHeaders[index].search.options = this.employees;
          }
          if (item.key === 'project_manager_name') {
            this.tableHeaders[index].search.options = this.formatedProjectManagerFilterOptions;
            this.tableHeaders[index].search.defaultValue = this.selectedPM.id;
            this.tableHeaders[index].search.selectedValue = this.selectedPM.id;
          }
        }
      });
      this.metronicTableKey++;
      return this.tableHeaders;
    }

  },
  watch: {
    currentUser(val) {
      this.currentUser = val;
    },
    projectListData(val) {
      if (val) {
        this.projectListData = val;
      } else {
        this.projectListData = [];
      }
    },
    loader(newVal) {
      if (newVal) {
        this.loader = newVal;
      } else {
        this.loader = false;
      }
    },
    selectedPM(newVal) {
      this.selectedPM = newVal;
    },
  }
};
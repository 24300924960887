import NotesModal from "../notes-modal/notes-modal.vue";
import AttachmentsModal from "../attachments-modal/attachments-modal.vue";
// EventBus.$emit('updateProjectSummary', null);

// EventBus.$on('onSnapshotWeekChange', selectedWeek => {
//   this.selectedWeek = selectedWeek;
// });

export default {
  name: 'metronic-table',
  components: {
    NotesModal,
    AttachmentsModal,
  },
  data() {
    return {
      filterParams: {},
      sortOrder: 'asc',
      oldExpensedetails: {},
      openNotesModal: false,
      openAttachmentsModal: false,
      datePickerOptions: {
        shortcuts: [{
          text: 'Last week',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: 'Last month',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: 'Last 3 months',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      workingItem: {
        notes: '',
        attachments: [],
      },
    }
  },
  props: {
    eventBus: {
      type: Object,
      required: true
    },
    headers: {
      type: Array,
      required: true
    },
    loader: {
      type: Boolean,
      required: true
    },
    tableData: {
      type: Array,
      required: true
    },
  },
  created() {
    console.log("tableData", this.tableData);
  },
  mounted() {},
  watch: {
    headers(newVal) {
      this.headers = newVal;
    },
    tableData(newVal) {
      if (newVal) {
        this.tableData = newVal;
      } else {
        this.tableData = null;
      }
    },
    loader(newVal) {
      if (newVal) {
        this.loader = newVal;
      } else {
        this.loader = false;
      }
    }
  },
  computed: {},
  methods: {
    emitEvent(id, value, eventName = null) {
      if (eventName) {
        this.eventBus.$emit(eventName, {
          'id': id,
          'value': value
        });
      }
    },
    rowDataUpdated(id, key, value) {
      const body ={ id }
      body[key] = value;
      this.$emit('rowDataUpdated', body);
    },
    saveExpenseDetails(refKey, id, key, value) {
      if (value == 1) {
        // Hack to open the el-select dropdown is make "visible" variable true
        setTimeout(() => {
          this.$refs[refKey][0].visible = true;
        }, 0);
      }
      this.rowDataUpdated(id, key, value);
    },
    getFilterData(sort = false, sortKey = '', search = false) {
      if (sort) {
        this.filterParams.sort = this.sortColumn(sortKey);
      }
      if (search) {
        this.filterParams.search = this.getSearchFilter()
      }
      // eventName = eventName ? eventName : (this.filterParams.sort ? this.filterParams.sort.eventName : '');
      // eventName ? this.eventBus.$emit(eventName, this.filterParams) : '';
      this.$emit('searchQuery', this.filterParams);
    },
    getSearchFilter() {
      const searchAccrualFormData = {};
      this.headers.forEach(element => {
        if (element.search.required) {
          searchAccrualFormData[element.key] = element.search.type === 'text' ? element.search.defaultValue : element.search.selectedValue;
        }
      });
      return searchAccrualFormData;
    },
    resetFilter() {
      this.filterParams = {};
      this.headers.forEach(element => {
        if (element.search.required) {
          if (element.search.type === 'text') {
            element.search.defaultValue = null;
          } else {
            element.search.selectedValue = element.search.defaultValue;
          }
        }
      });
      this.$emit('searchQuery', null);
    },
    sortColumn(colName) {
      this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
      let eventName;
      let sort = {};
      this.headers.forEach(element => {
        if (element.key === 'action' && element.buttons) {
          element.buttons.forEach(item => {
            if (item.text === 'Search') {
              eventName = item.eventName;
            }
          })
        }
        element.sort.value ? (element.sort.icon = '') : '';
        if (element.key === colName && element.sort.value) {
          element.sort.icon = this.sortOrder === 'asc' ? 'fa fa-caret-up' : (this.sortOrder === 'desc' ? 'fa fa-caret-down' : '');
        }
      });
      sort.col_name = colName;
      sort.sort_order = this.sortOrder;
      sort.eventName = eventName;
      return sort;
    },
    openNotesModalHandler(_workingItem) {
      this.workingItem = _workingItem;
      this.openNotesModal = true;
    },
    closeNotesModalHandler(_notes) {
      this.workingItem.notes = _notes;
      const body = { id: this.workingItem.id, notes:  _notes};
      this.$emit('rowDataUpdated', body);

      this.workingItem = {
        notes: '',
        attachments: [],
      };
    },
    openAttachmentsModalHandler(_workingItem) {
      this.workingItem = _workingItem;
      this.openAttachmentsModal = true;
    },
    closeAttachmentsModalHandler(_attachments) {
      const body = { id: this.workingItem.id }
      body['attachments'] = _attachments.map(item => item.id);
      this.$emit('rowDataUpdated', body);
      this.workingItem.attachments = _attachments;
      this.workingItem = { notes: '', attachments: []};
    },
    saveOldExpenseDetails(event, expenseChargedToClient, expenseChargedProjectName, expenseChargedClientName) {
      if (!event) {
        this.oldExpensedetails = {};
        return;
      }
      this.oldExpensedetails.expenseChargedToClient = expenseChargedToClient;
      this.oldExpensedetails.expenseChargedProjectName = expenseChargedProjectName;
      this.oldExpensedetails.expenseChargedClientName = expenseChargedClientName;
    },
    updateExpenseDetails(refKey, id, expenseChargedToClient, expenseChargedProjectName, expenseChargedClientName) {
      const body = { id }
      body['expense_charged_to_client'] = expenseChargedToClient;
      body['expense_charged_project_name'] = expenseChargedProjectName;
      body['expense_charged_client_name'] = expenseChargedClientName;

      this.$emit('rowDataUpdated', body);
      // Hack to close the el-select dropdown is make "visible" variable false
      this.$refs[refKey][0].visible = false;
    },
    cancelExpenseDetailsUpdate(refKey, item) {
      item['expense_charged_to_client'] = this.oldExpensedetails.expenseChargedToClient;
      item['expense_charged_project_name'] = this.oldExpensedetails.expenseChargedProjectName;
      item['expense_charged_client_name'] = this.oldExpensedetails.expenseChargedClientName;
      this.oldExpensedetails = {};
      // Hack to close the el-select dropdown is make "visible" variable false
      this.$refs[refKey][0].visible = false;
    },
  },
}
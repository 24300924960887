import ProjectSummaryPanel from "./components/project-summary-panel/project-summary-panel.vue";
import SnapshotPanel from "./components/snapshot-panel/snapshot-panel.vue";
import breadcrumb from '../../../components/breadcrumb/breadcrumb';
import elWeekPicker from "../../../components/el-week-picker/el-week-picker";
import metronicHamburgerMenu from "../../../components/metronic-hamburger-menu/metronic-hamburger-menu";
import elYearPicker from "../../../components/el-year-picker/el-year-picker";
import EventBus from "./global-event-bus";
import {
  snapshotService
} from "./services/snapshop-service";
import {
  formatDateObject
} from "../../../utils/utils";
import Sidebar from "../../../components/sidebar/sidebar";
import ProjectAccrualCreate from '../project-accrual-create/project-accrual-create';

export default {
  name: 'project-accrual-report',
  components: {
    ProjectSummaryPanel,
    SnapshotPanel,
    breadcrumb,
    elWeekPicker,
    metronicHamburgerMenu,
    elYearPicker,
    Sidebar,
    ProjectAccrualCreate
  },
  data() {
    return {
      snapshotService: snapshotService,
      summaryPanel: {},
      selectedWeek: null,
      openEditProjectAccrual: false,
      currentUser: null,
      projectId: null,
      selectedYear: new Date()
    };
  },
  created() {
    this.selectedWeek = this.getMonday();
    this.fetchCurrentUserDetails();
    EventBus.$on('sendSummaryPanel', summaryPanel => {
      this.summaryPanel = summaryPanel;
    })
    EventBus.$on('onSnapshotWeekChange', updatedWeek => {
      this.selectedWeek = updatedWeek;
    });
  },
  mounted() {
    // EventBus.$emit('selectedWeek', formatDateObject(this.selectedWeek, '/'));
    this.filterAccrualTableData();
  },
  computed: {
    breadcrumbOptions() {
      this.projectId = this.$route.params && this.$route.params ? this.$route.params.id : 0;
      return [{
          "label": "Accruals Dashboard",
          "isHref": false,
          "href": "/"
        },
        {
          "label": this.summaryPanel.project_name ? this.summaryPanel.project_name : '',
          "isHref": false,
          "href": "/project/" + this.projectId
        }
      ]
    },
    showSidebar() {
      if (this.currentUser && (this.currentUser.permissions['AccrualPolicy'] === 'modify' || this.currentUser.permissions['AccrualPolicy'] === 'write')) {
        return true;
      }
      return false;
    }
  },
  methods: {
    getMonday() {
      const d = new Date();
      const day = d.getDay();
      const diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
      const newDate = new Date(d.setDate(diff));
      return newDate;
    },

    // sendSummaryPanelHandler(summaryPanel) {
    //   this.summaryPanel = summaryPanel;
    // },

    // selectedWeekHandler() {
    //   EventBus.$emit('selectedWeek', formatDateObject(this.selectedWeek, '/'));
    // },

    fetchCurrentUserDetails() {
      this.snapshotService.getCurrentUser()
        .subscribe(currentUser => this.currentUser = currentUser)
    },

    emitExportSnapshot() {
      EventBus.$emit('emitExportSnapshot');
    },
    filterAccrualTableData(){
      let weekDate = formatDateObject(this.selectedWeek, '/');
      let year = this.selectedYear.getFullYear();
      let obj = {
        "weekDate": weekDate,
        "year": year
      }
      EventBus.$emit('filterAccrualSnapshot', obj);
    }

  },

  beforeDestroy() {}
}
export default {
  name: 'LineChart',
  // props: [
  //   'data',
  //   'collection',
  //   'yAxisLabel',
  //   'threshold',
  //   'scrollBar',
  //   'showBalloonText',
  //   'xAxisKey',
  //   'balloonTextValuePrefix',
  //   'balloonTextValuePostfix'
  // ],

  props: {
    data: {
      required: true
    },
    collection: {
      required: true
    },
    yAxisLabel: {
      required: false
    },
    threshold: {
      required: false
    },
    scrollBar: {
      type: Boolean,
      required: false,
      default: false
    },
    showBalloonText: {
      type: Boolean,
      required: false,
      default: true
    },
    xAxisKey: {
      type: String,
      required: false,
      default: ''
    },
    balloonTextValuePrefix: {
      type: String,
      required: false,
      default: ''
    },
    balloonTextValuePostfix: {
      type: String,
      required: false,
      default: ''
    },
  },

  mounted() {
    this.init(this.data);
  },

  data: function () {
    return {
      chart: {},
      range: {},
      legend: {},
      valueAxes: [],
      graphs: [],
      cursor: {},
      categoryAxis: {},
      colors: ["#00BAFF", "#1715AD", "#3BFF8a", "#FF6471", "#ED00BE", "#DDFF20", "#83FFC5", "#3A397B", "#FFCC00", "#19EFD1", "#FF0052", "#008082"],
      colorIndex: 0
    }
  },
  computed: {
    dataCollection() {
      return ( this.data && this.collection )
    }
  },
  watch: {
    dataCollection(value) {
      this.chart.graphs = this.plotChart(this.collection);
      this.chart.trendLines = this.setTrendLines(this.threshold);
      this.colorIndex = 0;
      if (this.scrollBar) {
        this.initialiseScrollBar(this.collection);
      }
      this.updateChartData(this.data);
    },
    threshold(val) {
      if(!val) { return; }

      this.chart.trendLines = this.setTrendLines(val);
      this.chart.validateData();
    },
    yAxisLabel(val) {
      this.chart.valueAxes[0].title = val;
      this.chart.validateData();
    }
  },
  methods: {
    init(chartData) {
      this.initializeChart(chartData);
      if (this.scrollBar) {
        this.initialiseScrollBar(this.collection);
      }
    },

    // Initialize the data
    initializeChart(chartData) {

      this.chart = AmCharts.makeChart(this.$refs.chartdiv, {
        "type": "serial",
        "theme": "light",
        "pathToImages": "/amCharts/images/",
        "legend": {
          "useGraphSettings": false,
          "divId": this.$refs.legenddiv,
          "valueText": `${this.balloonTextValuePrefix}[[value]]${this.balloonTextValuePostfix}`,
          "horizontalGap": 50,
          "valueWidth": 80
        },
        "dataProvider": chartData,
        "synchronizeGrid": true,
        "valueAxes": [{
          "axisColor": "",
          "axisThickness": 2,
          "axisAlpha": 1,
          "position": "left",
          "title": this.yAxisLabel ? this.yAxisLabel  : "Hours Per Project"
        }],
        "graphs": this.plotChart(this.collection),

        "chartCursor": {
          "cursorPosition": "mouse",
          "cursorAlpha": 0,
          "valueLineAlpha": 0,
          "valueLineEnabled": true,
          "valueLineBalloonEnabled": true
        },
        "categoryField": this.xAxisKey,
        "categoryAxis": {
          "parseDates": true,
          "axisColor": "#DADADA",
          "minorGridEnabled": true,
          "gridAlpha": 0.25,
          "minorGridAlpha": 0
        },
        "trendLines" : this.setTrendLines(this.threshold)
      });
    },

    initialiseScrollBar(collection) {
      this.chart.chartScrollbar = {
        "graph": "graph0",
        "gridAlpha": 0,
        "color": "#888888",
        "scrollbarHeight": 55,
        "graphFillAlpha": 0,
        "autoGridCount": true,
        "graphLineAlpha": 0.2,
        "graphLineColor": "#c2c2c2",
        "backgroundAlpha": 0,
        "selectedGraphFillAlpha": 0,
        "selectedBackgroundAlpha": 0.1,
        "selectedBackgroundColor": "#888888",
        "selectedGraphLineColor": "#888888",
        "selectedGraphLineAlpha": 1
      }
    },

    // To set Threshold line
    setTrendLines(val) {
      const trendLines = [];
      if (val && this.data.length) {
        const trendLinesObj = {};
        trendLinesObj.finalDate = this.data[this.data.length-1][this.xAxisKey];
        trendLinesObj.finalValue = val;
        trendLinesObj.initialDate = this.data[0][this.xAxisKey];
        trendLinesObj.initialValue = val;
        trendLinesObj.lineColor = "#CC0000";
        trendLinesObj.balloonText ="Threshold:" + this.threshold + "";
        trendLines.push(trendLinesObj);
      }
      return trendLines;
    },

    // Plot graph
    plotChart(collection) {
      const graphs = [];
      for (var i = 0; i < collection.length; i++) {
        graphs.push({
          "id": "graph"+i,
          "bullet": "round",
          "bulletSize": 8,
          "lineThickness": 2,
          "negativeLineColor": "#637bb6",
          "type": "smoothedLine",
          "title": collection[i],
          "valueField": collection[i],
          // "fillColorsField": "lineColor",
          // "lineColorField": "lineColor",
          "lineColor": this.setColors()
        });
        if (this.showBalloonText) {
          graphs[i].balloonText = `[[title]]:<b> ${this.balloonTextValuePrefix}[[value]]${this.balloonTextValuePostfix}</b>`;
        } else {
          graphs[i].balloonText = "";
        }
    }
      return graphs;
    },

    // To set predefined color codes for the line graph
    setColors() {
      if (this.colorIndex > this.colors.length - 1) {
        this.colorIndex = 0;
      }
      return this.colors[this.colorIndex++];
    },

    updateChartData(val) {
      this.chart.dataProvider = val;
      this.chart.validateData();
    },

    beforeDestroy() {
      if (this.chart) {
        this.chart.dispose();
      }
    },
  }
}

export default {
  props: ['lifetime_planned_vs_logged','show_dollar_value','selected_date_range','show_lifetime','chart_export','project_name'],
  name: 'DailyBurnChart',
  mounted() {
    this.plotProjectBurnChart();
    $('.tooltips').tooltip();
  },
  data(){
    return {
      tabPosition                 : 'top',
      activeTabName               :  'daily_burn_chart'
    }
  },
  computed: {
    // Returns the data points(logged, planned, budget, burnt values) per week or month for the time line graph.
    projectBurnReportChartData(){
      var vm      = this;
      var projectBurnPerDate = [];
      if (vm.show_lifetime === true){
        vm.lifetime_planned_vs_logged.forEach(function(row) {
          projectBurnPerDate.push({
            planned : row.planned.toFixed(2),
            logged  : row.logged.toFixed(2),
            date    : new Date(row.date)
          });
        });
      }else{
        vm.lifetime_planned_vs_logged.forEach(function(row) {
          // if( (Date.parse(row.date) >= vm.recurrence_date_range[0]) && (Date.parse(row.date) <= vm.recurrence_date_range[1]) ){
          if( (Date.parse(row.date) >= vm.selected_date_range[0]) && (Date.parse(row.date) <= vm.selected_date_range[1]) ){
            projectBurnPerDate.push({
              planned :  row.planned.toFixed(2),
              logged  :  row.logged.toFixed(2),
              date    :  new Date(row.date)
            });
          }
        });
      }
      return projectBurnPerDate
    },
    trendLineRange(){
      if (this.activeTabName == 'daily_burn_chart' && this.projectBurnReportChartData.length > 0){
        return [this.projectBurnReportChartData[0], this.projectBurnReportChartData[this.projectBurnReportChartData.length-1]]
      }else{
        return [{},{}]
      }
    }
  },
  methods: {
    plotProjectBurnChart(){
      var vm    = this;
      // var data  = ((this.activeTabName == 'daily_burn_chart') ? this.projectBurnReportChartData : this.projectBurnUpChartData)
      var data = this.projectBurnReportChartData;
      // var projectBurnChart = window.AmCharts.makeChart((vm.recurrence+"_burn_down_chart"), {
      var projectBurnChart = window.AmCharts.makeChart((this.activeTabName), {
        "path"              : "amCharts",
        "pathToImages"      : "/amCharts/images/",
        "type"              : "serial",
        "theme"             : "light",
        "marginTop"         : 20,
        "marginRight"       : 40,
        "dataProvider"      : data,
        "valueAxes"         : [{"title": (vm.show_dollar_value == true) ? "$ Value" : "Hours",
                                "axisAlpha": 0.25,
                                "position": "left"
        }],
        "mouseWheelZoomEnabled": true,
        "zoomOutButtonImage": "",
        "zoomOutText"       : "Zoom out",
        "graphs": [{
            "id"            : "planned",
            "title"         : (vm.show_dollar_value == true) ? "Estimated" : "Planned Hours",
            "bullet"        : "round",
            "bulletSize"    : 7.5,
            "lineColor"     : "#40b6c7",
            "lineThickness" : 1.75,
            "type"          : "smoothedLine",
            "hideBulletsCount": 30,
            "valueField"    : "planned",
            "balloonFunction": function(graphDataItem, graph) {
              var data        = graph.chart.dataProvider;
              var index       = graphDataItem.index;
              return vm.formatCurrency(data[index]["planned"])
            }
          },{
            "id"            :"logged",
            "title"         : (vm.show_dollar_value == true) ? "Burned" : "Logged Hours",
            "bullet"        : "round",
            "bulletSize"    : 7.5,
            "lineColor"     : "#ed264b",
            "lineThickness" : 1.75,
            "type"          : "smoothedLine",
            "hideBulletsCount": 30,
            "valueField"    : "logged",
            "balloonFunction": function(graphDataItem, graph) {
              var data        = graph.chart.dataProvider;
              var index       = graphDataItem.index;
              return vm.formatCurrency(data[index]["logged"])
            }
        }],
        "trendLines" : this.trendLineData(),
        "chartCursor": {
          "cursorAlpha"       : 0,
          "valueLineAlpha"    : 0,
          "valueLineEnabled"  : true,
          "valueLineBalloonEnabled"   : true
        },
        "chartScrollbar": {
            "graph"           : "logged",
            "gridAlpha"       : 0,
            "color"           : "#888888",
            "scrollbarHeight" : 55,
            "graphFillAlpha"  : 0,
            "autoGridCount"   : true,
            "graphLineAlpha"  : 0.2,
            "graphLineColor"  : "#c2c2c2",
            "backgroundAlpha" : 0,
            "selectedGraphFillAlpha"  : 0,
            "selectedBackgroundAlpha" : 0.1,
            "selectedBackgroundColor" : "#888888",
            "selectedGraphLineColor"  : "#888888",
            "selectedGraphLineAlpha"  : 1

        },
        "legend": {
          "position"          : "bottom",
          "valueWidth"        : 100,
          "valueAlign"        : "left",
          "equalWidths"       : false,
          "valueFunction"     : function(graphDataItem, text) {
            var formattedValue = text.replace(/[^0-9\.-]+/g,"");
            if (isNaN(parseFloat(formattedValue)))
              return "";
            else
              return vm.formatCurrency(formattedValue);
          }
        },
        "dataDateFormat"      : "MM/DD/YYYY",
        "categoryField"       : "date",
        "categoryAxis"        : {
          "gridAlpha"         : 0.25,
          "parseDates"        : true,
          "minorGridAlpha"    : 0
        },
        "export": {
          "enabled" : vm.chart_export,
          "fileName": vm.project_name
        }
      });
      this.amChartCheckEmptyData(projectBurnChart);
    },
    trendLineData(){
      var vm = this;
      if(this.activeTabName == 'burn_up_chart'){
        return [{
          "finalDate"    : vm.trendLineRange[1]['date'],
          "finalValue"   : vm.trendLineRange[1]['planned'],
          "initialDate"  : vm.trendLineRange[0]['date'],
          "initialValue" : vm.trendLineRange[0]['planned'],
          "lineColor"    : "#666"
        }]
      }else{
        return []
      }
    },
    amChartCheckEmptyData(chart) {
      if (0 == chart.dataProvider.length) {

        chart.valueAxes[0].minimum = 10;
        chart.valueAxes[0].maximum = 100;

        // add dummy data point
        var dataPoint = {
          dummyValue: 0
        };
        dataPoint[chart.categoryField] = '';
        chart.dataProvider = [dataPoint];

        // add label
        chart.addLabel(0, '50%', 'The chart contains no data', 'center');

        // set opacity of the chart div
        chart.chartDiv.style.opacity = 0.5;

        // redraw it
        chart.validateNow();
      }
    },
    formatCurrency(value){
      value = parseFloat(value);
      if (this.show_dollar_value == true)
        return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' })
      else
        return value.toFixed(1)
    }
  },
  watch: {
    lifetime_planned_vs_logged: function(){
      if(this.projectBurnReportChartData.length != 0)
        this.plotProjectBurnChart();
    },
    selected_date_range: function(){
      if(this.projectBurnReportChartData.length != 0)
        this.plotProjectBurnChart();
    },
    show_lifetime: function(){
      if(this.projectBurnReportChartData.length != 0)
        this.plotProjectBurnChart();
    }
  }
}
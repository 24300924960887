import Vue from 'vue/dist/vue.esm'
import CardTransactions from './card-transactions.vue'
import Axios from  'axios-observable';
import VueCurrencyFilter from 'vue-currency-filter';


Vue.use(VueCurrencyFilter, {
  symbol: '$',
  thousandsSeparator: ',',
  fractionCount: 2,
  fractionSeparator: '.',
  symbolPosition: 'front',
  symbolSpacing: false
});

// Axios.defaults.baseURL = 'https://api.example.com';
Axios.defaults.headers.common['Content-Type'] = 'application/json';
Axios.defaults.headers.post['Content-Type'] = 'application/json';

if (document.getElementById("card-transactions")) {
  new Vue({
    el: '#card-transactions',
    render: h => h(CardTransactions)
  })
}

import Axios from "axios-observable";
import { map } from "rxjs/operators";

class PipelineImpactService {
  apiUrlConstants = {
    accrualPipelineReportUri: "api/v1/finance/pipeline_impact",
    syncHubspotDealUri: "api/v1/finance/sync_hubspot_deal_and_cashdata"
  };
  constructor() { }

  getAccrualPipelineReport(selectedYear) {
    let uri = this.apiUrlConstants.accrualPipelineReportUri;
    return Axios.get(uri, { params: { year: selectedYear}}).pipe(map((response) => response.data));
  }

  getSyncHubspotDeal() {
    let uri = this.apiUrlConstants.syncHubspotDealUri;
    return Axios.get(uri).pipe(map((response) => response.data));
  }
}

export default new PipelineImpactService();

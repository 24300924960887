export default {
  name: 'breadcrumb',
  data: function () {
    return {};
  },
  props: {
    data: {
      type: Array,
      required: true
    },
    breadcrumbRootPage: {
      type: String,
      required: true
    }
  },
  mounted() {},
  created() {},
  methods: {},
  watch: {
    data(val) {
      this.data = val;
    },
  }
}

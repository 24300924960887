export default {
  name: 'layered-column-chart',
  data: function () {
    return {
      chart: {},
    };
  },
  props: {
    series: {
      type: Array,
      required: true
    },
    data: {
      type: Array,
      required: true
    },
    xAxisLabel: {
      type: String,
      required: true
    },
    yAxisLabel: {
      type: String,
      required: true
    },
    unit: {
      type: String,
      required: true
    },
    clustered: {
      type: Boolean,
      required:true
    },
    gridThickness: {
      type: Number,
      required: true
    },
    columnWidth: {
      type: String,
      required: true
    },
    categoryField: {
      type: String,
      required: true
    },
    valueAxisPosition: {
      type: String,
      required: true
    },
    rotate: {
      type: Boolean,
      required:true
    },
    categoryAxisPosition:{
      type: String,
      required: true
    },
    chart_style:{
      type: String,
      required: true
    },
    showLegend:{
      type:Boolean,
      required: true
    },
    columnSpacing:{
      type: Boolean,
      required: true
    }
  },
  watch: {
    data: function (val) {
      this.chart.dataProvider = val;
      this.chart.validateData();
    },
    xAxisLabel: function (val) {
      this.chart.categoryAxis.title = val;
    },
    yAxisLabel: function (val) {
      this.chart.valueAxes[0].title = val;
    }
  },
  mounted() {
    this.initChart();
  },
  methods: {
    initChart() {
      this.chart = AmCharts.makeChart(this.$refs.layeredColumnChart, {
        "theme": "none",
        "type": "serial",
        "dataProvider": this.data,
        "pathToImages": "/amCharts/images/",
        "valueAxes": this.setValueAxes(),
        "rotate": this.rotate,
        "legend": (this.showLegend ? this.setChartLegend() : ''),
        "startDuration": 1,
        "graphs": this.setSeries(),
        "plotAreaFillAlphas": 0.1,
        "categoryField": this.categoryField,
        "categoryAxis": this.setCategoryAxis(),
        "chartCursor": this.setChartCursor(),
        "columnSpacing": (this.columnSpacing ? 0 : '')
      });
    },
    setChartCursor() {
      return {
        "categoryBalloonColor": "#000000",
        "cursorAlpha": 0,
        "valueLineAlpha": 0,
        "valueLineEnabled": false,
        "valueLineBalloonEnabled": false,
        "valueBalloonsEnabled": true,
        "fullWidth": false,
        "categoryBalloonEnabled": false,
        "inside": true,
        "valueZoomable": true
      }
    },
    setSeries() {
      let graphs = [];
      for (let i = 0; i < this.series.length; i++) {
        const entity = this.series[i];
        let graph = {
          "id": "graphs" + i,
          "balloonText": "[[category]]<br><span style='font-size:14px'>[[title]]:<b> $[[value]]</b></span>",
          "fillAlphas": 0.8,
          "lineAlpha": 0.3,
          "title": entity.label,
          "type": "column",
          "color": entity.color,
          "fillColors": entity.color,
          "valueField": entity.key,
          "clustered": this.clustered
        }
        if (i != 0 && this.columnWidth === 'unEqual') {
          graph.columnWidth = 0.2 + (i * 0.3);
        }
        if (this.columnWidth === 'equal') {
          graph.columnWidth = 0.6;
        }
        graphs.push(graph);
      }
      return graphs;
    },
    setValueAxes() {
      return [{
        "title": this.yAxisLabel,
        "unit": this.unit,
        "unitPosition": "left",
        "position": this.valueAxisPosition,
        "gridThickness": this.gridThickness
      }];
    },
    setCategoryAxis() {
      return {
        "title": this.xAxisLabel,
        "gridPosition": "start",
        "minHorizontalGap": 20,
        "gridThickness": this.gridThickness,
        "categoryAxisPosition": this.categoryAxisPosition
      }
    },
    setChartLegend() {
      return {
        "horizontalGap": 10,
        "maxColumns": 2,
        "position": "bottom",
        "useGraphSettings": true,
        "align": 'center',
        "markerSize": 12,
        "labelWidth": 60,
        "valueWidth": 80,
        "valueText": "$[[value]]"
      }
    },
  },
  beforeDestroy() {}
}
// Dynamic column generation
// https://github.com/ElemeFE/element/issues/3416
//
// SAMPLE DATA
// {
//   talent_need_vs_allocated: {
//     "03/05/2018" => {:date=>"03/05/2018", :planned=>10.9, :allocated=>10.9},
//     "03/05/2018" => {:date=>"03/12/2018", :planned=>10.9, :allocated=>10.9}
//   }
//   planned_hours_per_project: {
//     "Project1": {
//       "Date1": {planned: 10, allocated: 10, unallocated: 0}
//     },
//     "Project2": {
//       "Date1": {planned: 20, allocated: 10, unallocated: 10}
//     }
//   },
//   talent_available: {
//     "JJ Jose"=>{"03/05/2018"=>12.8, "03/12/2018"=>4.0},
//     "Kristen Gerner"=>{"03/05/2018"=>12.8, "03/12/2018"=>4.0}
//   },
//   recurrence_intervals: ["03/05/2018", "03/12/2018"]
// }

import "./talent-need-analysis-view.css"
import Vue from 'vue/dist/vue.esm'
import vSelect from 'vue-select';
import ElementUI from 'element-ui';

Vue.component('v-select', vSelect)

import AmCharts from 'amcharts3'
import AmSerial from 'amcharts3/amcharts/serial'
import VueResource from 'vue-resource'; // HTTP client for Vue.js

Vue.use(AmCharts);
Vue.use(AmSerial);
Vue.use(VueResource);
Vue.use(ElementUI);

Vue.component('talent-need-analysis-view', {

  mounted() {
    // // Presetting the dates for range selector to one month from now
    // var todaysDate  = new Date().toLocaleDateString("en-US").split("/").join("-");
    // var futureDate  = new Date();
    // futureDate.setTime(futureDate.getTime() + 3600 * 1000 * 24 * 30);
    // futureDate      = futureDate.toLocaleDateString("en-US").split("/").join("-");

    // Presetting the dates to user selected dates
    var startDate = $("#talent-need-analysis-container").data('start-date');
    var endDate   = $("#talent-need-analysis-container").data('end-date');
    this.selected_date_range = [startDate, endDate];

    // First load of talent need chart
    this.loadChart();
  },
  data() {
    return {
      selected_project_role       : $("#talent-need-analysis-container").data('current-role'),
      selected_date_range         : [new Date(), new Date()],
      selected_location           : '',
      recurrence                  : 'weekly',
      hours_category              : 'unallocated',
      talent_hours_category       : 'available',
      project_plan_status         : 'all',
      talent_need_vs_allocated    : {},
      planned_hours_per_project   : {},
      talent_available            : {},
      recurrence_intervals        : [],
      pickerOptions: {
        shortcuts: [{
          text: 'Next week',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            end.setTime(end.getTime() + 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: 'Next month',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            end.setTime(end.getTime() + 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: 'Next 3 months',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            end.setTime(end.getTime() + 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      locationOptions             : $("#location-options").data("options"),
      projectRoleOptions          : $("#project-role-options").data("options"),
      loading: false

      // available_talents_header    : [
      //   {prop: 'talent.name', label: 'Talent Name', width: '250', isLink: true, fixed: true},
      //   {prop: 'date_1.planned', label: 'Date 1', width: '150'},
      //   {prop: 'date_2.planned', label: 'Date 2', width: '150'},
      //   {prop: 'date_3.planned', label: 'Date 3', width: '150'},
      //   {prop: 'date_4.planned', label: 'Date 4', width: '150'},
      //   {prop: 'date_5.planned', label: 'Date 5', width: '150'},
      //   {prop: 'date_6.planned', label: 'Date 6', width: '150'},
      //   {prop: 'date_7.planned', label: 'Date 7', width: '150'},
      //   {prop: 'date_8.planned', label: 'Date 8', width: '150'},
      //   {prop: 'date_9.planned', label: 'Date 9', width: '150'}
      // ],
      // available_talents           : [{
      //   talent: {name: "Syed Zakaullah", forecastLink: "/talent_forecasts?jira_username=syed.zakaullah"},
      //   date_1: {planned: 1, available: 8},
      //   date_2: {planned: 5, available: 8},
      //   date_3: {planned: 3, available: 8},
      //   date_4: {planned: 1, available: 8},
      //   date_5: {planned: 2, available: 8},
      //   date_6: {planned: 5, available: 8},
      //   date_7: {planned: 0, available: 8},
      //   date_8: {planned: 0, available: 8},
      //   date_9: {planned: 0, available: 8}
      // },{
      //   talent: {name: "Chirag KC", forecastLink: "/talent_forecasts?jira_username=chirag"},
      //   date_1: {planned: 0, available: 8},
      //   date_2: {planned: 4, available: 4},
      //   date_3: {planned: 8, available: 0},
      //   date_4: {planned: 0, available: 8},
      //   date_5: {planned: 0, available: 8},
      //   date_6: {planned: 4, available: 4},
      //   date_7: {planned: 0, available: 8},
      //   date_8: {planned: 0, available: 8},
      //   date_9: {planned: 0, available: 8}
      // }],
      // hours_per_project : [
      //   {prop: 'project.name', label: 'Project Name', width: '250', isLink: true, fixed: true},
      //   {prop: 'date_1.planned', label: 'Date 1', width: '150'},
      //   {prop: 'date_2', label: 'Date 2', width: '150'},
      //   {prop: 'date_3', label: 'Date 3', width: '150'},
      //   {prop: 'date_4', label: 'Date 4', width: '150'},
      //   {prop: 'date_5', label: 'Date 5', width: '150'},
      //   {prop: 'date_6', label: 'Date 6', width: '150'},
      //   {prop: 'date_7', label: 'Date 7', width: '150'},
      //   {prop: 'date_8', label: 'Date 8', width: '150'},
      //   {prop: 'date_9', label: 'Date 9', width: '150'}
      // ],
      // tableData: [{
      //   project: {name: "EEWEB Active", link: "http://google.com"},
      //   date_1: {planned: 1.4},
      //   date_2: 1.4,
      //   date_3: 1.4,
      //   date_4: 1.4,
      //   date_5: 1.4,
      //   date_6: 1.4,
      //   date_7: 1.4,
      //   date_8: 1.4,
      //   date_9: 1.4
      // }, {
      //   project: {name: "Holidays & Vacations", link: "#"},
      //   date_1: {planned: 0},
      //   date_2: 8,
      //   date_3: 0,
      //   date_4: 8,
      //   date_5: 8,
      //   date_6: 8,
      //   date_7: 8,
      //   date_8: 8,
      //   date_9: 8
      // }]

    }
  },
  computed: {
    categorized_hours_per_project() {
      var categorizedHours = {};
      for (var projKey in this.planned_hours_per_project) {
        // while showing unallocated hours hide the holidays and vacations.
        if(!(this.hours_category == 'unallocated' && projKey == 'Holidays & Vacations')){
          categorizedHours[projKey] = {};
          for(var key in this.planned_hours_per_project[projKey]) {
            categorizedHours[projKey][key]   = this.planned_hours_per_project[projKey][key][this.hours_category]
            categorizedHours[projKey]["url"] = this.planned_hours_per_project[projKey]["url"]
          }
        }
      };
      return categorizedHours;
    },
    categorized_hours_per_talent() {
      var categorizedHours = {};
      for (var talentName in this.talent_available) {
        categorizedHours[talentName] = {};
        for(var date in this.talent_available[talentName]) {
          categorizedHours[talentName][date] = this.talent_available[talentName][date][this.talent_hours_category]
          categorizedHours[talentName]["url"]  = this.talent_available[talentName]["url"]
        }
      }
      return categorizedHours;
    },capacityPerWeek() {
      var capacity_per_week = {};
      for(var row in this.planned_hours_per_project) {
        for(var date in this.planned_hours_per_project[row]) {
          capacity_per_week[date] = capacity_per_week[date] || {};
          capacity_per_week[date]["unallocated"]  = capacity_per_week[date]["unallocated"] || 0;
          capacity_per_week[date]["unallocated"] += Number(this.planned_hours_per_project[row][date]["unallocated"]);
        }
      }
      for(var row in this.talent_available) {
        for(var date in this.talent_available[row]) {
          capacity_per_week[date] = capacity_per_week[date] || {};
          capacity_per_week[date]["available"]  = capacity_per_week[date]["available"] || 0;
          capacity_per_week[date]["available"] += Number(this.talent_available[row][date]["available"]);
        }
      }
      for(var date in capacity_per_week) {
        var unallocated_hours = capacity_per_week[date]["unallocated"] || 0
        var available_hours   = capacity_per_week[date]["available"] || 0
        var ratio = (available_hours - unallocated_hours)*100/available_hours;
        if(ratio >= +10){
          capacity_per_week[date] = 'success-row'
        }else if(ratio <= -10){
          capacity_per_week[date] = 'danger-row'
        }else{
          capacity_per_week[date] = 'warning-row'
        }
      }
      return capacity_per_week;
    }
  },
  methods: {
    loadChart: function () {
      var vm      = this;
      if(vm.selected_project_role == '' || vm.selected_date_range[0] == '' || vm.selected_date_range[1] == ''){return};
      vm.loading = true;
      this.$http.get('/talent_need_analysis/'+ encodeURIComponent(vm.selected_project_role), {
          params: {
            start_date:       vm.selected_date_range[0],
            end_date:         vm.selected_date_range[1],
            recurrence_type:  vm.recurrence,
            estimate_status:  vm.project_plan_status
          }
        })
        .then(function(response) {
          var chart_data = JSON.parse(response.bodyText);
          window.AmCharts.makeChart((vm.recurrence+"_chart"), {
            "type"                : "serial",
            "dataDateFormat"      : "MM/DD/YYYY",
            "categoryField"       : "date",
            "categoryAxis"        : {
              "gridAlpha"         : 0.25,
              "parseDates"        : true,
              "minorGridAlpha"    : 0
            },
            "chartCursor"       : {},
            "legend": {
              "position": "bottom",
              "valueWidth": 100,
              "valueAlign": "left",
              "equalWidths": false
            },
            "valueAxes": [{"stackType": "regular", "gridAlpha": 0.07, "title": "Hours"}],
            "chartCursor"   : {
              "cursorAlpha" : 0.1,
              "selectWithoutZooming": true
            },
            "graphs" : [
              {
                "type"        : "line",
                "title"       : "Allocated Hours",
                "valueField"  : "allocated",
                "lineColor"   : "#40b6c7",
                "balloonText" : "",
                "showBalloon" : false,
                "fillAlphas"  : 0.8,
              },
              {
                "type"          : "line",
                "title"         : "Unallocated Hours",
                "valueField"    : "unallocated",
                "balloonText"   : "",
                "lineColor"     : "#ed264b",
                "fillAlphas"    : 0.8,
                "balloonFunction": function(graphDataItem, graph) {
                  var chart           = graph.chart;
                  var index           = graphDataItem.index;
                  var data            = chart.dataProvider;
                  var allocated       = data[index]["allocated"];
                  var unallocated     = data[index]["unallocated"];
                  var planned         = allocated + unallocated;
                  return "<table>\
                          <tr><td align='left'>Planned:</td><td></td><td align='right'>"+planned.toFixed(2)+"</td></tr>\
                          <tr><td align='left'>Allocated:</td><td></td><td align='right'>"+allocated.toFixed(2)+"</td></tr>\
                          <tr><td align='left'><b>Unallocated:</b></td><td>&nbsp</td><td align='right'><b>"+unallocated.toFixed(2)+"</b></td></tr>\
                        </table>";
                }
              }
            ],
            "dataProvider"      : chart_data.talent_need_vs_allocated
          }
        );
        vm.loading                    = false;
        vm.planned_hours_per_project  = chart_data.planned_hours_per_project;
        vm.talent_available           = chart_data.talent_available;
        vm.recurrence_intervals       = chart_data.intervals;
      });
    },
    selectRecurrence: function (selected) {
      this.recurrence = selected;
      this.loadChart();
    }
  }

});

new Vue({
  el: '#talent-need-analysis-container'
});
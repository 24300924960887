import Axios from  'axios-observable';
import { BehaviorSubject, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { dummyObservable } from '../../../../utils/utils';
import employeeService from "../../../../services/employee.service.js";

class SnapshotService {

  dataLoading = new BehaviorSubject(false);

  apiUrlConstants = {
    snapshotOptionsUri : '/api/v1/accruals/snapshots/options_for_select',
    projectAccrualSummaryUri: '/api/v1/accruals/project/PROJECT_ACCRUAL_ID/summary',
    accrualSnapshotDetailsUri: '/api/v1/accruals/PROJECT_ACCRUAL_ID/snapshots/details',
    createAccrualUri: '/api/v1/accruals/PROJECT_ACCRUAL_ID/create',
    updateAccrualUri: '/api/v1/accruals/PROJECT_ACCRUAL_ID/update',
    deleteAccrualUri: '/api/v1/accruals/PROJECT_ACCRUAL_ID/delete',
    createAccrualInvoicesUri: '/api/v1/invoices/create',
    updateAccrualInvoiceUri: '/api/v1/invoices/INVOICE_ID/update',
    deleteAccrualInvoiceUri: '/api/v1/invoices/INVOICE_ID/delete',
    createSnapshotNextMonthUri: '/api/v1/accruals/create',
    deleteSnapshotLastMonthUri: '/api/v1/accruals/ACCRUAL_ID/delete'

  }

  snapshotOptionsForSelect;

  constructor() {}

  getCurrentUser() {
    return employeeService.getCurrentUserDetails();
  }

  getProjectAccrualSummary(projectId) {
    let uri  = this.apiUrlConstants.projectAccrualSummaryUri.replace('PROJECT_ACCRUAL_ID', projectId);

    return Axios.get(uri)
    .pipe(map(response => response.data));
  }

  getSnapshotOptionsForSelect(projectId) {
    return Axios.get(this.apiUrlConstants.snapshotOptionsUri, {
      params: {
        project_accrual_id: projectId
      }
    })
    .pipe(map(response => response.data));
  }

  getAccrualSnapshotDetail(projectId, intervalDate, selectedYear) {
    let uri  = this.apiUrlConstants.accrualSnapshotDetailsUri
    .replace('PROJECT_ACCRUAL_ID', projectId);

    return Axios.get(uri, {
      params: {
        interval_date: intervalDate,
        selected_year: selectedYear
      }
    })
    .pipe(map(response => response.data));
  }

  createAccrual(projectId, body) {
    const uri = this.apiUrlConstants.createAccrualUri.replace('PROJECT_ACCRUAL_ID', projectId);

    return Axios.put(uri, body)
    .pipe(map(response => response.data));
  }

  updateAccrual(accrualId, body) {
    const uri = this.apiUrlConstants.updateAccrualUri.replace('PROJECT_ACCRUAL_ID', accrualId);

    return Axios.put(uri, body)
    .pipe(map(response => response.data));
  }

  deleteAccrual(accrualId) {
    const uri = this.apiUrlConstants.deleteAccrualUri.replace('PROJECT_ACCRUAL_ID', accrualId);

    return Axios.put(uri, body)
    .pipe(map(response => response.data));
  }

  createAccrualInvoices(invoice) {
    const uri = this.apiUrlConstants.createAccrualInvoicesUri
    const body = {
      accrual_id: invoice.accrualId,
      value: invoice.value,
      invoice_date: invoice.invoice_date,
      invoice_no: invoice.invoice_no
    }

    return Axios.post(uri, body)
      .pipe(map(response => response.data));
  }

  updateAccrualInvoice(invoiceId, invoice) {
    const uri = this.apiUrlConstants.updateAccrualInvoiceUri.replace('INVOICE_ID', invoiceId);
    const body = {
      id: invoice.id,
      invoice_no: invoice.invoice_no,
      value: invoice.value,
      invoice_date: invoice.invoice_date
    }

    return Axios.put(uri, body)
      .pipe(map(response => response.data));
  }

  deleteAccrualInvoice(invoiceId) {
    // if (confirm("Are you sure you want to delete?")) { 
      const uri = this.apiUrlConstants.deleteAccrualInvoiceUri.replace('INVOICE_ID', invoiceId);

      return Axios.delete(uri)
        .pipe(map(response => response.data));
    // }
    
  }

  createSnapshotNextMonth(newAccrualObj) {
    const uri = this.apiUrlConstants.createSnapshotNextMonthUri
    const body = {
      active: newAccrualObj.active,
      hours: newAccrualObj.hours,
      interval_start: newAccrualObj.interval_start,
      interval_type: newAccrualObj.interval_type,
      invoices: newAccrualObj.invoices,
      project_accrual_id: newAccrualObj.project_accrual_id,
      revenue: newAccrualObj.revenue,
      snapshot_id: newAccrualObj.snapshot_id,
    }
    return Axios.post(uri, body)
      .pipe(map(response => response.data));
  }

  deleteSnapshotLastMonth(lastAccrualId) {
      const uri = this.apiUrlConstants.deleteSnapshotLastMonthUri.replace('ACCRUAL_ID', lastAccrualId);
      return Axios.delete(uri)
        .pipe(map(response => response.data));
    
  }
}

const snapshotService = new SnapshotService();

export { snapshotService, SnapshotService }
import Axios from 'axios-observable';
import { map } from 'rxjs/operators';

class EmployeeSkillsetsService {

    apiUrlConstants = {
        addEmployeeSkillUri: "/api/v1/skills/EMPLOYEE_ID/create",
        addEmployeeCapabilityUri: "/api/v1/employees/EMPLOYEE_ID/createCapability",
        employeeSkillListUri: "/api/v1/employees/EMPLOYEE_ID/skills",
        deleteEmployeeSkillUri: "/api/v1/skills/SKILL_ID/delete",
        getCurrentUser: "/employees/fetch_current_employee.json",
        getAllSkillsUri: "/api/v1/skills",
        getAllCapabilitiesUrl: "/api/v1/employees/allCapabilities",
        employeeCapabilityListUri: "/api/v1/employees/EMPLOYEE_ID/employeeCapabilities",
        updateEmployeeSkillUri: "/api/v1/skills/SKILL_ID/update",
        deleteEmployeeCapabilityUri: "/api/v1/employees/SKILL_ID/deleteCapability",

    }

    constructor() {
        // if (process.env.NODE_ENV === 'development') {
        //     Axios.defaults.baseURL = 'http://localhost:3000/';
        // }
        // if (process.env.NODE_ENV === 'staging') {
        //     Axios.defaults.baseURL = 'https://bitool.softwaystaging.com/';
        // }
        // if (process.env.NODE_ENV === 'production') {
        //     Axios.defaults.baseURL = 'https://bitool.softwaystaging.com/';
        // }
     }

    addEmployeeSkill(employeeId, skillNames, skillNote) {
        const uri = this.apiUrlConstants.addEmployeeSkillUri.replace('EMPLOYEE_ID', employeeId);
        const body = {
            skill_names: skillNames,
            skill_note: skillNote
        }
        // console.log("environment = ", process.env.NODE_ENV);
        return Axios.post(uri, body)
            .pipe(map(response => response.body));
    }

    deleteEmployeeSkill(employeeId, skillId) {
        const uri = this.apiUrlConstants.deleteEmployeeSkillUri.replace('SKILL_ID', skillId);
        const body = {
            employee_id: employeeId
        }
        return Axios.delete(uri, { data: body})
            .pipe(map(response => response.body));
    }

    getAllSkills(employeeId) {
        let uri = this.apiUrlConstants.getAllSkillsUri;
        return Axios.get(uri, {
          params: {
            employee_id: employeeId
          }
        }).pipe(map(response => response.data.body));
    }

    getCurrentUser() {
        return Axios.get(this.apiUrlConstants.getCurrentUser)
            .pipe(map(res => res.data));
    }

    getEmployeeSkills(employeeId) {
        let uri = this.apiUrlConstants.employeeSkillListUri.replace('EMPLOYEE_ID', employeeId);
        return Axios.get(uri)
            .pipe(map(response => response.data.body));
    }

    updateEmployeeSkill(employeeId, skillId, skillNote) {
        const uri = this.apiUrlConstants.updateEmployeeSkillUri.replace('SKILL_ID', skillId);
        const body = {
            employee_id: employeeId,
            skill_note: skillNote
        }
        return Axios.put(uri, { data: body})
            .pipe(map(response => response.body));
    }

    getAllCapabilities(employeeId){
        let uri = this.apiUrlConstants.getAllCapabilitiesUrl;
        return Axios.get(uri, {
          params: {
            employee_id: employeeId
          }
        }).pipe(map(response => response.data.body));
    }

    getEmployeeCapabilities(employeeId) {
        let uri = this.apiUrlConstants.employeeCapabilityListUri.replace('EMPLOYEE_ID', employeeId);
        return Axios.get(uri)
            .pipe(map(response => response.data.body));
    }

    addEmployeeCapability(employeeId, role) {
        const uri = this.apiUrlConstants.addEmployeeCapabilityUri.replace('EMPLOYEE_ID', employeeId);
        const body = {
            projectRoleId: role,
        }
        // console.log("environment = ", process.env.NODE_ENV);
        return Axios.post(uri, body)
            .pipe(map(response => response.body));
    }
    
    deleteEmployeeCapability(employeeId, skillId) {
        const uri = this.apiUrlConstants.deleteEmployeeCapabilityUri.replace('SKILL_ID', skillId);
        const body = {
            employee_id: employeeId
        }
        return Axios.delete(uri, { data: body})
            .pipe(map(response => response.body));
    }

}

export default new EmployeeSkillsetsService();
import Axios from 'axios-observable';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

class ReportService {

    apiUrlConstants = {
        checkAuthorizationUri: '/authorization/api_autherization/check_permissions',
        jiraProjectCodeUri: '/api/v1/project_plans/fetch_jira_project_codes',
        projectBurnSummaryUri : '/api/v1/project_burn_report_summary',
        projectPlanApi: '/api/v1/project_plans/options_for_select'
    }

    constructor() {}

    checkAuthorization() {
        let obj = {
          page: "estimo, estimate",
          function: "create?"
        }
        return Axios.get(this.apiUrlConstants.checkAuthorizationUri,{
            params: obj
        }).pipe(
            map(response => {
                return response.data.permit;
            })
        );
    }

    getJiraProjectCodes() {
        return Axios.get(this.apiUrlConstants.jiraProjectCodeUri).pipe(
            map(response => {
                return response.data.body;
            })
        );
    }

    getProjectBurnReportSummary(selected_date_range, show_dollar_value, estimate_status) {
        let obj = {
            start_date: selected_date_range[0],
            end_date: selected_date_range[1],
            type: (show_dollar_value === true) ? "dollar" : "hour",
            estimate_status: estimate_status
        };
        return Axios.get(this.apiUrlConstants.projectBurnSummaryUri, {
            params: obj
        }).pipe(
            map(response => {
                return response.data;
            })
        );
    }

    getProjectPlans() {
        let obj = {
            type: "wip,closed"
        };
        return Axios.get(this.apiUrlConstants.projectPlanApi,
            { params: obj}).pipe(
            map(response => {
                return response.data.body;
            })
        );
    }
}

const reportService = new ReportService();

export { reportService, ReportService }

import "./talent-need-summary-view.css"
import Vue from 'vue/dist/vue.esm'
import vSelect from 'vue-select'
import VueResource from 'vue-resource'; // HTTP client for Vue.js
import ElementUI from 'element-ui';
import lang from 'element-ui/lib/locale/lang/en' // L10N
import locale from 'element-ui/lib/locale'
import DataTables from 'vue-data-tables';

locale.use(lang)

Vue.component('v-select', vSelect)
Vue.use(VueResource);
Vue.use(ElementUI);
Vue.use(DataTables);

Vue.component('talent-need-summary-view', {

  mounted() {
    // Presetting the dates for range selector to one month from now
    var todaysDate  = new Date().toLocaleDateString("en-US").split("/").join("-");
    var futureDate  = new Date();
    futureDate.setTime(futureDate.getTime() + 3600 * 1000 * 24 * 30);
    futureDate      = futureDate.toLocaleDateString("en-US").split("/").join("-");
    this.selected_date_range = [todaysDate, futureDate];
    // First load of data
    this.getSummaryData();
  },
  data() {
    return {
      selected_date_range         : [new Date(), new Date()],
      pickerOptions: {
        shortcuts: [{
          text: 'Next week',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            end.setTime(end.getTime() + 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: 'Next month',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            end.setTime(end.getTime() + 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: 'Next 3 months',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            end.setTime(end.getTime() + 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      loading: false,
      needAnalysisSummaryData: [],
      searchDef: {
        show: false
      },
      paginationDef: {
        pageSize: 20,
        pageSizes: [20, 50, 100],
        currentPage: 2,
        totalItems: 0 
      },
      tableProps: {
        border: true,
        defaultSort: {
          prop: 'unallocated',
          order: 'descending'
        },
        headerRowClassName: function (row, column, rowIndex, columnIndex) {
          return "need-analysis-summary-header-row"
        },
        // rowClassName: function(object) {
        //   var status = object.row.status;
        //   if (status === 'red') {
        //     return 'danger-row';
        //   } else if (status === 'green') {
        //     return 'success-row';
        //   }else if (status === 'yellow') {
        //     return 'warning-row';
        //   }
        //   return '';
        // },
        // cellStyle: function (row, column, rowIndex, columnIndex) {
        //   return {color: "red"}
        // },
        size: "medium"
      },
      customFilters: [{
          value: '',
          prop: 'role'
        }
      ]
    }
  },
  methods: {
    handleSortChange({ column, prop, order }) {
      var vm      = this;
      const sortedData = [...vm.needAnalysisSummaryData].sort((a, b) => {
        if (order === 'ascending') {
          return a[prop] - b[prop];
        } else {
          return b[prop] - a[prop];
        }
      });

      
      vm.needAnalysisSummaryData = sortedData;
      vm.refresh();
  
    },
    refresh() {
      this.$nextTick(() => {
        let dataTable = this.$children.find(a => a.$vnode.tag.indexOf('DataTables') >= 0);
        let elTable = dataTable.$children.find(a => a.$vnode.tag.indexOf('ElTable') >= 0);

        if (elTable) {
          let bodyAndHeaders = elTable.$children.filter(a => a.$vnode.tag.indexOf('ElTableBody') >= 0 || a.$vnode.tag.indexOf('ElTableHeader') >= 0)
          bodyAndHeaders.map(bodyOrHeader => {
            Object.keys(bodyOrHeader._computedWatchers).forEach(k => bodyOrHeader._computedWatchers[k].run())
            this.$nextTick(() => bodyOrHeader.$forceUpdate())
          })
          let elPagination = dataTable.$children.find(a => a.$vnode.tag.indexOf('ElPagination') >= 0);

          if(elPagination) {
            elPagination.$children.map(item => {
              item._computedWatchers && Object.keys(item._computedWatchers).forEach(k => item._computedWatchers[k].run())
             
              this.$nextTick(() => item.$forceUpdate());
            })
          }
          dataTable.$on('update:currentPage', () => this.refresh());
        }
      })
    },
    getSummaryData: function () {
      var vm      = this;
      if(vm.selected_date_range == null){return};
      vm.loading = true
      $.ajax({
        type: 'get',
        url : '/talent_need_analysis',
        data: {
          start_date:       vm.selected_date_range[0],
          end_date:         vm.selected_date_range[1]
        },
        dataType: 'json',
        async   : true,
      }).done(function(data){
        if (data){
          vm.needAnalysisSummaryData = data;
          vm.paginationDef.totalItems = data.length;
        }else{
          vm.needAnalysisSummaryData = [];
        }
        vm.loading = false;
        vm.refresh();
      });
    },
    resetCurrentPage(){
      this.$refs.dataTable.currentPage = 1;
      this.refresh();
    },details_page_url(role){
      var start_date = this.selected_date_range[0];
      var end_date   = this.selected_date_range[1];
      return "/talent_need_analysis/" + encodeURIComponent(role) + "?start_date="+ start_date +"&end_date="+ end_date;
    }, roleCapacityTag(row) {
      var status = row.status;
      if (status === 'high') {
        return ['danger', 'high'];
      } else if (status === 'low') {
        return ['success', 'low'];
      }else if (status === 'medium') {
        return ['warning', 'medium'];
      }
      return ['info', 'NA'];
    },
      filterTag(value, row) {
        return row.status === value;
      },
      handleSizeChange(size) {
        this.paginationDef.pageSize = size;
        this.paginationDef.currentPage = 1;
        this.getSummaryData();
      },
      handleCurrentChange(page) {
        this.paginationDef.currentPage = page;
        this.getSummaryData();
      },
      handlePaginationChange({page, size}) {
        this.paginationDef.currentPage = page;
        this.paginationDef.pageSize = size;
        this.getSummaryData();
      }
  }
});

new Vue({
  el: '#talent-need'
});
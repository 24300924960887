<!-- @TODO MOVE THE CSS AND JS TO ANOTHER FILE -->
<template>
  <div ref="gantt" style="width: 100%; height:100%;"></div>
</template>

<script>
import 'dhtmlx-gantt'
import 'dhtmlx-gantt/codebase/ext/dhtmlxgantt_tooltip'
import 'dhtmlx-gantt/codebase/ext/dhtmlxgantt_smart_rendering'
export default {
  name: 'project-gantt',
  props: {
    tasks: {
      type: Object,
      default () {
        return {data: [], links: []}
      }
    },
    selected_filter: '',
    selected_project: ''
  },
  data() {
    return {
      holidays: {}
    }
  },
  watch: {
    tasks: function(newVal, oldVal) { // watch it
      gantt.render();
    }
  },
  methods: {
    getHolidays: function (){
      var vm     = this;
      this.$http.get('/api/v1/project_plans/'+vm.selected_project+'/holidays', {})
      .then(function(response) {
        var response_body = response.body.body;
        this.holidays     = JSON.parse(response_body);
        var holidays      = Object.keys(this.holidays);
        for (var i = 0; i < holidays.length; i++) {
          gantt.setWorkTime({
            date: new Date( holidays[i].split("-") ),
            hours: false
          });
        }
      });
    },
    applyfilter: function (){
    var vm=this;
    switch (vm.selected_filter){
        case "Phase":
          var phaseTask= gantt.getTaskBy("type",'Phase');
          phaseTask.forEach(function(ele){
            ele.$open=false;
          });
          break;
        case "Module":
          var phaseTask=gantt.getTaskBy("type","Phase");
          var modTask=[];
          phaseTask.forEach(function(ele){
            ele.$open=true;
            modTask=gantt.getChildren(ele.id);
            modTask.forEach(function(childEle){
              gantt.getTask(childEle).$open=false;
            });
          });
          break;
        case "Task":
          gantt.eachTask(function(task){
            task.$open = true;
          });
          break;
          case "Bug":
          gantt.eachTask(function(task){
            task.$open = true;
          });
          break;
      }
      gantt.render();
    },
    scrollToDate: function (date){
      var date_x = gantt.posFromDate(date);
      var scroll_to = Math.max(date_x - gantt.config.task_scroll_offset, 0);
      gantt.scrollTo(scroll_to);
    }
  },
  mounted: function () {
    var obj={};
    var vm = this;
    this.getHolidays();

    function taskName(task){
      return task.text
    };
    function taskID(task){
      return task.task_id
    };
    function startDate(task){
      return (new Date(task.start_date).toLocaleDateString('en-US'));
    };
    function endDate(task){
      var end_date = subtractDays(task.end_date, 1);
      return (new Date(end_date).toLocaleDateString('en-US'));
    };
    // @TODO COMMENT
    function subtractDays(date, days) {
      var result = new Date(date);
      result.setDate(result.getDate() - days);
      return result;
    };
    var dateToStr                 = gantt.date.date_to_str("%d %M");
    var weekScaleTemplate         = function (date) {
                                      var endDate = gantt.date.add(gantt.date.add(date, 1, "week"), -1, "day");
                                      return dateToStr(date) + " - " + dateToStr(endDate);
                                    };
    gantt.config.work_time        = true;
    gantt.config.duration_unit    = "day";
    gantt.config.date_scale       = "Week %W (%M %j,%Y)"
    gantt.config.scale_unit       = "week";
    gantt.config.scale_height     = 60;
    gantt.config.row_height       = 25;
    gantt.config.readonly         = true;
    gantt.config.show_task_cells  = true;
    gantt.config.date_grid        = "%d/%m/%Y";
    gantt.config.show_progress    = false;
    gantt.config.drag_resize      = false;
    gantt.config.show_links       = false;
    gantt.config.smart_rendering  = true;
    gantt.config.grid_width       = 500;
    gantt.config.grid_resize      = true;
    gantt.config.min_column_width = 35;
    gantt.config.work_time        = true;
    gantt.config.skip_off_time    = true;
    gantt.config.open_tree_initially = true;
    gantt.config.show_errors      = false;
    gantt.config.columns          = [
                                      {name:"task_id", label:"Task ID", tree:true, template: taskID, width: "*", max_width: 70},
                                      {name:"text", label:"Task Description",width: "*", min_width: 200, template: taskName},
                                      {name:"start_date", label:"Start Date", align:"center", template: startDate , width: "*", max_width: 80},
                                      {name:"end_date", label:"End Date", align:"center", template: endDate , width: "*", max_width: 110}
                                    ]
    gantt.config.subscales        = [
                                      {unit: "day",  step:1, date:"%d" },
                                    ];
    var holidays                  = Object.keys(this.holidays);
    // for (var i = 0; i < holidays.length; i++) {
    //   gantt.setWorkTime({
    //     date: new Date( holidays[i].split("-") ),
    //     hours: false
    //   });
    // }

    gantt.templates.scale_row_class = function(date){             // Using 'dhtmlx gantt templates' to style the timeline row
      return "timeline_row";
    };
    var vm = this; //@TODO MOVE THE VM
    gantt.templates.task_cell_class = function (task, date) {     // style the weekend and holiday timeline cells
      if (!gantt.isWorkTime(date)) {
        if(date.getDay()==0||date.getDay()==6){
          return "timeline_cell week_end" ;
        }
        else {
          var dateString = new Date(date.getTime() - (date.getTimezoneOffset() * 60000 )).toISOString().split("T")[0];
          switch (vm.holidays[dateString]){
            case "IN":
              return "timeline_cell in_holiday";
              break;
            case "US":
              return "timeline_cell us_holiday";
              break;
            case "ALL":
              return "timeline_cell all_holiday";
              break;
          }
        }
      }
      else {
        return "timeline_cell";
      }
    };
    gantt.templates.grid_header_class = function(columnName, column){  // style the left section's column headers
      return "column_header";
    };

    gantt.templates.grid_folder = function(item) {                // removing the default folder icon for parent elements
      if(item.$open === true)
        return "<i class='la la-minus-square'> </i>";
      else
        return "<i class='la la-plus-square'> </i>";
    };
    gantt.templates.grid_file = function(item) {                  // removing the default folder icon for childe elemnts (tasks)
      return "";
    };
    gantt.templates.tooltip_text = function(start,end,task){      // configure tool-tip text
      return task.text;
      // return "";
    };
    gantt.templates.task_class  = function(start, end, task){     // styling timeline rows/right-section
      switch (task.type){
        case "Phase":
          return "phase";
          break;
        case "Modyule":
          return "module";
          break;
        case "Task":
          return "task";
          break;
          case "Bug":
          return "task";
          break;
      }
    };
    gantt.templates.grid_row_class = function(start, end, task){  // styling grid rows/left-section
      switch (task.type){
        case "Phase":
          return "phase";
          break;
        case "Modyule":
          return "module";
          break;
        case "Task":
          return "task";
          break;
        case "Bug":
          return "task";
          break;
      }
    };

    gantt.templates.task_text=function(start, end, task){         // removing text in the task bars
        return "";
    };
    gantt.templates.grid_open=function(item){         // removing text in the task bars
        return "";
    };
    gantt.templates.grid_open=function(item){         // removing text in the task bars
        return "";
    };

    gantt.init(this.$refs.gantt);
    gantt.parse(this.$props.tasks);

    gantt.attachEvent("onTaskDblClick", function(id, e) {
      if(id != null){
        var targetTask = gantt.getTask(id);
        if(targetTask.type==='Phase' || targetTask.type==='Modyule'){
          if(targetTask.$open===true){
            gantt.close(id);
          }
          else{
            gantt.open(id);
          }
        }
      }
    });

    gantt.attachEvent("onGanttRender", function(){
      vm.$emit('after:projectGanttRendered');
    });

    gantt.attachEvent("onGanttScroll", function (left, top){
      gantt.ext.tooltips.tooltip.hide();
    });

  }
}
</script>

<style>
    @import "dhtmlx-gantt/codebase/dhtmlxgantt.css";
    /*to set background color for selected row */
    .gantt_task_row.gantt_selected .gantt_task_cell, .gantt_grid .gantt_selected{
      background-color: #cee74a !important;
    }
    /*to set background color for selected row text*/
    .gantt_grid .gantt_selected .gantt_tree_content {
      color: black !important;
    }
    .gantt_task_cell.week_end {
      background-color: #ececec;
    }
    .gantt_task_row.gantt_selected .gantt_task_cell.week_end {
      background-color: #ececec;
    }
    .gantt_task_cell.in_holiday {
      background-color: #939393;
      color: white !important;
      padding: 4px 0px 0px 4px;
    }
    .gantt_task_cell.in_holiday::after {
      content: "IN";
      position: absolute;
    }
    .gantt_task_row.gantt_selected .gantt_task_cell.in_holiday {
      background-color: #939393;
      color: white !important;
    }
    .gantt_task_cell.us_holiday {
      background-color: #939393;
      color: white !important;
      padding: 4px 0px 0px 4px;
    }
    .gantt_task_cell.us_holiday::after {
      content: "US";
      position: absolute;
    }
    .gantt_task_row.gantt_selected .gantt_task_cell.us_holiday {
      background-color: #939393;
      color: white !important;
    }
    .gantt_task_cell.all_holiday {
      background-color: #939393;
      color: white !important;
      padding: 4px 0px 0px 4px;
    }
    .gantt_task_cell.all_holiday::after {
      content: "ALL";
      position: absolute;
    }
    .gantt_task_row.gantt_selected .gantt_task_cell.all_holiday {
      background-color: #939393;
      color: white !important;
    }
    .column_header {
      font-weight: bold;
      color: black !important;
    }
    .timeline_cell {
      width: 35px;
      height: 25px;
      border-bottom: 1px solid #CCC;
      border-right: 1px solid #CCC;
    }
    /*styling for phases*/
    .phase {
      background-color: #060553 !important;
      border: 0px;
      cursor: pointer;
    }
    /*styling for modules*/
    .module {
      background-color: #e66c73 !important;
      border: 0px;
      cursor: pointer;
    }
    /*to add padding to the first cell in module*/
    .module .gantt_cell:first-child {
      padding-left: 10px;
    }
    /*styling for tasks*/
    .task {
      background-color: #c4c5d6 !important;
      border: 0px;
      cursor: pointer;
    }
    /*to add padding to the first cell in task*/
    .task .gantt_cell:first-child {
      padding-left: 20px;
    }
    .timeline_row {
      height: 30px;
    }
    /*not using the plugins indentation*/
    .gantt_tree_indent, .gantt_tree_icon.gantt_blank {
      display: none;
    }
    /*to set text color for phase and module to white*/
    .phase .gantt_tree_content, .module .gantt_tree_content {
      color: white !important;
      font-weight: bold;
    }
    /*to set the color for the icons*/
    .la-plus-square, .la-minus-square {
      color: white;
      font-size: 12px;
    }
    /*to change the color of the icons if present in the selected row*/
    .gantt_selected .la-plus-square, .gantt_selected .la-minus-square {
      color: black;
    }
</style>
import { snapshotService } from "../../services/snapshop-service";
import { formatDateObject } from "../../../../../utils/utils";
import { strToDate, formatDateObj } from "../../utils/utils";
import EventBus from "../../global-event-bus";

export default {
  name: "invoice-edit-dialog",
  components: {},
  filters: {
    strToDate: strToDate
  },
  props: {
    open: {
      type: Boolean,
      required: true,
      default: false
    },
    currentInvoices: {
      type: Array,
      required: true,
      default: []
    },
    accrualId: {
      type: Number,
      required: true,
      default: 0
    }
  },
  data() {
    return {
      snapshotService: snapshotService,
      invoiceTableKey: 0,
      open_: false
    };
  },
  watch: {
    currentInvoices(value) {
      this.invoiceTableKey++;
      this.refresh();
    },
    open(value) {
      this.open_ = value;
      this.refresh();
    }
  },
  created() {},
  mounted() {
    this.refresh();
  },
  computed: {},
  methods: {
    deleteInvoice(invoice) {
      const index = this.currentInvoices.indexOf(invoice);
      const invoiceId = invoice.id ? invoice.id : 0;
      if (!confirm("Are you sure you want to delete?")) { 
        return;
      }
      if (index !== -1) {
        this.currentInvoices.splice(index, 1);
        this.invoiceTableKey++;
      }
      if (invoiceId) {
        this.snapshotService.deleteAccrualInvoice(invoiceId).subscribe();
        this.refresh();
      }
      this.$emit("invoiceUpdated", this.currentInvoices);
    },
    refresh() {
      this.$nextTick(() => {
        let elTable = this.$children[0].$children.find(a => a.$vnode.tag.indexOf('ElTable') >= 0);
        if (elTable) {
          let bodyAndHeaders = elTable.$children.filter(a => a.$vnode.tag.indexOf('ElTableBody') >= 0 || a.$vnode.tag.indexOf('ElTableHeader') >= 0)
          bodyAndHeaders.map(bodyOrHeader => {
            Object.keys(bodyOrHeader._computedWatchers).forEach(k => bodyOrHeader._computedWatchers[k].run())
            this.$nextTick(() => bodyOrHeader.$forceUpdate())
          })
        }
      })
    },
    addInvoice(prevInvoice = null) {
      const newInvoiceObj = {
        id: null,
        invoice_date: formatDateObject(new Date(), "/"),
        invoice_no: '',
        value: 0,
        accrualId: this.accrualId
      };
      let self = this;
      if (!prevInvoice) {
        this.currentInvoices.push(newInvoiceObj);
        this.invoiceTableKey++;
        let invoiceId = null;
        this.snapshotService
          .createAccrualInvoices(newInvoiceObj)
          .subscribe(response => {
            invoiceId = response.json.invoice_id;
            if (invoiceId) {
              this.currentInvoices[
                this.currentInvoices.length - 1
              ].id = invoiceId;
              this.$set(this, 'currentInvoices', this.currentInvoices);
              this.$emit("invoiceUpdated", this.currentInvoices);
            }
            self.refresh();
          });
        return;
      }
      const index = this.currentInvoices.indexOf(prevInvoice);
      if (index !== -1) {
        this.currentInvoices.splice(index + 1, 0, newInvoiceObj);
        this.invoiceTableKey++;
      }
    },
    checkValidation(invoice){
      if(invoice.invoice_date == null || invoice.invoice_no == ''){
        bootstrapNotify('All fields are mandatory to fill.', 'error');
      }else{
        this.updateInvoice(invoice.id);
        bootstrapNotify('Invoice has been updated successfully.', 'success');
        this.emitClose();
      }
    },
    updateInvoice(invoiceId, event) {
      let invoice;
      event && this.checkForMaxValue(event.target.value, event);
      // this.checkForMaxValue(event.target.value, event);
      this.currentInvoices.forEach(element => {
        // if (element.value.includes("$") || event.target.value.includes("$")) {
        //   element.value = element.value.replace("$", "");
        // }
        element.value = element.value.replace(/[$,]/gi, '');
        if (!parseFloat(element.value)) {
            element.value = element.value || 0;
          if (event && !parseFloat(event.target.value)) {
            event.target.value = event.target.value || 0;
          }
        }
        if (element.length > 10) {
          element.value = element.value.slice(0, 10);
        }
        if (element.id === invoiceId) {
          invoice = element;
          invoice.invoice_date = moment(element.invoice_date).format('MM/DD/YYYY');
        }
      });
      this.snapshotService.updateAccrualInvoice(invoiceId, invoice).subscribe();
      this.$emit("invoiceUpdated", this.currentInvoices);
    },
    setCharAt(str, index, chr) {
      if (index > str.length - 1) return str;
      return str.substr(0, index) + chr + str.substr(index + 1);
    },
    checkForMaxValue(value, event) {
      if (!parseFloat(event.target.value)) {
        event.target.value = "0";
      }
      let fullNum = event.target.value.toString().split(".")[0];
      let isValidNum = /^[0-9]{0,10}$/.test(parseFloat(fullNum));
      if (!isValidNum && event.target.value.includes(".")) {
        event.target.value = this.setCharAt(
          event.target.value,
          event.target.value.indexOf(".") - 1,
          ""
        );
      } else if (!isValidNum && !event.target.value.includes(".")) {
        event.target.value = event.target.value.slice(0, 10);
      }
    },
    emitClose() {
      this.open_ = false;
      this.$emit("update:open", this.open_);
    }
  }
};

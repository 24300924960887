export default {
    name: 'metronic-hamburger-menu',
    components: {

    },
    data () {
        return {

        }
    },
    props: {

    },
    watch: {

    },
    computed: {

    },
    methods: {
        
    }
}
/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// import "components/page/page";
// import "components/auth-form/auth-form";
// import "components/chat/chat";

import Vue from 'vue/dist/vue.esm'

// //-- Load & Init Element UI --
// import ElementUI from 'element-ui';
// import 'element-ui/lib/theme-chalk/index.css'; // theme
import lang from 'element-ui/lib/locale/lang/en' // L10N
import locale from 'element-ui/lib/locale'
locale.use(lang)

// Vue.use(ElementUI, { size: 'medium', locale });
// // Vue.use(ElementUI, { size: 'medium'});

// // import { Loading } from 'element-ui';
// // Vue.use(Loading.directive);
// //-- Load & Init Element UI ends --

import "init";
import "components/global_filters"

import "components/table-wide/table-wide";
import "components/talent-need-summary-view/talent-need-summary-view";
import "components/talent-need-analysis-view/talent-need-analysis-view";
//import "components/burn-report-view/burn-report-view";
import "project-plan/burn-report/burn-report";
import "project-plan/talent-forecast";
import "components/talent-forecast-widget/talent-forecast-widget";
import "project-plan/gantt-chart/gantt-chart"
import "project-plan/project-plan"

import "talent-utilization"
import "card-transactions"
import "finance/pipeline-impact"
import "finance/accruals"
import "employee"
import "talent-skill-analysis"

import "amcharts3/amcharts/plugins/export/export.js"
import "xlsx/dist/xlsx.full.min.js"
import "amcharts3/amcharts/plugins/export/export.css"
import AmCharts from 'amcharts3'
import AmSerial from 'amcharts3/amcharts/serial'
import AmGauge from 'amcharts3/amcharts/gauge';
import DataTables from 'vue-data-tables'
// // Global JS

Vue.use(DataTables)

// // Go through all the items on that page that needs vue binding.
// $('.vue-app').each((i, el) => new Vue({el}))

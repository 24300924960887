import Axios from "axios-observable";
import { map } from "rxjs/operators";

class TalentSkillAnalysisService {
    apiUrlConstants = {
        getAllSkillsUri: "api/v1/skills/options_for_select",
        getAllTalentsBySkillUri: "api/v1/skills/export_talent_detailed_report",
        getProjectDetails: "api/v1/employees/ID/project_details",
        getEmployeeListBySkillUri: "api/v1/skills/talent_detailed_report"
    };
    constructor() { }

    getAllSkills() {
        let uri = this.apiUrlConstants.getAllSkillsUri;
        return Axios.get(uri).pipe(map(response => response.data.body));
    }

    getAvailableEmployeesBySkill(recurrence, startDate, endDate, selectedSkills, selectedPool, currentPage, pageSize) {
        let uri     = this.apiUrlConstants.getEmployeeListBySkillUri;
        let param   = {};
        // if (selectedSkills.length > 0) {
            // param = { skill_ids: selectedSkills}
        // }
        // if (selectedPool) {
            // param = { pool_id: selectedPool }
        // }
        param.skill_ids = selectedSkills;
        param.pool_id = selectedPool;
        param.page_num          = currentPage;
        param.page_size         = pageSize;
        param.start_date        = startDate;
        param.end_date          = endDate;
        param.recurrence_type   = recurrence;

        return Axios.get(uri, { params: param})
        .pipe(
                map(response => response.data.body)
            );
    }

    getAllTalentsBySkill(recurrence, startDate, endDate, selectedSkills, selectedPool) {
        let uri = this.apiUrlConstants.getAllTalentsBySkillUri;
        let param = {};
        if (selectedSkills.length > 0) {
            param = { skill_ids: selectedSkills }
        }
        if (selectedPool) {
            param = { pool_id: selectedPool }
        }
        param.start_date = startDate;
        param.end_date = endDate;
        param.recurrence_type = recurrence;

        return Axios.get(uri, { params: param })
            .pipe(
                map(response => response.data.body)
            );
    }

    getProjectDetails(employeeId) {
        let uri = this.apiUrlConstants.getProjectDetails.replace('ID', employeeId);

        return Axios.get(uri, { params: { id: employeeId} })
            .pipe(
                map(response => response.data.body)
            );
    }

}

export default new TalentSkillAnalysisService();
import ElMonthPicker from "../../../../../components/el-month-picker/el-month-picker";
import snapshotClosureService from "../../snapshot-closure-service";
import { formatSnapshotName } from "../../../project-accrual-report/utils/utils";
import { formatDateObject } from "../../../../../utils/utils";

export default {
  name: 'snapshot-week-closure',
  components: {
    ElMonthPicker,
  },
  filters: {
    formatSnapshotName: formatSnapshotName
  },
  props: {
    open: {
      type: Boolean,
      required: true,
      default: false
    },
  },
  data() {
    return {
      snapshotClosureService: snapshotClosureService,
      open_: false,
      selectedMonth: new Date(),
      snapshots: [],
      snapshotsWeekTableKey: 0,
    }
  },
  watch: {
    open(value) {
      this.open_ = value;
      this.refresh();
    },
  },
  created() {
    this.getSnapshotClosureWeeks().subscribe((weeks) => {
      this.snapshots = weeks;
    });
  },
  mounted() {
    this.init();
  },
  computed: {},
  methods: {
    init() {},
    refresh() {
      this.$nextTick(() => {
        let elTable = this.$children[0].$children.find(a => a.$vnode.tag.indexOf('ElTable') >= 0);
        if (elTable) {
          let bodyAndHeaders = elTable.$children.filter(a => a.$vnode.tag.indexOf('ElTableBody') >= 0 || a.$vnode.tag.indexOf('ElTableHeader') >= 0)
          bodyAndHeaders.map(bodyOrHeader => {
            Object.keys(bodyOrHeader._computedWatchers).forEach(k => bodyOrHeader._computedWatchers[k].run())
            this.$nextTick(() => bodyOrHeader.$forceUpdate())
          })
        }
      }) 
    },
    updateMonthhandler() {
      let self = this;
      this.getSnapshotClosureWeeks()
      .subscribe(weeks => {
        this.snapshots = weeks;
        self.refresh();
      });
    },
    getSnapshotClosureWeeks() {
      const date = formatDateObject(this.selectedMonth, '/');
      return this.snapshotClosureService.getSnapshotClosureWeeks(date);
    },
    updateSnapshotStatus(id, status) {
      this.snapshotClosureService.updateSnapshotStatus(id, status).subscribe();
    },
    emitClose(event) {
      this.open_ = false;
      this.$emit('update:open', this.open_);
    },
  }
}

import employeeSkillsetsService from "../services/employee-skillsets-service";
export default {
  name: "skill-set-dialog",
  props: {
    open: {
      type: Boolean,
      required: true,
      default: false
    },
    formType: '',
    skillSet: null,
    skillsetList: null,
  },
  data() {
    var isUniqueSkillName = (rule, value, callback) => {
      setTimeout(() => {
        if (value.search(/[a-zA-Z]/) == -1) {
          return callback(new Error('Role name must contain alteast one alphabets'))
        }
        callback();
      }, 200);
    };
    return {
      employeeSkillsetsService: employeeSkillsetsService,
      dialogFormVisible: false,
      currentUser: null,
      counter: 0,
      skillDeleteVisible: false,
      skillId: '',
      userId: null,
      userSkillSets: [],
      allowCreateSkill: false,
      skillSetFormData: {
        title: [],  
      },
      skillsetFormRules: {
        title: [{
          required: true,
          message: 'Please select capability name',
          trigger: 'blur'
        },
        {
            validator: isUniqueSkillName,
            trigger: 'blur'
        }
      ]
      }
    };
  },
  created() {
    this.userId = window.location.pathname.split('/')[2];
    this.employeeSkillsetsService.getCurrentUser()
      .subscribe(response => {
        this.currentUser = response;
        if(this.currentUser.permissions["SkillPolicy"] === "modify"){
          this.allowCreateSkill = true;
        }
      });
  },
  watch: {
    open(value) {
      this.dialogFormVisible = value;
      if (this.formType === "add") {
        this.skillSetFormData.title = "";
      }
      if (this.formType === "edit") {
        this.skillSetFormData.title = this.skillSet.title
      }
    }
  },
  computed: {
    pageTitle() {
      return this.formType ? this.formType === 'add' ? "Add new role" : "Edit role" : '';
    },
    elSelectPlaceholder(){
      if (this.allowCreateSkill){
        return "Search for roles"
      }else{
        return "Search for roles"
      }
    },
    elInputPlaceholder(){
      return "Please enter some details about this capability you acquired. For eg. Name of the project where you used it last, or any certifications etc"
    }
  },
  mounted() {
  },
  methods: {
    addEmployeeCapability() {
      this.$refs['skillSetForm'].validate((valid) => {
        if (valid) {
          if(this.formType === 'add'){
            this.employeeSkillsetsService
            .addEmployeeCapability(this.userId, this.skillSetFormData.title)
            .subscribe(
              response => {
                this.$refs['skillSetForm'].resetFields();
                this.dialogFormVisible = false;
                bootstrapNotify("New capability has been added successfully.", "success");
              },
              error => {
                bootstrapNotify("Error in saving capabilities.\n Please retry", "error");
              }
            );
          }
          if(this.formType === 'edit'){
            this.employeeSkillsetsService
            .updateEmployeeSkill(this.userId, this.skillSet.id, this.skillSetFormData.skillNote)
            .subscribe(
              response => {
                this.$refs['skillSetForm'].resetFields();
                this.dialogFormVisible = false;
                bootstrapNotify("Capability has been updated successfully.", "success");
              },
              error => {
                bootstrapNotify("Error in saving capabilities.\n Please retry", "error");
              }
            );
          }  
        } else {
          return false;
        }
      });
        
    },
    resetSkillSetForm() {
      this.$refs['skillSetForm'].resetFields();
      this.dialogFormVisible = false;
      this.$emit('update:open', this.dialogFormVisible);
    }
  }
}
import Vue from 'vue/dist/vue.esm'
import Router from 'vue-router'
import GanttCharts from './gantt-chart-view/GanttChartView'

Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/',
      name: 'GanttCharts',
      component: GanttCharts,
      props: true
    }
  ]
})
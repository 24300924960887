import Vue from 'vue/dist/vue.esm'
import TalentUtilization from './talent-utlization.vue'
import Axios from  'axios-observable';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

Vue.use(ElementUI);

// Axios.defaults.baseURL = 'https://api.example.com';
Axios.defaults.headers.common['Content-Type'] = 'application/json';
Axios.defaults.headers.post['Content-Type'] = 'application/json';


if (document.getElementById("talent-utilization")) {
  new Vue({
    el: '#talent-utilization',
    render: h => h(TalentUtilization)
  })
}

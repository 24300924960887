import { exportXLSXFromTable, formatTime } from '../../utils/utils';
import { throttle } from 'throttle-debounce';

export default {
  name: 'tasks-panel',
  props: ['startDate', 'endDate', 'project_id'],
  mounted(){
    this.loadTaskData();
    // reload tasks panel when resource summary is updated to fetch the latest information
    this.$root.$on('updateDashboard', () => {
      this.loadTaskData();
    });
  },
  data() {
    return {
    	tasks_data   : [],
      loading      : true,
      taskHeaders  : [
        { field: 'uid', title: 'Task ID' },
        { field: 'name', title: 'Task Name' },
        { field: 'start_date', title: 'Start Date' },
        { field: 'end_date', title: 'End Date' },
        { field: 'talents', title: 'Talent(s)' },
        { field: 'hours', title: 'Hours' }
      ],
      // tableToolbar: null,
      tableTabs    : '',
      tableToolbar: {
        perfect: true,
        zoom: {
          iconIn: 'fa fa-arrows-alt',
          iconOut: 'fa fa-expand'
        }
      },

    }
  },
  computed: {
    project_gantt_url(){
      return "/estimates/" + this.project_id + "/gantt_charts?type=project";
    },
    resource_gantt_url(){
      return "/estimates/" + this.project_id + "/gantt_charts?type=resource";
    },
    date_range(){
      return [this.startDate, this.endDate];
    }
  },
  watch: {
    date_range(){
      this.loadTaskData();
    },
  },
  created() {
  this.throttleExportTableData = throttle(500, true, () => {
    var table = this.$refs.taskTable.$el.querySelector('.el-table__body-wrapper').parentElement;
    const time = formatTime(new Date());
    const file_name = this.tableTabs.replace(/ /g, '_') + "(" + time + ")"
    exportXLSXFromTable(table, file_name)
  });
  },
  methods: {
    exportTaskData() {
      const time = formatTime(new Date());
      const file_name = this.tableTabs.replace(/ /g, '_') + "(" + time + ")"
      this.$refs.taskTable.exportData(
        {
          type: 'xlsx',
          filename: file_name,
          sheetName: file_name,
          message: false,
          isHeader: true
        }
      )
    },
    loadTaskData(){
      this.loading = true;
      let vm = this;
      this.$http.get('/api/v1/project_plans/' + this.project_id + '/tasks',{
        params: {
          start_date: this.startDate.toLocaleDateString('en-US'),
          end_date: this.endDate.toLocaleDateString('en-US')
        }
      }).then(function(response){
        this.tasks_data = response.body.body;
        this.loading = false;
      });
    },
    fullscreen() {
      // this.tableToolbar = {
      //   zoom: true,
      //   slots: {
      //     buttons: 'toolbar_buttons'
      //   }
      // };
      this.$refs.taskTable.zoom();
    }
  }
}
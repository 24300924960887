import * as AmGauge from "amcharts3/amcharts/gauge.js";
import Vue from 'vue/dist/vue.esm';
Vue.use(AmGauge);
/**
 * Purpose: Gauge chart component(Re-usable)
 * Usage: <gauge-chart :config="config" :data="data"/>
 * Input:
    config: {
      category: {
        key: 'employee',
        labelColor: 'ff0000'
      },
      bands: [{
        key: 'billable'
        label: 'Billable',
        color: '#ff0000'
      },{
        key: 'non_billable'
        label: 'Non-Billable',
        color: '#ff00ff'
      },{
        key: 'available'
        label: 'Available',
        color: '#ffff00'
      }]
    }
    data: [{
      "employee": "Gagan H N",
      "billable": 46.55,
      "non_billable": 30.45,
      "available": 24
    }]
 */

export default {
  name: 'gauge-chart',
  data: function () {
    return {
      chart: {},
      valueAxis: {},
      categoryAxis: {},
      bandsDefault: [
        {
          "radius": "90%",
          "innerRadius": "65%"
        },
        {
          "radius": "60%",
          "innerRadius": "35%"
        }
      ],
      axesDefaults: {
        "axisAlpha": 0,
        "tickAlpha": 0,
        "labelsEnabled": false,
        "startValue": 0,
        "endValue": 100,
        "startAngle": 0,
        "endAngle": 270,
      },
      allLabelsDefaults: [
        {
          "x": "49%",
          "y": "13%",
          "size": 12,
          "bold": true,
          "align": "right"
        },
        {
          "x": "49%",
          "y": "25%",
          "size": 12,
          "bold": true,
          "align": "right"
        }
      ]
    }
  },
  props: {
    config: {
      type: Object,
      required: true
    },
    data: {
      type: Array,
      required: true
    }
  },
  watch: {
    data: function (val) {
      // this.chart.data = val;
      // this.chart.addData(val, this.chart.data.length);
      // this.chart.invalidateRawData();
      this.initializeChart();
    }
  },
  mounted() {
    this.initializeChart();
  },
  methods: {
    initializeChart() {
      this.chart = AmCharts.makeChart(this.$refs.chartdiv, {
        "type": "gauge",
        "addClassNames": true,
        "theme": "light",
        "axes": this.setAxes(),
        "legend": {
          "data": this.setLegends(),
          "maxColumns": 1,
          "position": "right",
        },
        "allLabels": this.setAllLabels()
      });
    },
    setAxes() {
      let bands = [];
      for (let i = 0; i < this.data.length; i++) {
        let end = 0;
        const category = this.data[i];
        bands.push({
          "color": "#eeeeee",
          "startValue": 0,
          "endValue": 100,
          ...this.bandsDefault[i]
        });
        for(let j = 0 ; j < this.config.bands.length; j++) {
          const bandCur = this.config.bands[j];
          let startValue = (j == 0) ? 0 : end;
          let endValue =  startValue + (category[bandCur.key] === null ? 0 : category[bandCur.key]);
          end = endValue;

          bands.push({
            "color": bandCur.color,
            "startValue": startValue,
            "endValue": endValue,
            "balloonText": `${category[this.config.category.key]}<br><span style='font-size:14px'><b>${category[bandCur.key]}% </b>${bandCur.label}</span>`,
            ...this.bandsDefault[i]
          });
        }
      }
// "[[category]]<br><span style='font-size:14px'>[[title]]:<b>[[value]]</b></span>",
      return [{
        ...this.axesDefaults,
        "bands": bands
      }];
    },
    setLegends() {
      let legends = [];
      for(let j = 0 ; j < this.config.bands.length ; j++) {
        const band = this.config.bands[j];
        legends.push({
          title: band.label,
          color: band.color
        });
      }
      return legends;
    },
    setAllLabels() {
      let labels = [];
      for(let j = 0 ; j < this.data.length; j++) {
        const category = this.data[j];
        labels.push({
          "text": category[this.config.category.key],
          "color": this.config.category.color,
          ...this.allLabelsDefaults[j]
        })
      }

      return labels;
    }
  },
  beforeDestroy() {
    // if (this.chart) {
    //   this.chart.dispose();
    // }
  }
}
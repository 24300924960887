import Axios from 'axios-observable';
import { BehaviorSubject, of, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

class SnapshotClosureService {

  apiUrlConstants = {
    snapshotWeeksUri: 'api/v1/accruals/snapshot/monthly',
    updateWeekStatusUri: 'api/v1/snapshot/SNAPSHOT_ID/update',
    snapshotMonthsUri: 'api/v1/accrual_interval/options_for_select',
    updateMonthStatusUri: 'api/v1/accrual_interval/ID/update',
  }

  constructor() {}

  getSnapshotClosureWeeks(selectedMonth) {
    return Axios.get(this.apiUrlConstants.snapshotWeeksUri, {
      params: {
        selected_month: selectedMonth
      }
    })
    .pipe(map(response => response.data));
  }

  updateSnapshotStatus(id, status) {
    const body = {
      active: status
    }
    const uri = this.apiUrlConstants.updateWeekStatusUri.replace('SNAPSHOT_ID', id);
    return Axios.put(uri, body)
    .pipe(map(response => response.data));
  }

  getSnapshotClosureMonths(selectedYear) {
    return Axios.get(this.apiUrlConstants.snapshotMonthsUri, {
      params: {
        selected_year: selectedYear
      }
    })
    .pipe(map(response => response.data));
  }

  updateSnapshotMonthStatus(id, status) {
    const body = {
      active: status
    }
    const uri = this.apiUrlConstants.updateMonthStatusUri.replace('ID', id);
    return Axios.put(uri, body)
    .pipe(map(response => response.data));
  }

  errorHandler() {
    return of(`Bad Promise: ${error}`)
  }

}

export default new SnapshotClosureService();
import EventBus from '../../finance/accruals/project-accrual-report/global-event-bus'
const pageConfig = {
    currentPage: 1,
    itemsPerPage: null,
    pageNos: [],
    maxLength: 7,
    totalNoOfProjects: null,
    name: null,
}
export default {
    name: 'metronic-pagination',
    data() {
        return pageConfig;
    },
    props: {
        paginationConfig: {
            type: Object,
        },
    },
    watch: {
        paginationConfig: {
            handler(newVal, oldValue) {
                if (newVal) {
                    this.itemsPerPage = newVal.itemsPerPage;
                    this.maxLength = newVal.maxLength;
                    this.totalNoOfProjects = newVal.totalNoOfProjects;
                    this.name = newVal.name;
                    this.initPagination();
                }
            },
            deep: true,
        }
    },
    computed: {
        getVisibleRecordPosition() {
            let start = ((this.currentPage - 1) * this.itemsPerPage)+1;
            let end = (this.currentPage * this.itemsPerPage) < this.totalNoOfProjects ? (this.currentPage * this.itemsPerPage) : (this.currentPage * this.itemsPerPage) - ((this.currentPage * this.itemsPerPage) - this.totalNoOfProjects)
            return {
                start,
                end
            }
        },
    },
    methods: {
        goToFirstPage() {
            this.currentPage = 1;
            this.initPagination();
        },
        goTolastPage() {
            let totalNoOfPages = Math.ceil(this.totalNoOfProjects / this.itemsPerPage)
            this.moveToPage(totalNoOfPages)
            this.getDataForPage();
        },
        getDataForPage() {
            EventBus.$emit("fetchAccrualsForPage", this.currentPage);
        },
        initPagination() {
            let defaultPages = Math.ceil(this.totalNoOfProjects / this.itemsPerPage);
            this.pageNos = [];
            for (var i = 1; i <= defaultPages; i++) {
                if (i < 6) {
                    this.pageNos.push(i);
                } else {
                    break;
                }
            }
            this.currentPage = 1;
            this.getDataForPage();
        },
        setPageConfig() {
            let pageConf = {
                "currentPage": this.currentPage,
                "itemsPerPage": this.itemsPerPage,
                "pageNos": this.pageNos,
                "maxLength": this.maxLength,
                "totalNoOfProjects": this.totalNoOfProjects,
                "name": this.name
            }
            pageConfig.setPageConfig(pageConf)
        },
        moveToPage(pageNum) {
            pageNum = +pageNum
            if (this.currentPage < pageNum) {
                let diff = pageNum - this.currentPage;
                for (let i = 1; i <= diff; i++) {
                    if (this.pageNos.length < this.maxLength) {
                        if ((this.pageNos[this.pageNos.length - 1] * this.itemsPerPage) < this.totalNoOfProjects) {
                            this.pageNos.push(this.pageNos[this.pageNos.length - 1] + 1)
                        }
                    } else {
                        if (Math.ceil(this.totalNoOfProjects / this.itemsPerPage) > this.pageNos[this.pageNos.length - 1]) {
                            this.pageNos.push(this.pageNos[this.pageNos.length - 1] + 1)
                            this.pageNos.shift();
                        }
                    }
                }
            } else if (this.currentPage > pageNum) {
                let diff = this.currentPage - pageNum;
                for (let i = 1; i <= diff; i++) {
                    if (this.pageNos.length > 5 && this.pageNos[0] !== 1) {
                        this.pageNos.pop();
                        this.pageNos.unshift(this.pageNos[0] - 1)
                    }
                }
            }
            this.currentPage = pageNum;
            this.getDataForPage();

        },

        nextPage() {
            if ((this.pageNos[this.pageNos.length - 1] * this.itemsPerPage) < this.totalNoOfProjects) {
                if (this.pageNos.length < this.maxLength) {
                    this.pageNos.push((this.pageNos[this.pageNos.length - 1]) + 1)
                } else {
                    this.pageNos.push((this.pageNos[this.pageNos.length - 1]) + 1)
                    this.pageNos.shift();
                }
            }
            this.currentPage++;
            this.getDataForPage();
        },
        prevPage() {
            if (this.pageNos[0] !== 1) {
                if (this.pageNos.length >= this.maxLength) {
                    this.pageNos.pop();
                    this.pageNos.unshift(this.pageNos[0] - 1)
                } else {
                    this.pageNos.unshift(this.pageNos[0] - 1)
                }
            }
            this.currentPage--;
            this.getDataForPage();
        },

    }
}
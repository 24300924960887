export default {
  name: 'element-ui-table',
  data: function () {
    return {
      maxDefaultHeight: 400,
      fixedHeightSpan: 7
    };
  },
  props: {
    tableRows: {
      type: Array,
      required: true
    },
    stripe: {
      type: Boolean,
      required:true
    },
    border: {
      type: Boolean,
      required: true
    },
    headers: {
      type: Array,
      required: true
    },
    height: {
      default: null
    }
  },

  mounted() {
    this.init();
  },
  watch: {
    tableRows(rows) {
      this.refresh();
    }
  },
  methods: {
    refresh() {
      let elTable = this.$children[0];
      this.$nextTick(() => {
        let bodyAndHeaders = elTable.$children.filter(a => a.$vnode.tag.indexOf('ElTableBody') >= 0 || a.$vnode.tag.indexOf('ElTableHeader') >= 0)

        bodyAndHeaders.map(bodyOrHeader => {
          Object.keys(bodyOrHeader._computedWatchers).forEach(k => bodyOrHeader._computedWatchers[k].run())
          this.$nextTick(() => bodyOrHeader.$forceUpdate())
        })
      })
    },
    init() {
      
    },
    sendSelectedYearToParent() {
      this.$emit('selectedYear', "this.selectedYear");
    },
    filterTag(value, row) {
      // console.log("value",value);
      // console.log("row",row);
      if(value && row)
      return row.type === value;
    }
  },
  computed: {
    computedTableHeight: function () {
      if (this.height) return this.height;
      if (this.tableRows.length > this.fixedHeightSpan)
        return this.maxDefaultHeight; // enable scrolling after few rows if no height given.
      else
        return null;
    }
  }
}
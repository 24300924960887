import AccrualsSummaryWidget from './accruals-summary/accruals-summary.vue'

export default {
  name: 'accruals-report-panel',
  props: ['project_id'],
  components: {AccrualsSummaryWidget},
  mounted(){
    this.loadAccrualsData();
    // reload project accruals to fetch the latest information
    this.$root.$on('updateDashboard', () => {
      this.loadAccrualsData();
    });

    new Clipboard('[data-clipboard=true]').on('success', function(e) {
      e.clearSelection();
      bootstrapNotify('Copied', 'success');
    });
  },
  data() {
    return {
    	accruals_data      : [],
      selected_interval  : 'monthly',
      total_hours        : '',
      total_accrual      : '',
      loading            : true,
      options            : [{
                              label: 'By Days',
                              value: 'daily'
                              },
                            {
                              label: 'By Weeks',
                              value: 'weekly'
                            },
                            {
                              label: 'By Months',
                              value: 'monthly'
                            }]
    }
  },
  computed: {
    selected_dropdown() {
      if (this.selected_interval == 'daily')
        return 'By Days';
      else if (this.selected_interval == 'weekly')
        return 'By Weeks'
      else
        return 'By Months'
    },
    accrual_recurrence() {
      if (this.selected_interval == 'daily')
        return 'Days';
      else if (this.selected_interval == 'weekly')
        return 'Week Of'
      else
        return 'Month Of'
    }
  },
  methods: {
    loadAccrualsData() {
      this.loading = true;
      let vm = this;
      this.$http.get('/api/v1/project_plans/' + this.project_id + '/accruals',{
        params: {
          interval: this.selected_interval
        }
      }).then(function(response){
        this.accruals_data = response.body.body.accrual_records;
        this.total_hours = response.body.body.total_hours;
        this.total_accrual = response.body.body.total_accrual;
        this.loading = false;
      });
    },
    roundOffHours({row}) {
      return row.hours.toFixed(2);
    },
    roundOffAccruals({row}) {
      return (parseFloat(row.accrual).toLocaleString('en-US', { style: 'currency', currency: 'USD' }));
    },
    valueBasedFontClass(value){
      if (value == "Unassigned")
        return "is-zero";
      else
        return "break-word";
    },
    changeInterval(interval) {
      this.selected_interval = interval;
      this.loadAccrualsData();
    }
  }
}
import Axios from  'axios-observable';
import { map } from 'rxjs/operators';

class EmployeeService {

  apiUrlConstants = {
    currentUserApiUrl : '/employees/fetch_current_employee.json',
  }

  constructor() {}

  getCurrentUserDetails() {
    return Axios.get(this.apiUrlConstants.currentUserApiUrl)
    .pipe(
      map(response =>  response.data)
      );
  }

}

export default new EmployeeService();
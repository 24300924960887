export default {
  name: 'el-month-picker',
  data: function () {
    return {
      // month: JSON.parse(JSON.stringify(this.selectedMonth))
      month: JSON.parse(JSON.stringify(this.selectedMonth))
    };
  },
  props: {
    monthFormat: {
      type: String,
      required: true
    },
    selectedMonth: {
      required: true
    },
    clearable: {
      type: Boolean,
      required: false,
      default: true
    },
  },

  mounted() {
    this.init();
  },
  methods: {
    init() {

    },

    sendSelectedMonthToParent() {
      this.$emit('update:selectedMonth', this.month);
      this.$emit('updatedMonth', this.month);
    }
  },
  watch: {

  }
}
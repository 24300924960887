import {
  snapshotService
} from "../../services/snapshop-service";
import EventBus from "../../global-event-bus";
import { formatDateObj } from '../../utils/utils';
export default {
  name: 'project-summary-panel',
  props: [],
  filters: {
    formatDateObj: formatDateObj
  },
  data() {
    return {
      snapshotService: snapshotService,
      projectSummary: {
        "project_name": '',
        "client_name": '',
        "jira_code": '',
        // "total_revenue": '',
        "account_manager": [],
        "contract_type": '',
        // "completion_percent": '',
        "project_manager": [],
        // "contract_term": '',
        // "total_invoice": '',
        "budgeted_revenue": '',
        "contract_range": '',
        "accrual_status": '',
      }
    }
  },
  created() {
    this.init();
    EventBus.$on("updateProjectSummary", this.init.bind(this));
  },
  mounted() {},
  methods: {
    init() {
      const projectId = this.$route.params && this.$route.params ? this.$route.params.id : 0;
      this.snapshotService.getProjectAccrualSummary(projectId)
        .subscribe(
          projectSummary => {
            EventBus.$emit('sendSummaryPanel', projectSummary);
            this.projectSummary = projectSummary;
          }
        )
    }
  }
}
import { reportService } from "../report.service";
export default {
  name: "ProjectBurnSummary",
  mounted() {
    // Presetting the dates for range selector for past one month
    var todaysDate = new Date().toLocaleDateString("en-US").split("/").join("-");
    var pastDate = new Date();
    pastDate.setTime(pastDate.getTime() - 3600 * 1000 * 24 * 30);
    pastDate = pastDate.toLocaleDateString("en-US").split("/").join("-");

    this.selected_date_range = [pastDate, todaysDate];

    this.getJiraProjectKeys();
    this.getBurnReportSummary();
    this.check_autherization();
  },
  data() {
    var vm = this;
    return {
      reportService: reportService,
      modify_permission:    false,
      selected_date_range:  [new Date(), new Date()],
      data:                 [], // Rename this to summary data or burn date. 
      estimate_status:      "wip",
      loading:              false,
      dialogFormVisible:    false,
      selected_row:         {},
      projectKeyList:       [],
      updated_jira_key:     null,
      syncButtonHtml:       `<span>get updated list </span`,
      submitButtonLoading:  false,
      statusOptions:        [{label: "WIP", value: "wip"}, {label: "Closed", value: "closed" }],
      pickerOptions: {
        shortcuts: [{
          text: 'Last week',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(end.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: 'Last month',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(end.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: 'Last 3 months',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(end.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      searchDef: {
        show: false
      },
      paginationDef: {
        pageSize: 20,
        pageSizes: [20, 50, 100],
        currentPage: 1
      },
      tableProps: {
        defaultSort: {
          prop: 'difference',
          order: 'ascending'
        },
        headerRowClassName: function (row, column, rowIndex, columnIndex) {
          return "project-table-header-row"
        },
        // cellStyle: function (row, column, rowIndex, columnIndex) {
        //   return {color: "red"}
        // },
        size: "medium"
      },
      rules: {
        jira_key: [
          { required: true, message: 'Please add jira key', trigger: 'blur' }
        ]
      },
      customFilters: [{
        value: '',
        prop: 'project_name'
      }, {
        value: [],
        prop: 'contract_type'
      }],
      show_dollar_value: false
    }
  },
  computed: {
    burnReportData() {
      var summaryBurnReport = [];
      this.data.forEach(function (project) {
        summaryBurnReport.push({
          id: project.id,
          jira_key: project.jira_key,
          project_name: project.project,
          contract_type: project.contract_type,
          budget: project.budget,
          difference: project.planned - project.logged,
          planned: project.planned,
          logged: project.logged,
          total_difference: project.total_planned - project.total_logged,
          total_planned: project.total_planned,
          total_logged: project.total_logged,
          inaccuracy: (project.logged === 0) ? "N/A" : (project.logged - project.planned) * 100 / project.logged
        })
      });
      return summaryBurnReport;
    }
  },
  methods: {
    refresh() {
      let datatable = this.$children.find(a => a.$vnode.tag.indexOf('DataTables') >= 0);
      if(datatable) {
        let elTable = datatable.$children.find(a => a.$vnode.tag.indexOf('ElTable') >= 0)

        Object.keys(elTable._computedWatchers).forEach(k => elTable._computedWatchers[k].run())
        this.$nextTick(() => elTable.$forceUpdate())

        let bodyAndHeaders = elTable.$children.filter(a => a.$vnode.tag.indexOf('ElTableBody') >= 0 || a.$vnode.tag.indexOf('ElTableHeader') >= 0)
        console.log(bodyAndHeaders);

        this.$nextTick(() => {
          bodyAndHeaders.map(bodyOrHeader => {
            Object.keys(bodyOrHeader._computedWatchers).forEach(k => bodyOrHeader._computedWatchers[k].run())
            this.$nextTick(() => bodyOrHeader.$forceUpdate())
          })
        })
        let elPagination = datatable.$children.find(a => a.$vnode.tag.indexOf('ElPagination') >= 0);
        if (elPagination) {
          elPagination.$children.map(item => {
            item._computedWatchers && Object.keys(item._computedWatchers).forEach(k => item._computedWatchers[k].run())

            this.$nextTick(() => item.$forceUpdate());
          })
        }
        datatable.$on('update:currentPage', () => this.refresh());
      }
      // $vm0.$children.find(a => a.$vnode.tag.indexOf('DataTables') >= 0)
    },
    getBurnReportSummary: function() {
      let self = this;
        if (this.selected_date_range !== null){
          this.loading = true;
          this.reportService.getProjectBurnReportSummary(this.selected_date_range, this.show_dollar_value, this.estimate_status)
          .subscribe( (response) => {
            this.data = response.body;
            this.loading = false;

            self.refresh();
          })
      }
    },
    // getBurnReportSummary: function () {
    //   if (this.selected_date_range !== null){
    //     // var vm     = this;
    //     // vm.loading = true;
    //     this.loading = true;
    //     this.$http.get('/api/v1/project_burn_report_summary', {
    //       params: {
    //         start_date      : this.selected_date_range[0],
    //         end_date        : this.selected_date_range[1],
    //         type            : (this.show_dollar_value == true) ? "dollar" : "hour",
    //         estimate_status : this.estimate_status
    //       }
    //     })
    //     .then((response) => {
    //       //this.tableData = response.body.body;
    //       // vm.data = response.body.body;
    //       // vm.loading = false;
    //       console.log('response=>',response);
    //       this.data = response ? response.body.body : [];
    //       this.loading = false;
    //     })
    //     .catch( (error) => {
    //       console.log('error=>', error);
    //     });
    //   }
    // },
    customButtonsForRow(row) {
      if (row.jira_key === null || row.jira_key === "") {
        return [{
          name: 'Link Jira project',
          handler: _ => {
            this.dialogFormVisible = true;
            this.selected_row = row;
          },
          type: 'danger',
          size: 'mini',
          disabled: (this.modify_permission === true ? false : true)
        }]
      } else {
        return [{
          name: 'View Report',
          handler: _ => {
            this.$router.push({
              name: "ProjectBurnReport",
              params: { project_id: row.id },
              query: {
                start_date: this.selected_date_range[0],
                end_date: this.selected_date_range[1],
                type: (this.show_dollar_value == true) ? "dollar" : "hour"
              }
            })
          },
          type: 'primary',
          size: 'mini',
          disabled: false
        }]
      }
    },
    getJiraProjectKeys(){
      this.reportService.getJiraProjectCodes().subscribe( response => {
        this.projectKeyList = response;
        this.refresh();
      })
    },
    linkJiraProjectKey() {
      var vm = this;
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          vm.submitButtonLoading = true;
          this.$http.put('/api/v1/project_plans/' + vm.selected_row.id + '/update', {
            jira_project_key: vm.selected_row.jira_key
          })
            .then(function (response) {
              vm.updated_jira_key = vm.selected_row.jira_key;
              vm.dialogFormVisible = false;
              vm.submitButtonLoading = false;
              vm.$message({ message: 'Jira code has been successfully linked to ' + vm.selected_row.project_name, type: 'success' });
            }, response => {
              vm.dialogFormVisible = false;
              vm.$message.error(response.bodyText);
              vm.submitButtonLoading = false;
              vm.refresh();
            });
        } else {
          return false;
        }
      });
    },
    onCloseModal() {
      this.selected_row.jira_key = this.updated_jira_key;
      this.updated_jira_key = null;
    },
    syncJiraProjectCodes() {
      var vm = this;
      vm.syncButtonHtml = `<i class="fa fa-refresh fa-spin"></i><span> Fetching projects</span>`;
      vm.$http.get('/estimates/fetch_jira_projects')
        .then(function (response) {
          vm.projectKeyList = response.body.results;
          vm.syncButtonHtml = `<span>get updated list </span>`;
        }, response => {
          vm.syncButtonHtml = `<span>get updated list </span>`;
          vm.$message.error(response.bodyText);
        });
    },
    conditionalFormatNumber(value) {
      if (isNaN(value)) {
        return value
      } else {
        return value.toFixed(2);
      }
    },
    sortInaccuracyColumn(a, b) {
      var first = isNaN(a.inaccuracy) ? 0 : a.inaccuracy
      var second = isNaN(b.inaccuracy) ? 0 : b.inaccuracy
      return first > second ? 1 : first < second ? -1 : 0
    },
    valueBasedFontClass(value, colorize = false) {
      var classes = [];
      if (typeof (value) == "number") {
        var parsedValue = parseFloat(value);
        switch (true) {
          case (parsedValue == 0 || isNaN(parsedValue)):
            classes.push("is-zero");
            break;
          case (parsedValue < 0 && colorize == true):
            classes.push("is-negative");
            break;
          case (parsedValue > 0 && colorize == true):
            classes.push("is-positive");
            break;
          default:
            classes.push("");
            break;
        }
      }
      else {
        if (value == 'N/A' || value == 'Not Available')
          classes.push('is-zero')
      }
      return classes;
    },
    resetCurrentPage() {
      this.$refs.dataTable.currentPage = 1;
      this.refresh();
    },
    toggleDollarValue() {
      this.getBurnReportSummary();
    },
    formatCurrency(value) {
      if (this.show_dollar_value == true)
        return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' })
      else
        return value.toFixed(1)
    },
    toggleProjectStatus(status) {
      this.estimate_status = status;
      this.getBurnReportSummary();
    },
    statusToggleClass(status) {
      return (this.estimate_status == status) ? "active" : ''
    },
    check_autherization(){
      this.reportService.checkAuthorization().subscribe(response => {
        this.modify_permission = response;
      })
    }
  }
}

import TalentSkillAnalysisService from './talent-skill-analysis-service';
import { formatDateObject, exportXLSXFromJSON } from '../utils/utils';

export default {
    name: 'talent-skill-analysis',
    components: {

    },
    data() {
        return {
            TalentSkillAnalysisService: TalentSkillAnalysisService,
            allAvailableTalents:[],
            allPlannedTalents: [],
            datePickerOptions: {},
            formatemployeeListAvailableData: [],
            formatemployeeListPlannedData: [],
            headers: [],
            loading: false,
            modalLoading: false,
            pageInfo: {
                currentPage: 1,
                pageSize: 10,
                totalResult: 0
            },
            poolOptions: [
                { id: 1, name: 'services'},
                { id: 2, name: 'products' },
            ],
            projects: [],
            recurrence: 'weekly',
            selectedDateRange: [],
            selectedPool: '',
            selectedSkills: [],
            skills: [],
            start: 0,
            tabSelected: 'available'
        }
    },
    computed: {
        formatemployeeListBySkill() {
            if (this.tabSelected === 'planned') {
                return this.formatemployeeListPlannedData;
            }
            else {
                return this.formatemployeeListAvailableData;
            }
        }
    },
    created() {
        this.TalentSkillAnalysisService.getAllSkills().subscribe(response => {
            this.skills = response;
        });
        this.setDefaultDateRange();
        this.setDatePickerOptions();
        this.loadEmployeeReportBySkill();
    },
    methods: {
        exportDataEvent() {
            let start_date, end_date;
            let headersFilter = [];
            this.loading = true;
            start_date = formatDateObject(this.selectedDateRange[0], '/');
            end_date = formatDateObject(this.selectedDateRange[1], '/');
            this.allAvailableTalents = [];
            this.allPlannedTalents = [];
            this.TalentSkillAnalysisService.getAllTalentsBySkill(this.recurrence, start_date, end_date, this.selectedSkills, this.selectedPool).subscribe(response => {
                let allAvailableObj = {};
                let allPlannedObj = {};
                for (let [key, value] of Object.entries(response.talent_available)) {
                    allAvailableObj['name'] = key;
                    allPlannedObj['name'] = key;
                    // allAvailableObj['id'] = value['id'];
                    // allPlannedObj['id'] = value['id'];
                    // allAvailableObj['url'] = value['url'];
                    // allPlannedObj['url'] = value['url'];
                    // if ('skills' in response.talent_available)
                    // { 
                        allAvailableObj['skills'] = value['skills'];
                        allPlannedObj['skills'] = value['skills'];
                    // }
                    for (let i = 0; i < response.headers.length; i++) {
                        allAvailableObj[response.headers[i]] = value[response.headers[i]]['available'];
                        allPlannedObj[response.headers[i]] = value[response.headers[i]]['planned'];
                    }
                    this.allAvailableTalents.push(allAvailableObj);
                    this.allPlannedTalents.push(allPlannedObj);
                    allAvailableObj = {};
                    allPlannedObj = {};
                }
                this.loading = false;
                let data = (this.tabSelected === 'available') ? this.allAvailableTalents : this.allPlannedTalents;
                let fileName = 'skillReport' + moment().format('MM-DD-YYYY HH:mm:ss');
                
                // this.$refs.availableTable.exportData({
                //     filename: fileName,
                //     type: 'csv',
                //     isHeader: true,
                //     isFooter: true,
                //     data
                // })
                exportXLSXFromJSON(data, fileName);
            })
        },
        formatEmployeeListReponse(response) {
          let formattedAvailableObj = {};
          let formattedPlannedObj = {};
          for (let [key, value] of Object.entries(response.talent_available)){
            formattedAvailableObj['name'] = key;
            formattedPlannedObj['name'] = key;
            formattedAvailableObj['id'] = value['id'];
            formattedPlannedObj['id'] = value['id'];
            formattedAvailableObj['url'] = value['url'];
            formattedPlannedObj['url'] = value['url'];
            formattedAvailableObj['skills'] = value['skills'];
            formattedPlannedObj['skills'] = value['skills'];
            for (let i = 0; i < response.headers.length; i++) {
                formattedAvailableObj[response.headers[i]] = value[response.headers[i]]['available'];
                formattedPlannedObj[response.headers[i]] = value[response.headers[i]]['planned'];
            }
            this.formatemployeeListAvailableData.push(formattedAvailableObj);
            this.formatemployeeListPlannedData.push(formattedPlannedObj);
            formattedAvailableObj = {};
            formattedPlannedObj = {};   
          }
        },
        formatRole({ cellValue, row }) {
            return `<a href="${row.url}" class="link" target="_black">${cellValue}</a>`;
        },
        getEmployeeListByPage() {
            this.loadEmployeeReportBySkill();

        },
        loadEmployeeReportBySkill() {
            this.loading = true;
            this.formatemployeeListAvailableData =  [];
            this.formatemployeeListPlannedData = [];
            let start_date, end_date;
            start_date = formatDateObject(this.selectedDateRange[0], '/');
            end_date = formatDateObject(this.selectedDateRange[1], '/');
            this.TalentSkillAnalysisService.getAvailableEmployeesBySkill(this.recurrence, start_date, end_date, this.selectedSkills, this.selectedPool, this.pageInfo.currentPage, this.pageInfo.pageSize).subscribe(response => {
                this.headers = response.headers;
                this.formatEmployeeListReponse(response);
                this.pageInfo.totalResult = response.total_results;
                this.loading = false;
            })
            
        },
        loadProjectDetails(id) {
          this.modalLoading = true;
          this.TalentSkillAnalysisService.getProjectDetails(id).subscribe(response => {
              this.projects = response.projects;
              this.modalLoading = false;
          })
          return this.projects;
        },
        setDatePickerOptions() {
            this.datePickerOptions = {
                shortcuts: [{
                    text: 'Next week',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(end.getTime() + 3600 * 1000 * 24 * 7);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: 'Next month',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(end.getTime() + 3600 * 1000 * 24 * 30);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: 'Next 3 months',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(end.getTime() + 3600 * 1000 * 24 * 90);
                        picker.$emit('pick', [start, end]);
                    }
                }],
                disabledDate(time) {
                    return time.getTime() < Date.now();
                }
            }
        },
        setDefaultDateRange() {
            const end = new Date();
            const start = new Date();
            // start.setTime(end.getTime() - 3600 * 1000 * 24 * 30);
            end.setTime(start.getTime() + 3600 * 1000 * 24 * 30);
            this.selectedDateRange = [start, end];
        },
        // showTooltipMethod({ type, column, row, items, _columnIndex }){
        //     const { property } = column;
        //     console.log('column=>', column);
        //     console.log('row=>', row);
        //     if (property === 'skills') {
        //         // return column.title ? row.skills + column.title : ''
        //         return row.skills;
        //     }
        // },
        sortNameMethod(a, b) {
            var v1 = (a.name || '').toLowerCase()
            var v2 = (b.name || '').toLowerCase()
            return v1 < v2 ? -1 : v1 > v2 ? 1 : 0
        },
    }
}
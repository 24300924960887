import { baseUrl, transactionService } from "../../card-transactions-service";
import { EventBus } from "../../cards-transaction-event";

export default {
  name: 'attachments-modal',
  props: {
    open: {
      type: Boolean,
      required: true,
      default: false
    },
    attachments: {
      type: Array,
      required: true,
      default: []
    },
  },
  data() {
    return {
      open_: false,
      uploadKey: 0,
      attachments_: [],
      oldAttachments_: [],
      attachmentsUploadUrl: baseUrl + 'attachments',
      loader: false,
    }
  },
  watch: {
    open(value) {
      this.open_ = value;
    },
    attachments(value) {
      this.attachments_ = [...value];
      this.oldAttachments_ = [...value];
      this.uploadKey++;
    },
  },
  created() { },
  mounted() { },
  computed: {},
  methods: {
    emitClose(event) {
      this.open_ = false;
      this.$emit('update:open', this.open_);
    },
    handleFileRemove(file, fileList) {
      this.attachments_ = fileList;
    },
    handlePreview(file) {
      transactionService.downloadAttachmentsThroughSignedUrl(file.name)
        .subscribe(res => res);
    },
    HandleHttpRequest(request) {
      this.loader = true;
      const filename = request.file.name;
      this.fileReader(request.file).then(file => {
        transactionService.uploadAttachmentsThroughSignedUrl(file, filename)
          .subscribe(res => {
            this.loader = false;
            this.attachments_.push(res)
          },
          err => {
            this.loader = false;
            console.log('uploadAttachments failed', err);
          })
      })
    },
    attachmentsUpdateCanceled() {
      this.attachments_ = this.oldAttachments_;
      this.emitClose();
    },
    attachmentsUpdated() {
      this.$emit('attachmentsUpdated', this.attachments_);
      this.oldAttachments_ = [];
      this.emitClose();
    },
    fileReader(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          resolve(reader.result);
        }
        reader.readAsArrayBuffer(file);
      })
    }
  }
}
import ElementTableWide from '../../../components/element-table-wide/element-table-wide.vue'
import XEUtils from 'xe-utils'

export default {
  name       : 'forecast-panel',
  components : {ElementTableWide},
  props      : ['startDate', 'endDate', 'projectId'],
  mounted(){
    this.loadForecastData('project_forecast');
    this.loadForecastData('overall_forecast');
    // reload project forecast panel to fetch the latest information
    this.$root.$on('updateDashboard', () => {
      this.loadForecastData('project_forecast');
      this.loadForecastData('overall_forecast');
      // this.refresh();
    })
    // this.refresh();
  },
  data() {
    return {
      projectForecastData   : [],
      projectForecastHeaders: [],
      overallForecastHeaders: [],
      overallForecastData   : [],
      loading               : true,
      recurrenceType        : 'weekly',
      threshold             : 40,
      elementTableWideKey   : 0,
      tabSelected : 'project'
    }
  },
  computed: {
    // formattedProjectForecast() {
    //   if (this.projectForecastData.length > 0)
    //     { 
    //       return this.formatApiResponse(this.projectForecastData); 
    //     }
    // },
    // formattedOverallForecast() {
    //   if (this.overallForecastData.length > 0)
    //    return this.formatApiResponse(this.overallForecastData);
    // },
    dateRange(){
      return [this.startDate, this.endDate];
    },
    forecastTableData(){
      if(this.tabSelected === 'project'){
        if (this.projectForecastData.length > 0){ 
          return this.formatApiResponse(this.projectForecastData); 
        }
      }else{
        if (this.overallForecastData.length > 0){
          return this.formatApiResponse(this.overallForecastData);
        }    
      }
    }
  },
  watch: {
    dateRange(){
      this.loadForecastData('project_forecast');
      this.loadForecastData('overall_forecast');
    }
  },
  methods: {
    refresh() {
      this.$nextTick(() => {
      let elTables = this.$children.filter(a => a.$vnode.tag.indexOf('ElTable') >= 0);
      elTables.map(elTable => {
        if (elTable) {
          let bodyAndHeaders = elTable.$children.filter(a => a.$vnode.tag.indexOf('ElTableBody') >= 0 || a.$vnode.tag.indexOf('ElTableHeader') >= 0 || a.$vnode.tag.indexOf('ElTableFooter') >= 0)
          bodyAndHeaders.map(bodyOrHeaderOrFooter => {
            Object.keys(bodyOrHeaderOrFooter._computedWatchers).forEach(k => bodyOrHeaderOrFooter._computedWatchers[k].run())
            bodyOrHeaderOrFooter.$forceUpdate();
          })
        }
      })
    })
    },
    // API response need to be formatted to fit the element table data format.
    // Since the same api is used in multiple place; formatting is implemented.
    formatApiResponse(response){
      var vm            = this;
      var formattedData = {};
      response.forEach(function(forecastPerDate){
        for (let [key, value] of Object.entries(forecastPerDate)) {
          // if (key != 'total' && key != 'date') {
          if(key != 'date'){
            if(formattedData[key]){
              formattedData[key][forecastPerDate['date']] = value;
            }else{
              formattedData[key] = {talent: key}
              formattedData[key][forecastPerDate['date']] = value;
            }
          }
        }
      });
      // vm.refresh();
      return Object.values(formattedData);
    },
    // Get the forecast data using the project forecast API.
    loadForecastData(forecastType){
      var vm = this;
      vm.loading = true;
      vm.$http.get('/api/v1/project_plans/' + this.projectId + '/forecast',{
        params: {
          start_date     : vm.startDate.toLocaleDateString('en-US'),
          end_date       : vm.endDate.toLocaleDateString('en-US'),
          project_type   : forecastType,
          recurrence_type: vm.recurrenceType
        }
      }).then(function(response){
        var responseBody = response.body.body
        if(forecastType == 'project_forecast'){
          // vm.projectForecastHeaders = ['talent'].concat(responseBody['header_row']);
          vm.projectForecastHeaders = responseBody['header_row'];
          if(responseBody['hoursPerTalent'])
            var projectData = responseBody['hoursPerTalent']['hours_per_recurrence'];
            for (var i = 0; i < projectData.length; i++) {
              delete projectData[i]['total'];
            }
            vm.projectForecastData    = projectData;
        }else{
          // vm.overallForecastHeaders = ['talent'].concat(responseBody['header_row']);
          vm.overallForecastHeaders = responseBody['header_row'];
          if(responseBody['hoursPerTalent'])
            var overallData = responseBody['hoursPerTalent']['hours_per_recurrence'];
            for (var i = 0; i < overallData.length; i++) {
              delete overallData[i]['total'];
            }
            vm.overallForecastData    = overallData;
        }

        vm.elementTableWideKey++;
        // vm.refresh();
        vm.loading = false;
      });
    },
    footerMethod({columns, data}){
      const footerData = [
        columns.map((column, columnIndex) => {
          if (columnIndex === 0) {
            return 'Total'
          }
          return XEUtils.sum(data, column.property);
        })
      ]
      return footerData
    }
  }
}
export default {
  name: 'el-select-wrapper',
  data: function() {
    return {
      selected_: {}
    };
  },
  props: {
    options: {
      type: Array,
      required: true
    },
    selected: {
      type: Object,
      required: false
    },
    placeholder: {
      type: String,
      required: false
    },
    multiple: {
      type: Boolean,
      default: false,
      required: false
    },
    multipleLimit: {
      type: Number,
      default: 1,
      required: false
    },
  },
  watch: {
    options(val) {
      this.options = val;
    },
    selected(val) {
      this.selected_ = val ? JSON.parse(JSON.stringify(val)) : this.selected_;
    }
  },
  created() {
    this.selected_ = this.selected ? JSON.parse(JSON.stringify(this.selected)) : this.selected;
  },
  mounted() {
  },
  methods: {
    emitEvent() {
      this.$emit('update:selected', this.selected_);
      this.$emit('update', this.selected_);
    }
  },
}
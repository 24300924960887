import PipelineImpactService from "./pipeline-impact-service";
import Sidebar from '../../components/sidebar/sidebar'
import SyncHubspotDeal from './components/sync-hubspot-deal/sync-hubspot-deal.vue'
import elYearPicker from '../../components/el-year-picker/el-year-picker'

const humanNumber = require('human-number');

export default {
  name: "pipeline-impact",
  props: [],
  components: {
    Sidebar, SyncHubspotDeal,
    elYearPicker: elYearPicker
  },
  filters: {},
  data() {
    return {
      PipelineImpactService: PipelineImpactService,
      accrualTmpPrvStage: new Map(),
      accrualTmpPrvStageCashRunway: new Map(),
      accrualWipArr: [],
      accrualWipObj: {},
      cashAfterQtr: 0,
      cashBeforeQtr:0,
      cashObj: {},
      cashFormatObj: {},
      cashQtrObj: {},
      cashTmpArr: [],
      cashFormatTmpArr: [],
      cashTmpPrvStage: [],
      currentStageTableData: [],
      currentStageCashTableData: [],
      developBtnStatus: '',
      developTableColumns: [],
      developTableData: [],
      developCashTableColumns: [],
      developCashTableData: [],
      formatAccrualTableData: new Map(),
      formatCashRunwayTableData: new Map(),
      gapQtr: 0,
      accrualReportLoading: false,
      cashReportLoading: false,
      noOfOpp: 0,
      stageName: '',
      oppWip: '',
      oppWinPending: '',
      oppPropose: '',
      oppDevelop: '',
      oppQualify: '',
      previousStage: '',
      proposeBtnStatus: '',
      proposeTableColumns: [],
      proposeTableData: [],
      proposeCashTableColumns: [],
      proposeCashTableData: [],
      qtrCount: 0,
      qtrObj: {},
      qualifyBtnStatus: '',
      qualifyTableColumns: [],
      qualifyTableData: [],
      qualifyCashTableColumns: [],
      qualifyCashTableData: [],
      revenueQtr: 0,
      selectedPipelineStage: 'wip',
      selectedYear: new Date(),
      showWipOpp: true,
      showWipPendingOpp: '',
      showProposeOpp: '',
      showDevelopOpp: '',
      showQualifyOpp: '',
      tmpArr: [],
      reverseTmpArr: new Map(),
      totalBudgetRevenue: 0,
      wipTotalBudgetRevenue: 0,
      winPendingTotalBudgetRevenue: 0,
      proposeTotalBudgetRevenue: 0,
      developTotalBudgetRevenue: 0,
      qualifyTotalBudgetRevenue: 0,
      totalCashAfter: 0,
      totalCashBefore:0,
      totalRevenue: 0,
      totalWip: 0,
      totalGap: 0,
      wip: 0,
      wipBtnStatus: 'primary',
      wipPendingBtnStatus: '',
      wipPendingTableColumns: [],
      winPendingTableData: [],
      wipPendingCashTableColumns: [],
      winPendingCashTableData: [],
      wipQtr: 0,
      wipTableColumns: [],
      wipTableData: [],
      wipCashTableColumns: [],
      wipCashTableData: [],
      accrualTableData: [],
      accrualTableColumns: [
        { field: 'month_name', title: 'Month' },
        { field: 'formatted_budgeted_revenue', title: 'Budgeted Revenue' },
        { field: 'formatted_wip_projection', title: 'WIP Projection' },
        { field: 'revenue_gap', title: 'Revenue Gap' }
      ],
      cashRunwayColumns: [
        { field: 'month_name', title: 'Month' },
        { field: 'formatted_cash_before', title: 'Before' },
        { field: 'formatted_cash_after', title: 'After' }
      ],
      openSyncHubspotDeal: false
    };
  },
  created() {
    this.wipTableColumns = this.wipPendingTableColumns = this.proposeTableColumns = this.developTableColumns = this.qualifyTableColumns = [...this.accrualTableColumns];
    this.wipCashTableColumns = this.wipPendingCashTableColumns = this.proposeCashTableColumns = this.developCashTableColumns = this.qualifyCashTableColumns = [...this.cashRunwayColumns];
    this.getAccrualReport();
  },
  mounted() { },
  computed: {
    
    accrualReportData() {
      return this.formatAccrualTableData;
    },
    cashReportData() {
      return this.formatCashRunwayTableData;
    },
    developOpp() {
      return this.showDevelopOpp;
    },
    qualifyOpp() {
      return this.showQualifyOpp;
    },
    proposeOpp() {
      return this.showProposeOpp;
    },
    wipOpp() {
      return this.showWipOpp;
    },
    wipPendingOpp() {
      return this.showWipPendingOpp;
    }
  },
  watch: {
    'openSyncHubspotDeal': function (newVal) {
      if(!newVal){
        window.location.reload(true);
      }
    }
  },
  methods: {
    calculateQtrData(item, index, counter, diff, arrLength) {

      this.cashBeforeQtr += parseFloat(item.cash_before);
      this.cashAfterQtr += parseFloat(item.cash_after);

      this.revenueQtr +=  parseFloat(item.budgeted_revenue);
      this.gapQtr += parseFloat(diff);
      this.wipQtr += parseFloat(item.wip);
      
      if (index > 0 && counter % 3 == 0) {
        this.qtrCount++;
      
        //for accrual report
        this.totalRevenue += parseFloat(this.revenueQtr);
        this.totalWip += parseFloat(this.wipQtr);
        this.totalGap += parseFloat(this.gapQtr);

        this.qtrObj.month_name = 'QTR' + this.qtrCount;
        this.qtrObj.formatted_budgeted_revenue = humanNumber(this.revenueQtr, n => Number.parseFloat(n).toFixed(1));

        this.qtrObj.formatted_wip_projection = humanNumber(this.wipQtr, n => Number.parseFloat(n).toFixed(1));
        let prefix = "";
        if (this.gapQtr < 0) {
          prefix = "-";
        }
      
        this.qtrObj.revenue_gap = prefix + humanNumber(this.gapQtr, n => Number.parseFloat(n).toFixed(1));
        this.qtrObj.revenue_gap = this.formatNumber(this.qtrObj.revenue_gap);

        this.qtrObj.formatted_before_stage = this.qtrObj.formatted_after_stage = '-';
        this.revenueQtr = this.gapQtr = this.wipQtr = 0;
        this.tmpArr.push(this.qtrObj);

        this.qtrObj = {};
      }
        this.calculateTotalofRows(counter, arrLength);
    },
    calculateTotalofRows(counter, arrLength) {
      if (counter == arrLength) {
        this.qtrObj.month_name = 'Total';
        this.qtrObj.formatted_budgeted_revenue = humanNumber(this.totalRevenue, n => Number.parseFloat(n).toFixed(1));
        this.qtrObj.formatted_wip_projection = humanNumber(this.totalWip, n => Number.parseFloat(n).toFixed(1));
        let prefix = "";
        if (this.totalGap < 0) {
          prefix = "-";
        }
        this.qtrObj.revenue_gap = prefix + humanNumber(this.totalGap, n => Number.parseFloat(n).toFixed(1));
        this.qtrObj.revenue_gap = this.formatNumber(this.qtrObj.revenue_gap);
        this.qtrObj.formatted_before_stage = this.qtrObj.formatted_after_stage = '-';

        this.tmpArr.push(this.qtrObj);
        this.qtrObj = {};
        this.totalRevenue = this.totalWip = this.totalGap = 0;
      }
    },
    cellStyle({ $rowIndex, column, columnIndex, $columnIndex }) {
      let color;
      color = columnIndex == 0 ? '#FFD3B7' : ((columnIndex % 4 == 0) ? '#FFD3B7' : '');
        return {
          backgroundColor: color,
          color: '#454545',
          fontWeight: columnIndex == 0 ? 'bold' : ''
        }
    },
    formatNumber(num) {
      if (!(/\d/.test(num) && /[a-zA-Z]/.test(num))) {
        if (num / 1000 < 1)
        // return num = (num / 1000) + 'K';
        return '~0K';
      }
      else {
        return num;
      }
    },
    formatPipelineReportData(accrualReports, stage) {
      let dateObj, givenMonth;
      let counter = 0;
      let prevStageAccData, prevStageWip;
      
      accrualReports.forEach((item, index) => {
        counter++;
        this.qtrObj.formatted_budgeted_revenue = humanNumber(item.budgeted_revenue, n => Number.parseFloat(n).toFixed(1));
        this.qtrObj.formatted_wip_projection = 0;
        this.wip = item.wip_projection;
        if (stage !== 'wip') {
          prevStageAccData = this.accrualTmpPrvStage.get(this.previousStage);
          this.wip = parseFloat(prevStageAccData[index].wip) + parseFloat(item.wip_projection);
        }
        this.qtrObj.formatted_wip_projection = humanNumber(this.wip, n => Number.parseFloat(n).toFixed(1));
        
        item.wip = this.wip;
        item.formatted_wip_projection = this.qtrObj.formatted_wip_projection;
        this.accrualWipObj.wip = item.wip;

        let diff = parseFloat(this.wip) - parseFloat(item.budgeted_revenue);
        let prefix = "";
        if (diff < 0){
          prefix = "-";
        }
        this.qtrObj.revenue_gap = prefix + humanNumber(diff, n => Number.parseFloat(n).toFixed(1));
        this.qtrObj.revenue_gap = this.formatNumber(this.qtrObj.revenue_gap);

        dateObj = new Date(item.month + ' 00:00:00');
        this.qtrObj.month_name = moment(dateObj).format("MMM'YY");

        // cash runway logic starts here
        this.cashObj.month_name = this.qtrObj.month_name;
        this.cashFormatObj.month_name = this.cashObj.month_name;
        givenMonth = moment(item.month, "DD-MM-YYYY").format('MMM');
        // cashRcvDate = moment(item.month, "DD-MM-YYYY").add('days', 60);
        // cashRcvMonth = cashRcvDate.format('MMM');
        const itemMonth = moment(dateObj).format("MM");
        const currentMonth = moment().format('MM');
        if( stage === 'wip') {
          // Check year & existing month and makes all previous month values 0 of current & previous year
          item.cash_balance = (this.selectedYear.getFullYear() > new Date().getFullYear()) ? item.cash_balance : ((currentMonth > itemMonth) ? 0 : item.cash_balance);
          // item.cash_balance = (currentMonth > itemMonth) ? 0 : item.cash_balance;
          this.cashObj.cash_before = item.cash_balance;
          this.cashObj.cash_after = this.cashObj.cash_before;
          this.cashFormatObj.formatted_cash_before = humanNumber(item.cash_balance, n => Number.parseFloat(n).toFixed(1));
          this.cashFormatObj.formatted_cash_after = humanNumber(this.cashObj.cash_before, n => Number.parseFloat(n).toFixed(1));
        }
        else {
          const displayMonth = moment(this.cashTmpPrvStage[index].month_name).format('MM');
          // Check existing month and makes all previous month values 0
          // this.cashTmpPrvStage[index].cash_after = (currentMonth > displayMonth) ? 0 : this.cashTmpPrvStage[index].cash_after;
          // Check year & existing month and makes all previous month values 0 of current & previous year
          if (this.selectedYear.getFullYear() > new Date().getFullYear()) {
            this.cashTmpPrvStage[index].cash_after = this.cashTmpPrvStage[index].cash_after;
          }
          else {
            this.cashTmpPrvStage[index].cash_after = (currentMonth > displayMonth) ? 0 : this.cashTmpPrvStage[index].cash_after;
          }
          this.cashObj.cash_before = this.cashTmpPrvStage[index].cash_after;
          this.cashFormatObj.formatted_cash_before = humanNumber(this.cashTmpPrvStage[index].cash_after, n => Number.parseFloat(n).toFixed(1));
          if (givenMonth === 'Jan' || givenMonth === 'Feb') {
            this.cashObj.cash_after = this.cashObj.cash_before;
            this.cashFormatObj.formatted_cash_after = humanNumber(this.cashObj.cash_before, n => Number.parseFloat(n).toFixed(1));
          }
          else {
            // prevStageAccData = this.accrualTmpPrvStage.get(stage);
            // Check year & existing month and makes all previous month values 0 of current & previous year
            if(this.selectedYear.getFullYear() > new Date().getFullYear()) {
              prevStageWip = accrualReports[index - 2].wip_projection;
            }
            else {
              prevStageWip = (currentMonth > displayMonth) ? 0 : accrualReports[index - 2].wip_projection;
            }
            // prevStageAccData[index - 2].wip = (currentMonth > displayMonth) ? 0 : prevStageAccData[index - 2].wip
            this.cashObj.cash_after = this.cashObj.cash_before + prevStageWip;
            let sum = this.cashObj.cash_before + prevStageWip;
            this.cashFormatObj.formatted_cash_after = humanNumber(sum, n => Number.parseFloat(n).toFixed(1));
          }
        }   
        item.cash_before = this.cashObj.cash_before;
        item.cash_after = this.cashObj.cash_after;
        this.cashTmpArr.push(this.cashObj);
        this.cashObj = {};
        this.cashFormatTmpArr.push(this.cashFormatObj);
        this.cashFormatObj = {};
        this.accrualWipArr.push(this.accrualWipObj);
        this.accrualWipObj = {};
        // cash runway logic ends here
        this.tmpArr.push(this.qtrObj);
        this.qtrObj = {};
        this.calculateQtrData(item, index, counter, diff, accrualReports.length);
      });
      // cash runway logic starts here
      this.formatCashRunwayTableData.set(stage, this.cashFormatTmpArr);
      this.cashTmpPrvStage = this.cashTmpArr;
      this.cashTmpArr = [];
      this.cashFormatTmpArr = [];
      this.accrualTmpPrvStage.set(stage, this.accrualWipArr);
      this.accrualWipArr = [];
      // cash runway logic ends here
      this.formatAccrualTableData.set(stage, this.tmpArr);
      this.tmpArr = [];
    },
    getAccrualReport() {
      this.accrualReportLoading = true;
      this.PipelineImpactService.getAccrualPipelineReport(this.selectedYear.getFullYear()).subscribe(
        (response) => {
          this.accrualTableData = response;
          this.accrualReportLoading = false;
          return (response.length > 0) ? this.storeStageTableData(this.accrualTableData) : [];
        }
      );
    },
    getAccrualReportByYear(){
      let tmp = this.selectedYear;
      this.reset();
      this.selectedYear = tmp;
      this.getAccrualReport();
    },
    getPipelineReports(selectedStage) {
      this.selectedPipelineStage = selectedStage;
      this.updateCurrentStageTableData();
    },
    reset() {
      Object.assign(this.$data, this.$options.data.call(this));
      this.wipTableColumns = this.wipPendingTableColumns = this.proposeTableColumns = this.developTableColumns = this.qualifyTableColumns = [...this.accrualTableColumns];
      this.wipCashTableColumns = this.wipPendingCashTableColumns = this.proposeCashTableColumns = this.developCashTableColumns = this.qualifyCashTableColumns = [...this.cashRunwayColumns];
    },
    reverseArrayData(columns, arrData) {
      let tableData = arrData;
      let data = columns.map(column => {
        let item = { 0: column.title }
        tableData.forEach((row, rowIndex) => {
          item[rowIndex + 1] = row[column.field];
        })
        return item
      });
      return data;
    },
    reverseArrayColumns(columns, arrData, flag = false) {
      let tableData = arrData;
      let colWidth = flag ? 89 : 80;
      return columns = [{
        field: '0',
        fixed: 'left',
        width: 155
      }].concat(tableData.map((item, index) => {
        return {
          field: `${index + 1}`,
          width: colWidth
        }
      }));
    },
    rowStyle({ row, rowIndex }) {
      let color;
      color = rowIndex == 0 ? '#FFD3B7' : (rowIndex == 3 ? '#E5976C' : 'white');
      // if (rowIndex == 0 || rowIndex == 3) {
      return {
        backgroundColor: color,
        color: '#454545',
        fontWeight: rowIndex == 0 ? 'bold' : ''
        // }
      }
    },
    storeStageTableData(responseData) {
      responseData.forEach((item, index) => {
        switch (item.stage) {
          case 'wip':
            this.wipTotalBudgetRevenue = humanNumber(item.total_revenue, n => Number.parseFloat(n).toFixed(1));
            this.oppWip = `(${item.no_of_opportunities})`;
            this.previousStage = '';
            this.formatPipelineReportData(item.stage_revenue_breakdown, item.stage);
            this.qtrCount = 0;
            this.wipTableData = this.reverseArrayData(this.wipTableColumns, this.formatAccrualTableData.get(item.stage));
            this.wipTableColumns = this.reverseArrayColumns(this.wipTableColumns, this.formatAccrualTableData.get(item.stage));
            this.wipCashTableData = this.reverseArrayData(this.wipCashTableColumns, this.formatCashRunwayTableData.get(item.stage));
            this.wipCashTableColumns = this.reverseArrayColumns(this.wipCashTableColumns, this.formatCashRunwayTableData.get(item.stage), true);
            break;
          case 'Win pending':
            this.winPendingTotalBudgetRevenue = humanNumber(item.total_revenue, n => Number.parseFloat(n).toFixed(1));
            this.oppWinPending = `(${item.no_of_opportunities})`;
            this.previousStage = 'wip';
            this.formatPipelineReportData(item.stage_revenue_breakdown, item.stage);
            this.qtrCount = 0;
            this.winPendingTableData = this.reverseArrayData(this.wipPendingTableColumns, this.formatAccrualTableData.get(item.stage));
            this.wipPendingTableColumns = this.reverseArrayColumns(this.wipPendingTableColumns, this.formatAccrualTableData.get(item.stage));
            this.winPendingCashTableData = this.reverseArrayData(this.wipPendingCashTableColumns, this.formatCashRunwayTableData.get(item.stage));
            this.wipPendingCashTableColumns = this.reverseArrayColumns(this.wipPendingCashTableColumns, this.formatCashRunwayTableData.get(item.stage), true);
            break;
          case 'Propose':
            this.proposeTotalBudgetRevenue = humanNumber(item.total_revenue, n => Number.parseFloat(n).toFixed(1));
            this.oppPropose = `(${item.no_of_opportunities})`;
            this.previousStage = 'Win pending';
            this.formatPipelineReportData(item.stage_revenue_breakdown, item.stage);
            this.qtrCount = 0;
            this.proposeTableData = this.reverseArrayData(this.proposeTableColumns, this.formatAccrualTableData.get(item.stage));
            this.proposeTableColumns = this.reverseArrayColumns(this.proposeTableColumns, this.formatAccrualTableData.get(item.stage));
            this.proposeCashTableData = this.reverseArrayData(this.proposeCashTableColumns, this.formatCashRunwayTableData.get(item.stage));
            this.proposeCashTableColumns = this.reverseArrayColumns(this.proposeCashTableColumns, this.formatCashRunwayTableData.get(item.stage), true);
            break;
          case 'Develop':
            this.developTotalBudgetRevenue = humanNumber(item.total_revenue, n => Number.parseFloat(n).toFixed(1));
            this.oppDevelop = `(${item.no_of_opportunities})`;
            this.previousStage = 'Propose';
            this.formatPipelineReportData(item.stage_revenue_breakdown, item.stage);
            this.qtrCount = 0;
            this.developTableData = this.reverseArrayData(this.developTableColumns, this.formatAccrualTableData.get(item.stage));
            this.developTableColumns = this.reverseArrayColumns(this.developTableColumns, this.formatAccrualTableData.get(item.stage));
            this.developCashTableData = this.reverseArrayData(this.developCashTableColumns, this.formatCashRunwayTableData.get(item.stage));
            this.developCashTableColumns = this.reverseArrayColumns(this.developCashTableColumns, this.formatCashRunwayTableData.get(item.stage), true);
            break;
          case 'Qualify':
            this.qualifyTotalBudgetRevenue = humanNumber(item.total_revenue, n => Number.parseFloat(n).toFixed(1));
            this.oppQualify = `(${item.no_of_opportunities})`;
            this.previousStage = 'Develop';
            this.formatPipelineReportData(item.stage_revenue_breakdown, item.stage);
            this.qtrCount = 0;
            this.qualifyTableData = this.reverseArrayData(this.qualifyTableColumns, this.formatAccrualTableData.get(item.stage));
            this.qualifyTableColumns = this.reverseArrayColumns(this.qualifyTableColumns, this.formatAccrualTableData.get(item.stage));
            this.qualifyCashTableData = this.reverseArrayData(this.qualifyCashTableColumns, this.formatCashRunwayTableData.get(item.stage));
            this.qualifyCashTableColumns = this.reverseArrayColumns(this.qualifyCashTableColumns, this.formatCashRunwayTableData.get(item.stage), true);
            break;
          default:
            break;
        }
      });
      this.updateCurrentStageTableData();
    },
    updateCurrentStageTableData() {
      switch (this.selectedPipelineStage) {
        case 'wip':
          this.totalBudgetRevenue = this.wipTotalBudgetRevenue;
          this.currentStageTableData = this.wipTableData;
          this.accrualTableColumns = this.wipTableColumns;
          this.previousStage = '';
          this.currentStageCashTableData = this.wipCashTableData;
          this.cashRunwayColumns = this.wipCashTableColumns;
          this.showWipOpp = true;
          this.stageName = 'WIP';
          this.noOfOpp = this.oppWip.replace(/[^A-Za-z0-9]/g,"");
          this.showWipPendingOpp = this.showProposeOpp = this.showDevelopOpp = this.showQualifyOpp = '';
          this.wipBtnStatus = 'primary';
          this.wipPendingBtnStatus = this.proposeBtnStatus = this.developBtnStatus = this.qualifyBtnStatus = '';
          break;
        case 'Win pending':
          this.totalBudgetRevenue = this.winPendingTotalBudgetRevenue;
          this.currentStageTableData = this.winPendingTableData;
          this.accrualTableColumns = this.wipPendingTableColumns;
          this.previousStage = 'wip';
          this.currentStageCashTableData = this.winPendingCashTableData;
          this.cashRunwayColumns = this.wipPendingCashTableColumns;
          this.showWipOpp = true;
          this.showWipPendingOpp = true;
          this.noOfOpp = this.oppWinPending.replace(/[^A-Za-z0-9]/g,"");
          this.stageName = 'Win pending';
          this.showProposeOpp = this.showDevelopOpp = this.showQualifyOpp = '';
          this.wipPendingBtnStatus = this.wipBtnStatus = 'primary';
          this.proposeBtnStatus = this.developBtnStatus = this.qualifyBtnStatus = '';
          break;
        case 'Propose':
          this.totalBudgetRevenue = this.proposeTotalBudgetRevenue;
          this.currentStageTableData = this.proposeTableData;
          this.accrualTableColumns = this.proposeTableColumns;
          this.previousStage = 'Win pending';
          this.currentStageCashTableData = this.proposeCashTableData;
          this.cashRunwayColumns = this.proposeCashTableColumns;
          this.showWipOpp = this.showWipPendingOpp = this.showProposeOpp = true;
          this.noOfOpp = this.oppPropose.replace(/[^A-Za-z0-9]/g,"");
          this.stageName = 'Propose';
          this.showDevelopOpp = this.showQualifyOpp = '';
          this.proposeBtnStatus = this.wipBtnStatus = this.wipPendingBtnStatus = 'primary';
          this.developBtnStatus = this.qualifyBtnStatus = '';
          break;
        case 'Develop':
          this.totalBudgetRevenue = this.developTotalBudgetRevenue;
          this.currentStageTableData = this.developTableData;
          this.accrualTableColumns = this.developTableColumns;
          this.previousStage = 'Propose';
          this.currentStageCashTableData = this.developCashTableData;
          this.cashRunwayColumns = this.developCashTableColumns;
          this.showWipOpp = this.showWipPendingOpp = this.showProposeOpp = this.showDevelopOpp = true;
          this.noOfOpp = this.oppDevelop.replace(/[^A-Za-z0-9]/g,"");
          this.stageName = 'Develop';
          this.showQualifyOpp = '';
          this.developBtnStatus = this.wipBtnStatus = this.wipPendingBtnStatus = this.proposeBtnStatus = 'primary';
          this.qualifyBtnStatus = '';
          break;
        case 'Qualify':
          this.totalBudgetRevenue = this.qualifyTotalBudgetRevenue;
          this.currentStageTableData = this.qualifyTableData;
          this.accrualTableColumns = this.qualifyTableColumns;
          this.previousStage = 'Develop';
          this.currentStageCashTableData = this.qualifyCashTableData;
          this.cashRunwayColumns = this.qualifyCashTableColumns;
          this.showWipOpp = this.showWipPendingOpp = this.showProposeOpp = this.showDevelopOpp = this.showQualifyOpp = true;
          this.noOfOpp = this.oppQualify.replace(/[^A-Za-z0-9]/g,"");
          this.stageName = 'Qualify';
          this.qualifyBtnStatus = this.wipBtnStatus = this.wipPendingBtnStatus = this.proposeBtnStatus = this.developBtnStatus = 'primary';
          break;
        default:
          break;
      }
    },
    exportPipelineReports () {
      const date = moment().format('MM-DD-YYYY')
      const accrualFileName = "Accrual_"+ this.stageName+ "_"+ date
      this.$refs.exportAccrualDataCsv.exportData(
        { 
          type: 'xlsx', 
          filename: accrualFileName, 
          sheetName: accrualFileName,
          message: false,
          isHeader: false
        }
      )
      const cashFileName = "Cash Runway_" + this.stageName + "_" + date
      this.$refs.exportCashRunwayDataCsv.exportData(
        {
          type: 'xlsx',
          filename: cashFileName,
          sheetName: cashFileName,
          message: false,
          isHeader: false
        }
      )
    }
  }
};

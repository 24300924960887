
import "./talent-forecast-widget.css"
import Vue from 'vue/dist/vue.esm'
import vSelect from 'vue-select'
Vue.component('v-select', vSelect)

import AmCharts from 'amcharts3'
import AmSerial from 'amcharts3/amcharts/serial'
import VueResource from 'vue-resource'; // HTTP client for Vue.js

Vue.use(AmCharts);
Vue.use(AmSerial);
Vue.use(VueResource);


var talentForecastElement = document.getElementById("dashboard_widgets_vue");
if (talentForecastElement != null){

  new Vue({
  el: '#dashboard_widgets_vue',
  data: {
    graphData : [],
    headerRow: [],
    hoursPerProject: [],
    startDate: new Date(),
  },
  computed: {
    hours_per_project: function() {
      var vm = this;
      var categorizedHours = {};
      var arr =  this.hoursPerProject;
      arr.forEach(function(elem){
        for (var projKey in elem) {
          if (projKey != "date" && projKey !="total") {
                categorizedHours[projKey] = categorizedHours[projKey] || 0
                categorizedHours[projKey] = categorizedHours[projKey] + elem[projKey]
            }
        }
      });
      return categorizedHours;
    },
    chart_data_available: function() {
      return (this.graphData.dataProvider.length == 0)
    }
  },
  created: function() {
    var vm = this;
    vm.$http.get('/api/v1/talent_forecast_widget',{
      params : {
        current_user    : $("#talent_forecast_widget").attr("data-user"),
        start_date      : moment().startOf('month').format("MM-DD-YYYY"),
        end_date        : moment().endOf('month').format("MM-DD-YYYY")
      }
    })
    .then(function(response) {
      vm.graphData        = response.body.body.totalEstimateHours.hours_per_recurrence;
      vm.headerRow        = response.body.body.header_row
      vm.hoursPerProject  = response.body.body.hoursPerTalent.hours_per_recurrence

      this.showTalentForecastChart(vm.graphData, response.body.body.totalEstimateHours.collection[0] );
    });
  },
  methods: {
    checkEmptyData: function (chart) {
      if ( chart.dataProvider.length == 0) {
        chart.addLabel(15, '40%', 'The chart contains no data', 'center', 10);
        chart.validateNow();
      }
    },
    showTalentForecastChart: function (dataProvider, valueField){
      var chart2 = window.AmCharts.makeChart("talent_forecast_widget_chart", {
        "type"              : "serial",
        "theme"             : "light",
        "categoryField"     : "date",
        "chartCursor"       : {},
        "legend": {
          "useGraphSettings": false,
          "markerSize"      : 16,
          "autoMargins"     : true,
          "marginLeft"      : 70,
          "divId"           : 'talent_forecast_' + Math.random(),
          "enabled"         : false
        },
        "valueAxes": [{
         "title": "",
         "gridAlpha"   : 0,
         "minMaxMultiplier" : 1.2,
         "minimum": 0,
         "includeGuidesInMinMax": true,
         "fontSize"    : 7.5,
        }],
        "chartCursor"   : {
          "valueLineBalloonEnabled" : true,
          "valueLineAlpha"          : 0,
          "cursorAlpha"             : 0.05,
          "selectWithoutZooming": true
        },
        "dataDateFormat": "MM-DD-YYYY",
        "categoryField" : "date",
        "categoryAxis"  : {
          "gridPosition": "start",
          "gridAlpha"   : 0,
          "autoWrap"    : true,
          "fontSize"    : 7.5,
          "autoGridCount" : false,
          "gridCount"   : 20
        },
        "graphs" : [
          {
            "type"        : "smoothedLine",
            "valueField"  : valueField,
            "lineColor"   : "#e66c73",
            "bullet"            : "round",
            "bulletBorderAlpha" : 1,
            "bulletColor"       : "#FFFFFF",
            "bulletSize"        : 7,
            "lineThickness"     : 2,
            "useLineColorForBulletBorder" : true,
            "connect"                     : false
          }
        ],
        "dataProvider"      : dataProvider
      });
      this.addThresholdGuide(chart2, 40);
      this.checkEmptyData(chart2);
    },
    addThresholdGuide: function (chart, threshold_hours, lineColor){
      lineColor = lineColor || "#9699a2";
      var guide         = new window.AmCharts.Guide();
      guide.value       = parseFloat(($('#threshold_value').val() ? $('#threshold_value').val() : 100)) * threshold_hours/100;
      guide.dashLength  = 6;
      guide.inside      = true;
      guide.lineAlpha   = 1;
      guide.position    = "right";
      guide.label       = 'threshold:' + ($('#threshold_value').val() ? $('#threshold_value').val() : 100) + '%';
      guide.lineColor   = lineColor;
      if(chart.valueAxes[0].addGuide) {
        chart.valueAxes[0].addGuide(guide);
      } else {
        chart.addListener("dataUpdated", function(){
          chart.valueAxes[0].addGuide(guide);
        });
      }
      chart.validateData();
    }

  }
  });
}

import TransactionTable from "./components/transaction-table/transaction-table";
import EventBus from "./cards-transaction-event";
import { baseUrl } from "./card-transactions-service";
import { transactionService } from "./card-transactions-service";
import exportCSVFile from "../utils/json-to-csv.js";

export default {
  name: "card-transactions",

  components: {
    TransactionTable,
  },

  data() {
    return {
      bulkUploadUrl: baseUrl + 'bulk-upload',
      currentUser: null
    };
  },

  created() {
    transactionService.getCurrentUser()
    .subscribe(user => {
      this.currentUser = user;
    });
  },

  mounted() { },

  watch: {},

  computed: {},

  methods: {
    HandleHttpRequest(request) {
      transactionService.bulkUpload(request.file)
        .subscribe(res => {
          if (res.failed.length > 0) {
            bootstrapNotify('There are some records with no matching card numbers in the system, they have been exported to csv.', 'info', 8000);
            this.downloadFailedCSV(res.failed);
          }
          if (res.success.length) {
            EventBus.$emit('refreshTransactions');
          }
        });
    },
    downloadFailedCSV(failedRecords) {
      // map like (key in records : display header label)
      const headers = {
        'Card': 'Card',
        'Transaction Date': 'Transaction Date',
        'Post Date': 'Post Date',
        'Description': 'Description',
        'Category': 'Category',
        'Type': 'Type',
        'Amount': 'Amount',
        'Memo': 'Memo'
      };
      exportCSVFile(headers, failedRecords, 'failedrecords-'+Date.now());
    },
  },
};
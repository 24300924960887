export default {
  name: 'el-year-picker',
  data: function () {
    return {
      year: JSON.parse(JSON.stringify(this.selectedYear))
    };
  },
  props: {
    yearFormat: {
      type: String,
      required: true
    },
    selectedYear: {
      required: true
    },
    clearable: {
      type: Boolean,
      required: false,
      default: false
    },
  },

  mounted() {
    this.init();
  },
  methods: {
    init() {
      
    },
    sendSelectedYearToParent() {
      this.$emit('update:selectedYear', this.year);
      this.$emit('updatedYear', this.year);
    }
  },
  watch: {
    
  }
}
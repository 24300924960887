import elYearPicker from "../../../components/el-year-picker/el-year-picker";
import elementUiTable from "../../../../frontend/components/element-ui-table/element-ui-table";
import layeredColumnChart from "../../../components/layered-column-chart/layered-column-chart";
import breadcrumb from "../../../components/breadcrumb/breadcrumb";
import accrualsDashboardService from "./accruals-dashboard-service";
import ElSelectWrapper from "../../../components/el-select-wrapper/el-select-wrapper";
import elMonthPicker from "../../../components/el-month-picker/el-month-picker";
import projectListWidget from "./components/project-list-widget/project-list-widget";
import projectListTable from "./components/project-list-table/project-list-table";
import QueryStringWrapper from "../../../utils/query-string-wrapper";
import ProjectAccrualCreate from "../project-accrual-create/project-accrual-create";
import ProjectAccrualCreateService from "../project-accrual-create/project-accrual-create-service";
import SnapshotWeekClosure from "./components/snapshot-week-closure/snapshot-week-closure.vue";
import SnapshotMonthClosure from "./components/snapshot-month-closure/snapshot-month-closure.vue";
import Sidebar from "../../../components/sidebar/sidebar";
import moment from "moment";
import EventBus from "./../../../finance/accruals/project-accrual-report/global-event-bus";
import metronicPagination from "../../../components/metronic-pagination/metronic-pagination";
import SyncAccrualSheet from "./components/sync-accrual-sheet/sync-accrual-sheet.vue";

import {
  copyToClipboard,
  dummyObservable,
  formatDateObject
} from "../../../utils/utils";

import { concatMap } from "rxjs/operators";
import { of } from "rxjs";

export default {
  name: "accruals-dashboard",
  components: {
    elYearPicker,
    elementUiTable,
    layeredColumnChart,
    breadcrumb,
    ElSelectWrapper,
    elMonthPicker,
    projectListWidget,
    projectListTable,
    ProjectAccrualCreate,
    SnapshotWeekClosure,
    SnapshotMonthClosure,
    Sidebar,
    metronicPagination,
    SyncAccrualSheet
  },
  data() {
    return {
      accrualsDashboardService: accrualsDashboardService,
      ProjectAccrualCreateService: ProjectAccrualCreateService,
      yearlyRevenueChartSeries: [],
      projectSummaryChartSeries: [],
      projectSummaryData: [],
      projectListData: [],
      allProjectSummaryData: [],
      yearlyRevenueChartData: [],
      projectManagerFilterOptions: [],
      breadcrumbOptions: [],
      yearlyRevenueTableHeaders: [],
      projectSummaryChartData: [],
      value1: "",
      projectTypeFilterOptions: [],
      selectedProjectType: null,
      selectedPM: null,
      slideNo: 1,
      filterParam: {},
      selectedMonth: new Date(),
      queryStringPresent: false,
      paginationConfig: {
        currentPage: 1,
        itemsPerPage: 10,
        maxLength: 9,
        totalNoOfProjects: null,
        name: "projects"
      },
      oldfilterParams: {},
      yearlyRevenueDataloading: false,
      projectSummaryDataLoading: false,
      projectListDataLoading: false,
      loadingList: false,
      selectedYear: new Date(),
      breadcrumbRootPage: "",
      monthRange: {},
      endMonth: "",
      swiper: null,
      openSnapshotWeekClosure: false,
      openSnapshotMonthClosure: false,
      openProjectAccrualCreate: false,
      openSyncAccrualSheet: false,
      isCardLayout: true,
      currentUser: {
        id: null
      },
      itemsPerPage: 5
    };
  },
  computed: {
    // quarterPickerOptions() {
    //   return [
    //     {
    //       "id": 1,
    //       "name": "Q1 - " + this.selectedYear.getFullYear()
    //     },
    //     {
    //       "id": 2,
    //       "name": "Q2 - " + this.selectedYear.getFullYear()
    //     },
    //     {
    //       "id": 3,
    //       "name": "Q3 - " + this.selectedYear.getFullYear()
    //     },
    //     {
    //       "id": 4,
    //       "name": "Q4 - " + this.selectedYear.getFullYear()
    //     }
    //   ]
    // },
    showSidebar() {
      if (
        this.currentUser &&
        this.currentUser.id &&
        this.currentUser.permissions["AccrualPolicy"] === "modify"
      ) {
        return true;
      }
      return false;
    },
    allowCreate() {
      if (
        this.currentUser &&
        this.currentUser.id &&
        (this.currentUser.permissions["AccrualPolicy"] === "modify" ||
          this.currentUser.permissions["AccrualPolicy"] === "write")
      ) {
        return true;
      }
      return false;
    },
    formatedProjectManagerFilterOptions() {
      this.projectManagerFilterOptions.unshift({
        id: -1,
        name: "All Project Managers"
      });
      return this.projectManagerFilterOptions;
    }
  },
  created() {
    this.selectedMonth = new Date(
      this.selectedMonth.getFullYear(),
      this.selectedMonth.getMonth(),
      1
    );
    this.setYearlyRevenueChartConfigs();
    this.setProjectSummaryChartConfigs();
    this.setYearlyRevenueTableHeaders();
    this.setProjectTypeFilterOptions();
    this.setBreadcrumbOptions();
    this.attachLoaderHandlers();
    // this.setDefaultQuater();
    this.queryStringHandler();
    // this.assignSelectedPM();
    // this.selectedProjectType = this.projectTypeFilterOptions[0];
    EventBus.$on("paginateProjectList", (nextSlide, swiper) => {
      this.swiper = swiper;
      this.slideNo = nextSlide ? ++this.slideNo : --this.slideNo;
      if (
        nextSlide &&
        3 <= this.projectSummaryData.length - (this.slideNo - 1) * 3 &&
        this.allProjectSummaryData.length !== this.projectSummaryData.length
      ) {
        let startIndex = (this.slideNo - 1) * 3;
        let chunk = this.allProjectSummaryData.slice(
          startIndex,
          startIndex + 3
        );
        this.projectSummaryData.splice(this.projectSummaryData.length - 3);
        this.projectSummaryData.push(...chunk);
        let diff =
          this.allProjectSummaryData.length - this.projectSummaryData.length;
        if (diff > 0) {
          if (diff > 3) {
            this.projectSummaryData.push({}, {}, {});
          }
          if (diff <= 3) {
            let data = this.allProjectSummaryData.slice(
              this.allProjectSummaryData.length - diff
            );
            this.projectSummaryData.push(...data);
          }
        }
      }
    });
    // this.selectedProjectType = this.projectTypeFilterOptions[0];
    EventBus.$on("updateAccrualStatus", resp => {
      let newValue = resp.value ? false : true;
      this.updateAccrualStatus(resp.id, newValue);
      if (resp.reload) {
        this.getListView(this.filterParam);
      }
    });

    EventBus.$on("resetAccrualListView", this.getListView.bind(this));

    EventBus.$on("fetchAccrualFilterData", filterParams => {
      this.getListView(filterParams);
    });
    EventBus.$on("fetchAccrualsForPage", pageNum => {
      if (this.paginationConfig.currentPageNum !== pageNum) {
        this.paginationConfig.currentPageNum = pageNum;
        this.getListView(this.oldfilterParams);
      }
    });
  },
  mounted() {
    if (this.queryStringPresent) {
      this.queryStringInit();
    } else {
      // this.defaultInit();
      this.getCardView();
    }
    this.selectedProjectType = this.projectTypeFilterOptions[0];
  },
  methods: {
    attachLoaderHandlers() {
      this.accrualsDashboardService.yearlyRevenueDataloading.subscribe(
        response => (this.yearlyRevenueDataloading = response)
      );
      this.accrualsDashboardService.projectSummaryDataLoading.subscribe(
        response => (this.projectSummaryDataLoading = response)
      );
    },
    setYearlyRevenueChartConfigs() {
      this.yearlyRevenueChartSeries = [
        {
          key: "monthly_revenue",
          label: "Revenue",
          color: "#3A397B"
        },
        {
          key: "monthly_invoice",
          label: "Invoice",
          color: "#00BAFF"
        }
      ];
    },
    setProjectSummaryChartConfigs() {
      this.projectSummaryChartSeries = [
        {
          key: "revenue",
          label: "Revenue",
          color: "#040058"
        },
        {
          key: "total_invoice",
          label: "Invoice",
          color: "#FF6471"
        }
      ];
    },
    setYearlyRevenueTableHeaders() {
      this.yearlyRevenueTableHeaders = [
        {
          key: "month",
          label: "Month",
          sortable: false,
          filter: false,
          currency: false
        },
        {
          key: "monthly_revenue",
          label: "Revenue",
          sortable: false,
          filter: false,
          currency: true
        },
        {
          key: "monthly_invoice",
          label: "Invoice",
          sortable: false,
          filter: false,
          currency: true
        }
      ];
    },
    setProjectTypeFilterOptions() {
      this.projectTypeFilterOptions = [
        {
          id: 3,
          name: "All"
        },
        {
          id: 1,
          name: "Fixed Price"
        },
        {
          id: 2,
          name: "Retainer"
        },
        {
          id: 0,
          name: "T&M"
        }
      ];
    },
    queryStringHandler() {
      const queryString = QueryStringWrapper.parse(window.location.search);
      if (!Object.keys(queryString).length) {
        this.queryStringPresent = false;
        return;
      }

      this.queryStringPresent = true;
      this.selectedProjectType = JSON.parse(queryString.selectedProjectType);
      this.selectedPM = JSON.parse(queryString.selectedPM);

      if (queryString.selectedMonth) {
        this.selectedMonth = new Date(queryString.selectedMonth);
      }

      if (queryString.selectedYear) {
        this.selectedYear = new Date(queryString.selectedYear);
      }
    },
    setBreadcrumbOptions() {
      this.breadcrumbOptions = [
        {
          label: "Accruals Dashboard",
          isHref: false,
          href: "/"
        }
      ];
    },
    // setDefaultQuater() {
    //   const month = new Date().getMonth();
    //   let quater;
    //   switch (month) {
    //     case 0:
    //     case 1:
    //     case 2:
    //       this.selectedQuarter = this.quarterPickerOptions[0];
    //       this.setStartEndMonth();
    //       break;
    //     case 3:
    //     case 4:
    //     case 5:
    //       this.selectedQuarter = this.quarterPickerOptions[1];
    //       this.setStartEndMonth();
    //       break;
    //     case 6:
    //     case 7:
    //     case 8:
    //       this.selectedQuarter = this.quarterPickerOptions[2];
    //       this.setStartEndMonth();
    //       break;
    //     case 9:
    //     case 10:
    //     case 11:
    //       this.selectedQuarter = this.quarterPickerOptions[3];
    //       this.setStartEndMonth();
    //       break;
    //     default:
    //       break;
    //   }
    // },
    // setStartEndMonth() {
    //   switch (this.selectedQuarter.id) {
    //     case 1:
    //       this.startMonth = '01/01/' + this.selectedYear.getFullYear();
    //       this.endMonth = '03/01/' + this.selectedYear.getFullYear();
    //       break;
    //     case 2:
    //       this.startMonth = '04/01/' + this.selectedYear.getFullYear();
    //       this.endMonth = '06/01/' + this.selectedYear.getFullYear();
    //       break;
    //     case 3:
    //       this.startMonth = '07/01/' + this.selectedYear.getFullYear();
    //       this.endMonth = '09/01/' + this.selectedYear.getFullYear();
    //       break;
    //     case 4:
    //       this.startMonth = '10/01/' + this.selectedYear.getFullYear();
    //       this.endMonth = '12/01/' + this.selectedYear.getFullYear();
    //       break;
    //     default:
    //       break;
    //   }
    // },
    assignSelectedPM() {
      // this.accrualsDashboardService.getCurrentUser().subscribe(response => {
      //   this.currentUser = response;

      const userFound = this.formatedProjectManagerFilterOptions.filter(
        item => item.id == this.currentUser.id
      );
      if (userFound.length) {
        this.selectedPM = this.currentUser;
      } else {
        this.selectedPM = this.formatedProjectManagerFilterOptions[0];
      }
      return of(this.selectedPM);
      // });
    },
    async defaultInit() {
      this.init$ ? this.init$.unsubscribe() : "";
      this.init$ = this.accrualsDashboardService
        .getProjectManagersList()
        .pipe(
          concatMap(_projectManagerFilterOptions => {
            this.projectManagerFilterOptions = _projectManagerFilterOptions;
            // if (this.projectManagerFilterOptions.length) {
            //    this.assignSelectedPM();
            // }
            // return this.getProjectListSummary();
            return this.accrualsDashboardService.getCurrentUser();
          }),
          concatMap(currentUser => {
            this.currentUser = currentUser;
            return this.assignSelectedPM();
          }),
          concatMap(selectedPM => {
            this.selectedPM = selectedPM;
            return this.getProjectListSummary();
          }),
          concatMap(projectSummary => {
            this.setProjectListSummary(projectSummary);
            return this.getYearlyRevenueDetails();
          })
        )
        .subscribe(this.setYearlyRevenueDetails.bind(this), error => {
          bootstrapNotify(
            "Error in loading the report.\n Please retry",
            "error"
          );
        });
      // this.init$ ? this.init$.unsubscribe() : "";
      // this.projectManagerFilterOptions = await this.accrualsDashboardService
      //   .getProjectManagersList()
      //   .subscribe(_projectManagerFilterOptions => {
      //     return _projectManagerFilterOptions;
      //   });
      // await this.assignSelectedPM();
      // await this.getProjectListSummary().subscribe(projectSummary => {
      //   this.setProjectListSummary(projectSummary);
      // });
      // this.getYearlyRevenueDetails().subscribe(
      //   this.setYearlyRevenueDetails.bind(this),
      //   error => {
      //     bootstrapNotify(
      //       "Error in loading the report.\n Please retry",
      //       "error"
      //     );
      //   }
      // );
    },
    queryStringInit() {
      this.init$ ? this.init$.unsubscribe() : "";
      this.init$ = this.accrualsDashboardService
        .getProjectManagersList()
        .pipe(
          concatMap(PMList => {
            this.projectManagerFilterOptions = PMList.body;
            return this.getProjectListSummary();
          }),
          concatMap(projectSummary => {
            this.setProjectListSummary(projectSummary);
            return this.getYearlyRevenueDetails();
          })
        )
        .subscribe(this.setYearlyRevenueDetails.bind(this), error => {
          bootstrapNotify(
            "Error in loading the report.\n Please retry",
            "error"
          );
        });
    },
    getProjectListSummary() {
      const date = formatDateObject(this.selectedMonth, "/");
      return this.accrualsDashboardService.getProjectSummaryData(
        this.selectedPM,
        date
      );
    },
    setProjectListSummary(projectDetails) {
      this.projectSummaryData = [];
      this.allProjectSummaryData = projectDetails;
      let data = this.allProjectSummaryData.slice(0, 3);
      if (this.allProjectSummaryData.length > 3) {
        data.push({}, {}, {});
      }
      this.projectSummaryData = data;
    },
    getYearlyRevenueDetails() {
      return this.accrualsDashboardService.getYearlyRevenueData(
        "01/01/" + this.selectedYear.getFullYear(),
        this.selectedProjectType.id,
        this.selectedPM.id
      );
    },
    setYearlyRevenueDetails(revenueDetails) {
      for (var i = 0; i < revenueDetails.length; i++) {
        revenueDetails[i].month = moment(
          new Date(revenueDetails[i].month + ' 00:00:00')
        ).format("MMM");
      }
      this.yearlyRevenueChartData = revenueDetails;
    },
    loadProjectSummaryData() {
      this.slideNo = 1;
      if (this.swiper) {
        this.swiper.slideTo(0, 0);
      }
      this.projectSummaryData$ ? this.projectSummaryData$.unsubscribe() : "";
      this.projectSummaryData$ = this.getProjectListSummary().subscribe(
        this.setProjectListSummary.bind(this),
        error => {
          bootstrapNotify(
            "Error in loading the report.\n Please retry",
            "error"
          );
        }
      );
    },
    loadYearlyRevenueData() {
      this.yearlyrevenueData$ ? this.yearlyrevenueData$.unsubscribe() : "";
      this.yearlyrevenueData$ = this.getYearlyRevenueDetails().subscribe(
        this.setYearlyRevenueDetails.bind(this),
        error => {
          bootstrapNotify(
            "Error in loading the report.\n Please retry",
            "error"
          );
        }
      );
    },
    loadReport() {
      this.slideNo = 1;
      if (this.swiper) {
        this.swiper.slideTo(0, 0);
      }
      this.reportData$ ? this.reportData$.unsubscribe() : "";
      this.reportData$ = this.getProjectListSummary()
        .pipe(
          concatMap(res => {
            this.setProjectListSummary(res);
            return this.getYearlyRevenueDetails();
          })
        )
        .subscribe(this.setYearlyRevenueDetails.bind(this), error => {
          bootstrapNotify(
            "Error in loading the report.\n Please retry",
            "error"
          );
        });
    },
    copyAccrualsDashboardUrl(event) {
      if (event) event.preventDefault();
      var selectedMonth = new Date(this.selectedMonth.getTime());
      var selectedYear = new Date(this.selectedYear.getTime());

      const query = {
        selectedPM: JSON.stringify(this.selectedPM),
        selectedMonth: formatDateObject(selectedMonth, "/"),
        selectedProjectType: JSON.stringify(this.selectedProjectType),
        selectedYear: formatDateObject(selectedYear, "/")
      };

      const stringified = QueryStringWrapper.stringify(query);
      copyToClipboard(
        window.location.origin + window.location.pathname + "?" + stringified
      );
    },
    getCardView() {
      this.slideNo = 1;
      this.projectListData = [];
      this.paginationConfig.totalNoOfProjects = null;
      this.isCardLayout = true;
      this.defaultInit();
    },
    getListView(params = null) {
      this.accrualsDashboardService.projectListDataLoading.subscribe(
        response => (this.projectListDataLoading = response)
      );
      this.loadingList = true;
      this.oldfilterParams = params;
      this.projectSummaryData = [];
      this.isCardLayout = false;
      if (!params) {
        this.filterParam = {};
      }
      let filterParams = this.checkListViewParams(params);

      this.accrualsDashboardService
        .getProjectListData(
          this.selectedPM,
          this.paginationConfig.currentPageNum,
          this.paginationConfig.itemsPerPage,
          filterParams
        )
        .subscribe(response => {
          response.results.forEach((item, index) => {
            if (this.currentUser.permissions["AccrualPolicy"] == "modify") {
              response.results[index].permissions = true;
            } else if (
              this.currentUser.permissions["AccrualPolicy"] == "write" &&
              this.currentUser.name === item.name
            ) {
              response.results[index].permissions = true;
            } else {
              response.results[index].permissions = false;
            }
          });
          this.loadingList = false;
          this.projectListData = response.results;
          this.paginationConfig.totalNoOfProjects = response.count;
        });
    },
    updateAccrualStatus(accrualId, accrualStatus) {
      this.ProjectAccrualCreateService.updateProjectAccrual(accrualId, {
        active: accrualStatus
      }).subscribe(
        response => {
          let index = this.projectListData.findIndex(item => {
            if (item.id == accrualId) {
              return this.projectListData.indexOf(item.id);
            }
          });
          this.projectListData[index].active = accrualStatus;
          bootstrapNotify(
            "Project Accrual status updated successfully.",
            "success"
          );
        },
        error => {
          bootstrapNotify(
            "Failed to update Project Accrual status." + error,
            "error"
          );
        }
      );
    },
    checkListViewParams(params) {
      if (params) {
        if (params.search) {
          if (params.search.hasOwnProperty("active")) {
            if (params.search.active == "all") {
              delete this.filterParam.active_eq;
            } else {
              this.filterParam.active_eq = params.search.active;
            }
          }
          if (params.search.hasOwnProperty("name")) {
            this.filterParam.name_cont = params.search.name;
          }
          if (params.search.hasOwnProperty("account_manager_name")) {
            this.filterParam.account_manager_id_eq =
              params.search.account_manager_name;
          }
          if (params.search.hasOwnProperty("project_manager_name")) {
            this.filterParam.project_manager_id_eq =
              params.search.project_manager_name;
          }
          if (params.search.hasOwnProperty("jira_code")) {
            this.filterParam.jira_code_cont = params.search.jira_code;
          }
        }
        if (
          params.sort &&
          params.sort.hasOwnProperty("sort_order") &&
          params.sort.hasOwnProperty("col_name")
        ) {
          this.filterParam.s =
            params.sort.col_name + " " + params.sort.sort_order;
        }
      }
      return this.filterParam;
    }
  },
  beforeDestroy() {
    this.reportData$ ? this.reportData$.unsubscribe() : "";
    this.yearlyrevenueData$ ? this.yearlyrevenueData$.unsubscribe() : "";
    this.projectSummaryData$ ? this.projectSummaryData$.unsubscribe() : "";
    this.init$ ? this.init$.unsubscribe() : "";
  }
};

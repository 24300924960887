import Vue from 'vue/dist/vue.esm';
const EventBus = new Vue();

export default EventBus;

/*

SENDING EVENTS:
// Import the EventBus.
import { EventBus } from './event-bus.js';
// Send the event on a channel (i-got-clicked) with a payload (the click count.)
EventBus.$emit('i-got-clicked', this.clickCount);


RECEIVING EVENTS:
// Import the EventBus.
import { EventBus } from './event-bus.js';

// Listen for the i-got-clicked event and its payload.
EventBus.$on('i-got-clicked', clickCount => {
  console.log(`Oh, that's nice. It's gotten ${clickCount} clicks! :)`)
});

NOTE:
1. If you’d only like to listen for the first emission of an event,
you can use EventBus.$once(channel: string, callback(payload1,…)).


REMOVING EVENT LISTENERS:
// Import the EventBus we just created.
import { EventBus } from './event-bus.js';

// The event handler function.
const clickHandler = function(clickCount) {
  console.log(`Oh, that's nice. It's gotten ${clickCount} clicks! :)`)
}

// Listen to the event.
EventBus.$on('i-got-clicked', clickHandler);

// Stop listening.
EventBus.$off('i-got-clicked', clickHandler);

NOTE:
1. You could also remove all listeners for a particular event using EventBus.$off(‘i-got-clicked’) with no callback argument.
2. If you really need to remove every single listener from EventBus, regardless of channel, you can call EventBus.$off() with no arguments at all.

*/
import BurnUpChart from '../../burn-report/project-burn-report/burn-up-chart/BurnUpChart.vue'
import DailyBurnChart from '../../burn-report/project-burn-report/daily-burn-chart/DailyBurnChart.vue'

export default {
  name: 'burn-report-panel',
  props: ['startDate', 'endDate','project_id'],
  components: {BurnUpChart, DailyBurnChart},
  mounted() {
    this.loadProjectBurnData();
    // reload project burn report panel to fetch the latest information
    this.$root.$on('updateDashboard', () => {
      this.loadProjectBurnData();
    })
  },
  data() {
    return {
      recurrence                  : 'weekly',
      loading                     : false,
      selected_project            : this.project_id,
      lifetime_planned_vs_logged  : [],
      show_dollar_value           : false,
      show_lifetime               : false
    }
  },
  computed: {
    selected_date_range: {
      get: function() {
        var vm = this;
        return [vm.startDate, vm.endDate];
      },
      set: function() {
      }
    },
    activeTabName() {
      if ($('#m_portlet_tab_2_1').hasClass('active'))
        return 'burn_up_chart'
      else
        return'daily_burn_chart'
    }
  },
  methods: {
    // Fetches all the data needed for the detailed project burn report of a project(timeline chart data, burn report per talent, widget data, adjusted date range).
    loadProjectBurnData: function () {
      if (this.selected_date_range !== null){
        var vm     = this;
        if(vm.selected_project == ''){return};
        vm.loading = true;
        this.$http.get('/api/v1/project_burn_report/'+vm.selected_project, {
          params: {
            start_date      : vm.selected_date_range[0].toLocaleDateString('en-US').split("/").join("-"),
            end_date        : vm.selected_date_range[1].toLocaleDateString('en-US').split("/").join("-"),
            type            : (vm.show_dollar_value == true) ? "dollar" : "hour",
            recurrence_type :  vm.recurrence
          }
        })
        .then(function(response) {
          var response_body                 = response.body.body;
          this.lifetime_planned_vs_logged   = response_body.lifetime_planned_vs_logged;
          this.selected_date_range          = [new Date(response_body.recurrence_date_range[0]), new Date(response_body.recurrence_date_range[1])];
          // pass the lifetime range for lifetime filter data attribute in dashbaord component
          this.$parent.projectLifetimeRange = this.get_max_min_range();
          if (this.show_lifetime === true){
            if (this.lifetime_planned_vs_logged.length > 0){
              this.selected_date_range = this.get_max_min_range();
            }
          }
          vm.loading = false;
        });
      }
    },
    get_max_min_range(){
      return [ new Date(this.lifetime_planned_vs_logged.reduce((min, pbpd) => Date.parse(pbpd.date) < min ? Date.parse(pbpd.date) : min, Date.parse(this.lifetime_planned_vs_logged[0].date))),
        new Date(this.lifetime_planned_vs_logged.reduce((max, pbpd) => Date.parse(pbpd.date) > max ? Date.parse(pbpd.date) : max, Date.parse(this.lifetime_planned_vs_logged[0].date))),
      ]
    }
  }
}
export default {
  name: 'accruals-summary-widget',
  props: ['project_id', 'total_hours', 'total_accrual'],
  mounted() {
  	this.loadAccrualStatus();
    // reload accruals summary panel when resource summary is updated to fetch the latest information
    this.$root.$on('updateDashboard', () => {
      this.loadAccrualStatus();
    });
  },
  data() {
    return {
    	status: ''
    }
  },
  methods: {
    loadAccrualStatus() {
      this.$http.get('/api/v1/project_plans/' + this.project_id + '/accruals/status')
      .then(function(response){
      	if (typeof response.body.body.accrual_status == 'string')
	        this.status = parseInt(response.body.body.accrual_status);
	      else
	      	this.status = '';
      });
    },
    prettifyNumber(value) {
      var thousand  = 1000;
      var million   = 1000000;
      var billion   = 1000000000;
      var trillion  = 1000000000000;
      if (value < thousand) {
          return String(value);
      }
      if (value >= thousand && value <= 1000000) {
           return  Math.round(value/thousand) + 'k';
      }
      if (value >= million && value <= billion) {
          return Math.round(value/million) + 'M';
      }
      if (value >= billion && value <= trillion) {
          return Math.round(value/billion) + 'B';
      }
      else {
          return Math.round(value/trillion) + 'T';
      }
    }
  }
}
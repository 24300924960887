import "./table-wide.css"
import Vue from 'vue/dist/vue.esm'

Vue.component('table-wide', {

  props: ['headerRow', 'rows', 'fixedColumnHeader', 'unfixedColumns', 'capacity_indicator'],

  computed: {
    showBlankRow() {
      return !Object.keys(this.rows).length;
    },
    totalRow(){
      var summary = {};
      for(var row in this.rows) {
        for(var date in this.rows[row]) {
          summary[date] = summary[date] || 0;
          summary[date] += Number(this.rows[row][date]);
        }
      }
      return summary;
    }

  },

  data() {
    return{
      forecastTableMargin: (this.unfixedColumns ? '0px' : '')
    }
  },

  methods: {
    checkForZeros: function(hours_value) {
      return(hours_value.toFixed(1) == 0.0 ? 'is-zero' : '')
    },
    totalRowClass: function(headerElement){
      // console.log(headerElement);
      if (this.capacity_indicator && this.capacity_indicator[headerElement]){
        return this.capacity_indicator[headerElement]
      }
      return ''
    }
  },

  template: `
    <div class="table-container table-responsive forecast_table_container">
      <div class="forecast_table forecast-table"  v-bind:style="{marginLeft: forecastTableMargin} ">
        <table class="table table-bordered table-hover table-striped m-table">
          <thead>
            <tr>
              <th v-bind:class="[unfixedColumns ? 'col' : 'frozen-col']">{{fixedColumnHeader}}</th>
              <th v-for="headerElement in headerRow">
                <span>{{headerElement}}</span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="row, fixedHeading in rows">
              <th v-bind:class="[unfixedColumns ? 'col' : 'frozen-col']">
                <span v-if="fixedHeading == 'Holidays & Vacations'">
                  {{fixedHeading}}
                </span>
                <span v-else>
                  <a :href="row.url" target="_blank">
                    {{fixedHeading}}
                  </a>
                </span>
              </th>
              <td v-for="headerElement in headerRow" :class="checkForZeros(row[headerElement])"> {{row[headerElement].toFixed(1)}} </td>
            </tr>
            <tr v-if="Object.keys(this.rows).length !== 0 ">
              <th v-bind:class="[unfixedColumns ? 'col' : 'frozen-col']">Total</th>
              <th v-for="headerElement in headerRow" :class="totalRowClass(headerElement)"> {{Number(totalRow[headerElement]).toFixed(1)}}</th>
            </tr>
            <tr v-if="showBlankRow">
              <th v-bind:class="[unfixedColumns ? 'col' : 'frozen-col']" style="color:white;">-</th>
              <td class="center" :colspan="headerRow.length+1">No records to show.</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  `
 });

//// Commented this out as this component inherits from the main vue component & we dont need to specify an element for it.
// new Vue({
//   el: '#container-main'
//   // el: '#chart-container'
// })
import { render, staticRenderFns } from "./notes-modal.vue?vue&type=template&id=492ca63e&scoped=true&"
import script from "./notes-modal.js?vue&type=script&lang=js&"
export * from "./notes-modal.js?vue&type=script&lang=js&"
import style0 from "./notes-modal.css?vue&type=style&index=0&id=492ca63e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "492ca63e",
  null
  
)

export default component.exports
import layeredColumnChart from '../../../../../components/layered-column-chart/layered-column-chart'
import {
  swiper,
  swiperSlide
} from 'vue-awesome-swiper'

import EventBus from '../../../../../finance/accruals/project-accrual-report/global-event-bus'
import 'swiper/dist/css/swiper.css'

export default {
  name: 'project-list-widget',
  components: {
    layeredColumnChart,
    swiper,
    swiperSlide
  },
  data: function () {
    return {
      swiperOption: {
        slidesPerView: 3,
        spaceBetween: 0,
        slidesPerGroup: 3,
        // lazy: true,
        // preloadImages: false,
        // watchSlidesVisibility: true,
        // pagination: {
        //   el: '.swiper-pagination',
        //   clickable: true,
        //   // dynamicBullets: false,
        // },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      },
      dataAvailable: true,
    };
  },
  props: {
    projectSummaryData: {
      type: Array,
      required: true
    },
    projectSummaryChartSeries: {
      type: Array,
      required: true
    },
    projectSummaryChartData: {
      type: Array,
      required: true
    }
  },

  mounted() {
    // setInterval(() => {
    //   console.log('simulate async data')
    //   if (this.projectSummaryData.length < 10) {
    //     this.projectSummaryData.push(this.projectSummaryData.length + 1)
    //   }
    // }, 3000)
  },
  watch: {
    projectSummaryData(val) {
      this.projectSummaryData = val;
      this.projectSummaryData.forEach(item => {
        item.project_summary = item.project_summary ? this.formatChatData(item.project_summary) : [];
      });
      // this.$refs.mySwiper.swiper.slideTo(0, 0)
      if (!this.projectSummaryData.length) {
        this.dataAvailable = false;
      } else {
        this.dataAvailable = true;
      }
    },
    projectSummaryChartSeries(val) {
      this.projectSummaryChartSeries = val;
    },
    projectSummaryChartData(val) {
      this.projectSummaryChartData = val;
    },
  },
  methods: {
    formatChatData(data) {
      data.forEach(item => {
        item.month = moment(item.month, 'YYYY-MM-DD').format('MMM')
      })
      return data;
    },
    onNext() {
      EventBus.$emit('paginateProjectList', true, this.$refs.mySwiper.swiper)
    },
    onPrev() {
      EventBus.$emit('paginateProjectList', false)
    }
  },
}
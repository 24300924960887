import { render, staticRenderFns } from "./BurnUpChart.vue?vue&type=template&id=c4acce2e&"
import script from "./burn-up-chart.js?vue&type=script&lang=js&"
export * from "./burn-up-chart.js?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
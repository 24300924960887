import ProjectAccrualCreateService from './project-accrual-create-service';
import {
  concatMap
} from "rxjs/operators";
import {
  formatDateObject
} from '../../../utils/utils';
// import EventBus from '../project-accrual-report/global-event-bus';

export default {
  name: 'project-accrual-create',
  // components: {
  //   pmSelectFilter,
  //   dateRangePicker
  // },
  props: {
    open: {
      type: Boolean,
      required: true,
      default: false
    },
    accrualType: '',
    projectId: null,
    currentUserId: ''
  },
  data() {
    var vm = this;
    var isUniqueName = (rule, value, callback) => {
      let data = {
        'name': value,
        'id': this.projectId,
      }
      //just for example not need to replace it which API call for checking uniqueness
      this.ProjectAccrualCreateService.checkDataIsUnique(data).subscribe((response) => {
        if (response.status === 400) {
          return callback(new Error('Name is already taken'));
        }
        callback();
      }, error => {
        callback(new Error('Something went wrong, please try again!'));
      })
      // if (value.length < 5) {
      //   return callback(new Error('Name should containe more then 5 characters'));
      // }
    };
    var isUniqueCode = (rule, value, callback) => {
      //just for example need to replace it with API call for checking with uniqueness
      let data = {
        'jira_code': value,
        'id': this.projectId,
      }
      //just for example not need to replace it which API call for checking uniqueness
      this.ProjectAccrualCreateService.checkDataIsUnique(data).subscribe((response) => {
        if (response.status === 400) {
          return callback(new Error('Jira code is already taken'));
        }
        callback();

      }, error => {
        callback(new Error('Something went wrong, please try again!'))
      })
    };
    var isValidEndDate = (rule, value, callback) => {
      setTimeout(() => {
        //Need to optimize the code by shiva
        let start_date = vm.projectAccrualFormData.start_date;
        if (typeof (vm.projectAccrualFormData.start_date) === 'string') {
          start_date = new Date(vm.projectAccrualFormData.start_date)
        }
        if (value && value < start_date) {
          return callback(new Error('End date should be greater then Start date!'));
        }
        if (value && vm.prevEndDate && moment(value).isBefore(new Date(vm.prevEndDate), 'day')) {
          return callback(new Error('End date should not be less than previous end date!'))
        }
        callback();
      }, 200);
    };
    var isValidStartDate = (rule, value, callback) => {
      setTimeout(() => {
        let end_date = vm.projectAccrualFormData.end_date;
        if (end_date) {
          vm.$refs.projectAccrualForm.validateField("end_date")
        }
        // let end_date = vm.projectAccrualFormData.end_date;
        // if (end_date && typeof (vm.projectAccrualFormData.end_date) === 'string') {
        //   end_date = new Date(vm.projectAccrualFormData.end_date)
        // }
        // if (end_date && value > end_date) {
        //   return callback(new Error('Start date should be less then End date!'));
        // }
        // else if(end_date &&  ){
        // }
        callback();
      }, 200);
    };
    // var validateDate = (rule, value, callback) => {
    //   setTimeout(() => {
    //     console.log("validating  date", this);
    //     let end_date = vm.projectAccrualFormData.end_date;
    //     let start_date = vm.projectAccrualFormData.start_date;
    //     if (end_date && typeof (vm.projectAccrualFormData.end_date) === 'string') {
    //       end_date = new Date(vm.projectAccrualFormData.end_date)
    //     }
    //     if (typeof (vm.projectAccrualFormData.start_date) === 'string') {
    //       start_date = new Date(vm.projectAccrualFormData.start_date)
    //     }
    //     if (start_date & end_date) {
    //       if (start_date > end_date) {
    //         console.log("validating start Date")
    //         vm.$refs.projectAccrualForm.validateField("start_date")
    //         return callback(new Error('End date should be less then Start date!'));
    //       }
    //       if (end_date < start_date) {
    //         console.log("validating end date")
    //         vm.$refs.projectAccrualForm.validateField("end_date")
    //         return callback(new Error('Start date should be less then End date!'));
    //       }
    //     }
    //     callback();
    //   }, 200);
    // }
    return {
      ProjectAccrualCreateService: ProjectAccrualCreateService,
      dialogFormVisible: false,
      employeeSelectOptions: [],
      projectPlanSelectOptions: [],
      projectSelectOptions: [],
      companyDeptOptions: [],
      projectKeyList: [],
      loading: false,
      syncButtonHtml: `<span>get updated list </span`,
      prevEndDate: null,
      contractTypeSelectOptions: [{
          id: 1,
          name: 'Fixed Price'
        },
        {
          id: 0,
          name: 'Time And Material'
        },
        {
          id: 2,
          name: 'Retainer'
        },
      ],
      projectAccrualFormData: {
        id: null,
        name: '',
        start_date: null,
        end_date: null,
        project_manager_id: null,
        account_manager_id: null,
        contract_type: null,
        jira_code: '',
        company_department_id: null,
        estimate_id: null,
        budgeted_revenue: null
      },
      // this configuration disables all the dates in edit mode, which are before previous end date
      endDatePickerOptions: {
        disabledDate(date) {
          if (vm.prevEndDate) {
            return date < moment(new Date(vm.prevEndDate)).subtract(1, 'days')
          }
        }
      },
      projectAccrualFormRules: {
        name: [{
            required: true,
            message: 'Please enter Project Accrual Name',
            trigger: 'blur'
          },
          {
            max: 50,
            message: 'Project Name should be less than 50 characters',
            trigger: 'blur'
          },
          {
            validator: isUniqueName,
            trigger: 'blur'
          }
        ],
        contract_type: [{
          required: true,
          message: 'Please select Contract Type',
          trigger: 'change'
        }],
        project_manager_id: [{
          required: true,
          message: 'Please select Project Manager',
          trigger: 'change'
        }],
        account_manager_id: [{
          required: true,
          message: 'Please select Account Manager',
          trigger: 'change'
        }],
        // estimate_id: [{
        //   required: true,
        //   message: 'Please select Project',
        //   trigger: 'change'
        // }],
        jira_code: [{
          required: true,
          message: 'Please select Jira Code',
          trigger: 'change'
        }, {
          validator: isUniqueCode,
          trigger: 'change'
        }],
        company_department_id: [{
          required: true,
          message: 'Please select Company Department',
          trigger: 'change'
        }],
        budgeted_revenue: [{
          required: true,
          message: 'Please enter Budgeted revenue',
          trigger: 'change'
        }
      ],
        // contract_months: [{
        //   required: true,
        //   message: 'Please Enter Contract Months'
        // }],
        start_date: [{
            required: true,
            message: 'Please select Start Date',
          },
          {
            validator: isValidStartDate,
            trigger: 'blur'
          }
        ],
        end_date: [{
          required: true,
          message: 'Please select End Date',
        }, {
          validator: isValidEndDate,
          trigger: 'blur'
        }],
      },
      formLabelWidth: '150px'
    };
  },
  watch: {
    open(value) {
      this.dialogFormVisible = value;
    },
    currentUserId(value) {
      if (this.accrualType === "add") {
        this.projectAccrualFormData.project_manager_id = this.projectAccrualFormData.account_manager_id = value;
      }
    }
    // accrualData(newValue) {
    //   console.log('updated accrual data => ', newValue);
    //   this.projectAccrualFormData = newValue;
    //   console.log('edit form data=> ', this.projectAccrualFormData);
    // }
  },
  computed: {
    pageTitle() {
      return this.accrualType ? this.accrualType === 'add' ? "Create Project Accrual" : "Edit Project Accrual" : '';
    },
    dateDisable() {
      return this.accrualType ? this.accrualType === 'add' && this.projectAccrualFormData.estimate_id ? true : false : false;
    },
    disableField() {
      return this.accrualType ? this.accrualType === 'edit' ? true : false : false;
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      // this.employeeSelectOptions = this.ProjectAccrualCreateService.getEmployeeFilterOptions();
      // this.projectPlanSelectOptions = this.ProjectAccrualCreateService.getProjectPlanFilterOptions();
      // this.projectSelectOptions = this.ProjectAccrualCreateService.getProjectFilterOptions();
      if (this.accrualType === "edit") {
        this.ProjectAccrualCreateService.getProjectAccrualInfo(this.projectId).subscribe((response) => {
          this.projectAccrualFormData = response;
          this.prevEndDate = response.end_date
        });
      }

      this.init$ = this.ProjectAccrualCreateService
        .getEmployeeFilterOptions()
        .pipe(
          concatMap(employeeSelectOptions => {
            this.employeeSelectOptions = employeeSelectOptions;
            return this.ProjectAccrualCreateService.getProjectPlanFilterOptions();
          }),
          concatMap(projectPlanSelectOptions => {
            this.projectPlanSelectOptions = projectPlanSelectOptions;
            // return this.ProjectAccrualCreateService.getProjectFilterOptions();
            return this.ProjectAccrualCreateService.getJiraProjectCodes();
          }),
          concatMap(projectKeyList => {
            this.projectKeyList = projectKeyList;
            return this.ProjectAccrualCreateService.getCompanyDepartmentOptions();
          }),
        )
        .subscribe(
          companyDeptOptions => {
            return this.companyDeptOptions = companyDeptOptions;
          },
          error => {
            bootstrapNotify('Error in loading filter options.', 'error');
          }
        );
    },
    setCharAt(str, index, chr) {
      if (index > str.length - 1) return str;
      return str.substr(0, index) + chr + str.substr(index + 1);
    },
    checkForMaxValue(value, event) {
      if (!parseFloat(event.target.value)) {
        event.target.value = "0";
      }
      let fullNum = event.target.value.toString().split(".")[0];
      let isValidNum = (/^[0-9]{0,10}$/).test(parseFloat(fullNum))
      if (!isValidNum && event.target.value.includes(".")) {
        event.target.value = this.setCharAt(event.target.value, event.target.value.indexOf(".") - 1, "")
      } else if (!isValidNum && !event.target.value.includes(".")) {
        event.target.value = event.target.value.slice(0, 10)
      }
    },
    syncJiraProjectCodes() {
      this.syncButtonHtml = `<i class = "fa fa-spinner fa-spin"></i><span> Fetching projects</span>`;
      this.ProjectAccrualCreateService.getJiraProjects().subscribe((response) => {
          this.syncButtonHtml = `<span>get updated list </span>`;
          this.projectKeyList = response;
          bootstrapNotify('Jira sync completed.', 'success');
        },
        error => {
          this.syncButtonHtml = `<span>get updated list </span>`;
          bootstrapNotify(response.bodyText, 'error');
        }
      );
    },
    createProjectAccrual() {
      // this.$refs.projectAccrualForm.validateField("start_date");
      // this.$refs.projectAccrualForm.validateField("end_date")
      this.$refs['projectAccrualForm'].validate((valid) => {
        if (valid) {
          this.projectAccrualFormData.budgeted_revenue = parseFloat(this.projectAccrualFormData.budgeted_revenue.replace(/[$,]/gi, ''))
          this.projectAccrualFormData.end_date = moment(this.projectAccrualFormData.end_date).format("MM-DD-YYYY") //formatDateObject(this.projectAccrualFormData.end_date, );
          this.projectAccrualFormData.start_date = moment(this.projectAccrualFormData.start_date).format("MM-DD-YYYY") //formatDateObject(this.projectAccÏrualFormData.start_date, '-');
          if (this.accrualType === "add") {
            this.ProjectAccrualCreateService.createProjectAccrual(this.projectAccrualFormData)
              .subscribe(
                response => {
                  bootstrapNotify('Project Accrual created successfully.', 'success');
                  this.$refs['projectAccrualForm'].resetFields();
                  this.dialogFormVisible = false;
                  this.$router.push({
                    path: `/project/${response.json.project_accrual_id}`
                  });
                },
                error => {
                  bootstrapNotify('Failed to create Project Accrual.' + error, 'error');
                }
              );
          }
          if (this.accrualType === "edit") {
            this.loading = true;
            if (typeof (this.projectAccrualFormData.contract_type) === "string") {
              let contract_type = this.contractTypeSelectOptions.filter((item) => item.name.toLowerCase() === this.projectAccrualFormData.contract_type.toLowerCase())[0];
              this.projectAccrualFormData.contract_type = contract_type.id;
            }
            this.ProjectAccrualCreateService.updateProjectAccrual(this.projectId, this.projectAccrualFormData)
              .subscribe(
                response => {

                  this.loading = false;
                  this.dialogFormVisible = false;
                  bootstrapNotify('Project Accrual updated successfully.', 'success');
                  this.$router.go();
                },
                error => {
                  bootstrapNotify('Failed to update Project Accrual.' + error, 'error');
                }
              );
            // EventBus.$emit('updateProjectSummary', null);
          }

        } else {
          return false;
        }
      });
    },
    resetProjectAccrualForm() {
      this.$refs['projectAccrualForm'].resetFields();
      this.dialogFormVisible = false;
      this.$emit('update:open', this.dialogFormVisible);
    },
    getProjectDates() {
      this.ProjectAccrualCreateService.getProjectPlanDates(this.projectAccrualFormData.estimate_id).subscribe(response => {
          console.log(' project dates =>', response);
          // this.projectId = '';
          this.projectAccrualFormData.start_date = response.start_date;
          this.projectAccrualFormData.end_date = response.end_date;
        },
        error => {
          bootstrapNotify('Error in fetching Project Plan dates.', 'error');
        });
    }
  }
}
// import Vue from 'vue/dist/vue.esm'
// import Gantt from './gantt/Gantt.vue'
import router from './gantt-chart-router.js'

import Vue from 'vue/dist/vue.esm'
import vSelect from 'vue-select'
import VueResource from 'vue-resource'; // HTTP client for Vue.js
// import ElementUI from 'element-ui';
// import 'element-ui/lib/theme-chalk/index.css';
import "handsontable/dist/handsontable.full.css"
import DataTables from 'vue-data-tables'

// Vue.use(ElementUI, { size: 'medium', locale });
Vue.use(DataTables)

// //L10N (show English in pagination)
// import lang from 'element-ui/lib/locale/lang/en'
// import locale from 'element-ui/lib/locale'
// locale.use(lang)

Vue.component('v-select', vSelect)
Vue.use(VueResource);

// importing the directive to bypass the error -> Failed to resolve directive: popover
import directive from 'element-ui/packages/popover/src/directive';
Vue.directive('popover', directive);

var ganttChartElement = document.getElementById("gantt-chart");
if (ganttChartElement != null){
  new Vue({
    el: '#gantt-chart',
    router
  });
}
import Vue from 'vue/dist/vue.esm'
import Router from 'vue-router'
import ProjectBurnSummary from './project-burn-summary/ProjectBurnSummary'
import ProjectBurnReport from './project-burn-report/ProjectBurnReport'

Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/',
      name: 'ProjectBurnSummary',
      component: ProjectBurnSummary
    },
    {
      path: '/:project_id',
      name: 'ProjectBurnReport',
      component: ProjectBurnReport,
      props: true 
    }
  ]
})
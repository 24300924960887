import {
  Observable
} from "rxjs";
import XLSX from 'xlsx';
import moment from 'moment';

function copyToClipboard(str) {
  const el = document.createElement('textarea');
  el.value = str;
  el.setAttribute('readonly', '');
  el.style.position = 'absolute';
  el.style.left = '-9999px';
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
  bootstrapNotify('Copied', 'success');
}

function dummyObservable(response = {}) {
  const observable = Observable.create((observer) => {
    observer.next(response);
    observer.complete(response);
  });
  return observable;
}

function formatDateObject(date, separator) {
  let dd = date.getDate();
  let mm = date.getMonth() + 1;
  let yyyy = date.getFullYear();
  if (dd < 10) {
    dd = '0' + dd;
  }
  if (mm < 10) {
    mm = '0' + mm;
  }
  return `${mm}${separator}${dd}${separator}${yyyy}`;
}

function array_of_objects_sum(array, key) {
  if (array.length == 0) {
    return 0;
  } else if (array.length == 1) {
    return array[0][key];
  } else {
    return array.reduce((accumulator, currentValue) => parseInt(currentValue.value) + accumulator, 0);
  }
}

/**
 * Function to deep copy Object/Array
 * Input : value
 * Output Deep Copied value
 * Usage : import { clone } from 'path/to/utils.js'
 *         clone();
 */
function clone(value) {
  var output, temp, key;
  output = Array.isArray(value) ? [] : {};
  for (key in value) {
    temp = value[key];
    output[key] = (typeof temp === "object" || Array.isArray(temp)) ? clone(temp) : temp;
  }
  return output;
}

function formatTime(date) {
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0' + minutes : minutes;
  var strTime = hours + ':' + minutes + '-' + ampm;
  return date.getMonth() + 1 + "-" + date.getDate() + "-" + date.getFullYear() + "-" + strTime;
}

function exportXLSXFromJSON(data, filename) {
  /* generate workbook object from JSON object */
  // var wb = XLSX.utils.table_to_book(this.$refs.uniqueTable.$el);
  const ws = XLSX.utils.json_to_sheet(data);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");
  /* generate file and force a download*/
  XLSX.writeFile(wb, filename + ".xlsx");
}

function exportXLSXFromTable(tableElement, filename) {
  var workbook = XLSX.utils.book_new();
  var ws = XLSX.utils.table_to_sheet(tableElement);
  let wscols = [];

  Object.keys(ws).forEach(ele => {
    const matchedStr = ele.match(/-?\d+\.?\d*$/);
    if (matchedStr && matchedStr.length && matchedStr[0] === '1') {
      wscols.push({
        wpx: 70
      });
    }
  });

  ws['!cols'] = wscols;
  XLSX.utils.book_append_sheet(workbook, ws, "Sheet 1");

  // const wb = XLSX.utils.table_to_book(tableElement);
  XLSX.writeFile(workbook, filename + ".xlsx", {
    cellDates: true
  });
}

function stringCut(value, max) {
  let wordwise = true;
  if (!value) return '';

  max = parseInt(max, 10);
  if (!max) return value;
  if (value.length <= max) return value;

  value = value.substr(0, max);
  if (wordwise) {
    let lastspace = value.lastIndexOf(' ');
    if (lastspace !== -1) {
      //Also remove . and , so its gives a cleaner result.
      if (value.charAt(lastspace - 1) === '.' || value.charAt(lastspace - 1) === ',' || value.charAt(lastspace - 1) === '-') {
        lastspace = lastspace - 1;
      }
      value = value.substr(0, lastspace);
    }
  }

  return value + ('…');
}

function dateCompare(date1, date2) {
  date1 = moment(date1);
  date2 = moment(date2);
  if (moment(date1).isSame(date2)) {
    return '=';
  } else if (date1 > date2) {
    return '>';
  } else {
    return '<';
  }
}

function getFormattedCurrency(value) {
  value = parseFloat((Math.round(value * 100) / 100).toFixed(2));
  return "$" + value.toFixed(2).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")
}

function calculatePercentage(value, total, decimals) {
  if (!value) value = 0;
  if (!decimals) decimals = 0;

  value = (value / total) * 100;
  return (isNaN(value) ? 0 : value).toFixed(decimals) + '%';
}

export {
  copyToClipboard,
  dummyObservable,
  formatDateObject,
  exportXLSXFromJSON,
  exportXLSXFromTable,
  array_of_objects_sum,
  clone,
  formatTime,
  stringCut,
  dateCompare,
  getFormattedCurrency,
  calculatePercentage
}
export default {
  // It is recommended to provide a key with v-for whenever possible,
  // unless the iterated DOM content is simple, or you are intentionally relying on the default behavior for performance gains.
  // https://vuejs.org/v2/guide/list.html#key
  // <element-table-wide :tableData="formattedProjectForecast" :headers="projectForecastHeaders" :cellThreshold="threshold" :key="elementTableWideKey" :height="400"></element-table-wide>
  name: 'element-table-wide',
  props: {
    tableData: {
      default: [],
      type: Array
    },
    headers: {
      default: [],
      type: Array
    },
    cellThreshold: {
      default: null
    },
    height: {
      default: null
    },
    showSummary: {
      default: false
    },
    handleNameClick: {
      default: false
    }
  },
  data() {
    return {
      fixedColumns: [0],
      elementTableWideKey: 0,
      maxDefaultHeight: 400,
      fixedHeightSpan: 7
    }
  },
  filters: {
    capitalize: function (value) {
      if (!value) return ''
      value = value.toString()
      return value.charAt(0).toUpperCase() + value.slice(1)
    }
  },
  watch: {
    tableData(rows) {
      this.refresh();
    }
  },
  methods: {
    refresh() {
      let elTable = this.$children[0];
      console.log('wide table this =>', this);
      this.$nextTick(() => {
        let bodyAndHeaders = elTable.$children.filter(a => a.$vnode.tag.indexOf('ElTableBody') >= 0 || a.$vnode.tag.indexOf('ElTableHeader') >= 0)

        bodyAndHeaders.map(bodyOrHeader => {
          Object.keys(bodyOrHeader._computedWatchers).forEach(k => bodyOrHeader._computedWatchers[k].run())
          this.$nextTick(() => bodyOrHeader.$forceUpdate())
        })
      })
    },
    cellFontClass(value) {
      var classes = [];
      if(this.cellThreshold && typeof(value) == "number"){
        var parsedValue = parseFloat(value);
        switch(true){
          case (parsedValue == 0 || isNaN(parsedValue)):
            classes.push("is-zero");
            break;
          case (parsedValue > this.cellThreshold):
            classes.push("is-overutilized");
            break;
          default :
            classes.push("");
            break;
        }
      }
      return classes;
    },
    getSummaries(param){
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = 'Total';
          return;
        }
        const values = data.map(item => Number(item[column.property]));
        if (!values.every(value => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
        } else {
          sums[index] = 0;
        }
        sums[index] = sums[index].toFixed(1)
      });
      this.refresh();
      return sums;
    }
  },
  computed: {
    computedTableHeight: function () {
      if(this.height) return this.height;
      if (this.tableData.length > this.fixedHeightSpan)
        return this.maxDefaultHeight; // enable scrolling after few rows if no height given.
      else
        return null;
    }
  },
}
import QueryString from 'query-string';

class QueryStringWrapper {
  stringify(query) {
    return QueryString.stringify(query);
  }

  parse(query) {
    return QueryString.parse(query);
  }
}

export default new QueryStringWrapper();

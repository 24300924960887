import {
  CurrencyInput
} from 'vue-currency-input'

import LineChart from "../../../../../components/line-chart/line-chart.vue";
import ElSelectWrapper from "../../../../../components/el-select-wrapper/el-select-wrapper.vue";
import InvoiceEditDialog from "../invoice-edit-dialog/invoice-edit-dialog.vue";
import {
  array_of_objects_sum
} from "../../../../../utils/utils";
import {
  snapshotService
} from "../../services/snapshop-service";
import EventBus from "../../global-event-bus";
import {
  formatSnapshotName,
  strToDate,
  formatDateObj
} from "../../utils/utils";
import {
  formatDateObject,
  dateCompare
} from "../../../../../utils/utils";
import {
  concatMap
} from "rxjs/operators";
import {
  of
} from "rxjs";
import { exportXLSXFromJSON, formatTime, calculatePercentage } from '../../../../../utils/utils';
import { throttle } from 'throttle-debounce';

export default {
  name: "project-summary-panel",
  components: {
    ElSelectWrapper,
    LineChart,
    InvoiceEditDialog
  },
  filters: {
    formatSnapshotName: formatSnapshotName,
    strToDate: strToDate
  },
  data() {
    return {
      snapshotService: snapshotService,
      editInvoice: false,
      selectedWeek: "",
      nextWeekDate: "",
      editInvoiceObject: [],
      currentSnapshot: null,
      currentWeekData: [],
      slides: new Map(),
      projectId: 0,
      accrualId: 0,
      snapshotTableKey: 0,
      curRowId: 0,
      loading: false,
      initializeSlider: false,
      accrualDeleteVisible: false,
      slideClass: '',
      chartData: null,
      projectSummary: null,
      totalInvoiceSum: 0,
      selectedYear: ""
    };
  },
  created() {

    this.projectId =
      this.$route.params && this.$route.params ? this.$route.params.id : 0;
    this.fetchCurrentUserDetails();
    // EventBus.$on("selectedWeek", selectedWeek => {
    //   this.slides.clear();
    //   this.selectedWeek = selectedWeek;
    //   this.init();
    // });

    EventBus.$on('filterAccrualSnapshot', obj => {
      this.slides.clear();
      this.selectedYear = obj.year;
      this.selectedWeek = obj.weekDate;
      this.init();
    });

    EventBus.$on('sendSummaryPanel', projectSummary => {
      this.projectSummary = projectSummary;
    })

    // export logic for snapshot table
    EventBus.$on("emitExportSnapshot", this.ExportSnapshotData = throttle(500, true, () => {
      let exportArr = [];
      let revenCumu, totalInv, budgetRevn, allInvSum = 0;
      this.snapshotCurrentData[0].accruals.forEach(data => {
        revenCumu = this.getFormattedCurrency(data.revenueCummulative);
        totalInv = this.getFormattedCurrency(data.totalInvoice);
        allInvSum = this.getFormattedCurrency(data.invoiceCummulative);
        budgetRevn = this.getFormattedCurrency(this.projectSummary.budgeted_revenue);

          exportArr.push({
            'Month': data.interval_start,
            'Hours': data.hours,
            '% Completions': calculatePercentage((data.revn ? parseFloat(data.revn) : 0), this.snapshotCurrentData[0].accruals[this.snapshotCurrentData[0].accruals.length - 1].revenueCummulative, 2),
            'Revenue': data.revenue,
            'RevenueCummulative': revenCumu,
            'Invoice': totalInv
          });
      });
      exportArr.push({
        'Month': 'Total',
        'Hours': '',
        '% Completions': '',
        'Revenue': revenCumu,
        'RevenueCummulative': '',
        'Invoice': allInvSum
      });
      exportArr.push({
        'Month': 'Budgeted Revenue',
        'Hours': '',
        '% Completions': '',
        'Revenue': budgetRevn,
        'RevenueCummulative': '',
        'Invoice': ''
      });

      const time = formatTime(new Date());
      const file_name = "snapshot-of-" + this.projectSummary.project_name + "(" + time + ")";

      exportXLSXFromJSON(exportArr, file_name);
    }))
  },
  mounted() {
    // this.scrollToLatestMonth();
  },
  computed: {
    isFutureWeek() {
      this.nextWeekDate = moment().day(8).format('MM/DD/YYYY');
      // console.log('selected week', this.selectedWeek);
      // console.log('next date 2=>', this.nextWeekDate);
      return this.selectedWeek === this.nextWeekDate;
    },
    lineChartData() {
      return this.currentSnapshot ?
        this.formatLineChartData(this.currentSnapshot) : [];
    },
    snapshotCurrentData() {
      this.slides.size > 0 && this.slides.forEach(this.formatAccrualRow.bind(this));
      return this.currentWeekData ? this.currentWeekData : [];
    },
    updatePermission() {
      const permission = this.currentUser.permissions["AccrualPolicy"];
      if (this.currentUser && ["modify", "write"].includes(permission)) {
        return true;
      }
      return false;
    }
  },
  watch: {
    totalInvoiceSum(value) {
      this.clearFooterRowAndUpdateValues();
    },
  },
  methods: {
    refresh() {
      this.$nextTick(() => {
        if (this.$children[4]) {
          let tableBody = this.$children[4];

          let bodyHeadersFooter = tableBody.$children.filter(a => a.$vnode.tag.indexOf('ElTableBody') >= 0 || a.$vnode.tag.indexOf('ElTableHeader') >= 0 || a.$vnode.tag.indexOf('ElTableFooter') >= 0)

          bodyHeadersFooter.map(bodyOrHeaderOrFooter => {
            Object.keys(bodyOrHeaderOrFooter._computedWatchers).forEach(k => bodyOrHeaderOrFooter._computedWatchers[k].run())
            this.$nextTick(() => bodyOrHeaderOrFooter.$forceUpdate())
          })
        }
      })
    },
    init() {
      // of ('ibyufiuw').subscribe(res => console.log)
      this.initializeSlider = false;
      let prev1Date = this.prevWeek(this.selectedWeek);
      // let prev2Date = this.prevWeek(prev1Date);
      let nextDate1 = this.nextWeek(this.selectedWeek);
      let nextDate2 = this.nextWeek(nextDate1);
      // let prev3Date = this.prevWeek(prev2Date);
      let nextWeekData;
      this.loading = true;
      this.getAccrualSnapshotDetails(prev1Date, this.selectedYear)
        .pipe(
          concatMap(response => {
            this.slides.set(prev1Date, response);
            return this.snapshotService.getAccrualSnapshotDetail(
              this.projectId,
              this.selectedWeek,
              this.selectedYear
            );
          }),
          concatMap(response => {
            // if (this.selectedWeek === moment().startOf('isoweek').format('MM/DD/YYYY')) {
            //   return of(response);
            // }
            // console.log('nextweekdate 1=>', this.nextWeekDate);
            // console.log('cond date=>', moment().day(8).format('MM/DD/YYYY'));
            if (this.selectedWeek === moment().format('MM/DD/YYYY') || this.selectedWeek < moment().format('MM/DD/YYYY')) {
              this.nextWeekDate = '';
            }
            // console.log('nextweekdate 2=>', this.nextWeekDate);
            if (this.nextWeekDate === moment().day(8).format('MM/DD/YYYY')) {
              return of(response);
            }
            nextWeekData = response
            this.slides.set(this.selectedWeek, response);
            return this.snapshotService.getAccrualSnapshotDetail(
              this.projectId,
              nextDate1,
              this.selectedYear
            );
          }),

        )
        .subscribe(response => {
          if (nextWeekData) {
            this.slides.set(nextDate1, response);
          } else {
            this.slides.set(this.selectedWeek, response);
          }
          this.setSnapshotTable();
          this.loading = false;
        }, this.errorhandler.bind(this));
    },
    exportSnapshotTable() {
   
    },
    fetchCurrentUserDetails() {
      this.snapshotService
        .getCurrentUser()
        .subscribe(currentUser => (this.currentUser = currentUser));
    },
    showPrevData() {
      let prevDate = this.prevWeek(this.selectedWeek)
      this.selectedWeek = prevDate;
      this.updateCurSnapshotData();
      prevDate = this.prevWeek(this.selectedWeek);
      if (!this.slides.has(prevDate)) {
        this.updateSelectedSnapshotInfo(prevDate);
      }
      this.$refs.snapshot_slider.prev();
      EventBus.$emit('onSnapshotWeekChange', new Date(this.selectedWeek));
      this.clearFooterRowAndUpdateValues();
    },
    showNextData() {
      let nextDate = this.nextWeek(this.selectedWeek)
      // console.log('selected week', this.selectedWeek);
      // console.log('next date 1 =>', nextDate);
      // console.log('cond check', this.slides.has(nextDate))
      if (this.slides.has(nextDate)) {
        this.selectedWeek = nextDate;
        this.updateCurSnapshotData();
        nextDate = this.nextWeek(this.selectedWeek);
        // console.log('next date 2=>', nextDate);
        // console.log('start of', moment().startOf('isoweek').format('MM/DD/YYYY'));
        // console.log('updated selected week =>', this.selectedWeek );
        // console.log('has cond=>', !this.slides.has(nextDate));
        // console.log('date cond=>', this.selectedWeek !== moment().startOf('isoweek').format('MM/DD/YYYY'))
        if (!this.slides.has(nextDate) && (nextDate === this.nextWeekDate || this.selectedWeek !== moment().startOf('isoweek').format('MM/DD/YYYY'))) {
          this.updateSelectedSnapshotInfo(nextDate);
        }
        this.$refs.snapshot_slider.next();
      }
      EventBus.$emit('onSnapshotWeekChange', new Date(this.selectedWeek));
      this.clearFooterRowAndUpdateValues();
    },
    updateSelectedSnapshotInfo(selectedWeek) {
      // console.log('date rcvd', selectedWeek);
      this.getAccrualSnapshotDetails(selectedWeek, this.selectedYear).subscribe(response => {
        this.slides.set(selectedWeek, response);
      }, this.errorhandler.bind(this));
    },
    scrollToLatestMonth() {
      let dateCompared;
      const date = new Date();
      const containerElements = document.querySelectorAll(
        ".el-table > .el-table__body-wrapper"
      );
      var monthFirstDate = new Date(date.getFullYear(), date.getMonth(), 1);

      this.slides.get(this.selectedWeek)[0].accruals.filter(item => {
        dateCompared = dateCompare(item.interval_start, monthFirstDate);
        for (let i = 0; i < containerElements.length; i++) {
          const element = containerElements[i];
          let height = element.scrollTop + element.scrollHeight;
          if (dateCompared === "=") {
            element.scrollTo(0, height);
          }
        }
      });
    },
    prevWeek(selectedWeek) {
      const date = new Date(selectedWeek);
      date.setDate(date.getDate() - 7);
      return formatDateObject(date, "/");
    },
    nextWeek(selectedWeek) {
      const date = new Date(selectedWeek);
      date.setDate(date.getDate() + 7);
      return formatDateObject(date, "/");
    },
    getAccrualSnapshotDetails(selectedWeek) {
      return this.snapshotService.getAccrualSnapshotDetail(
        this.projectId,
        selectedWeek,
        this.selectedYear
      );
    },
    setSnapshotTable() {
      if (this.slides.has(this.selectedWeek)) {
        this.currentWeekData = [];
        this.currentWeekData.push(this.slides.get(this.selectedWeek));
        this.currentSnapshot = this.slides.get(this.selectedWeek);

        this.refresh();
        return this.currentWeekData;
      }
    },
    revenueSum(item) {
      if (item.revenue) {
        let rev = item.revenue;
        //  console.log('revenue=>', rev);
        return rev = rev.replace(/[$,]/gi, '');
      }
    },
    invoiceSum(item) {
      let invoiceCummulative = 0;
      let invSum = 0;
      item.invoices.forEach(invoice => {
        if (invoice.value) {
          let inv = invoice.value;
          if (inv.includes('$')) {
            inv = inv.replace(/[$,]/gi, '');
            invSum += parseFloat(inv);
          }
          //   invoiceCummulative += inv;
          //   console.log(' sum of invoices=>', invoiceCummulative);
        }
      })
      if (invSum) {
        invoiceCummulative = invSum;
      }
      else {
        invoiceCummulative += parseFloat(
          array_of_objects_sum(item.invoices, "value")
        );
      }
      return invoiceCummulative;

    },
    totalOfInvoices(item) {
      return totalInvoice += parseFloat(
        array_of_objects_sum(item.invoices, "totalInvoice")
      );
    },
    formatAccrualRow(accrualsItem) {
      let revenueCummulative = 0;
      let invoiceCummulative = 0;
      let totalInvoice = 0;
      let revn = 0;
      let invTot = 0;
      let totalInvoiceSum = 0;
      accrualsItem.accruals.forEach((item, index) => {
        // if (item.revenue) {
        //   item.revenue = item.revenue.replace(/[$,]/gi, '')
        // }
        // item.invoices.forEach(invoice => {
        //   if (invoice.value) {
        //     if (invoice.value) {
        //       invoice.value = invoice.value.replace(/[$,]/gi, '');
        //     }
        //   }
        // })

        revn = this.revenueSum(item);
        item.revn = revn;
        revenueCummulative += revn ? parseFloat(revn) : 0;
        // console.log('revenue recvd=>', revn);
        // console.log('revenue cumm =>', revenueCummulative);
        invTot = this.invoiceSum(item);
        item.revenueCummulative = revenueCummulative;

        // invoiceCummulative += parseFloat(
        //   array_of_objects_sum(item.invoices, "value")
        // );
        invoiceCummulative = invoiceCummulative + invTot;
        item.invoiceCummulative = invoiceCummulative;
        // item.invoiceCummulative = invoiceCummulative;
        totalInvoice = invTot;
        item.totalInvoice = totalInvoice;
        totalInvoiceSum += invTot ? parseFloat(invTot) : 0;

        // totalInvoiceSum = invoiceCummulative;
        this.totalInvoiceSum = totalInvoiceSum;
        let prevDate = this.prevWeek(this.selectedWeek);
        const prevAccrualsItem = this.slides.get(prevDate);
        if (prevAccrualsItem) {
          const prevItem = prevAccrualsItem.accruals.filter(
            accrualItem => accrualItem.interval_start == item.interval_start
          );
          if (prevItem.length) {
            item.revenueDifference = "";
            item.invoiceDifference = "";
            let prev_revn = prevItem[0].revenue.replace(/[$,]/gi, '');
            // item.revenueDifference =
            //   item.revenue != parseFloat(prevItem[0].revenue) ?
            //   parseFloat(item.revenue) > parseFloat(prevItem[0].revenue) ?
            //   "up" :
            //   "down" :
            //   "";
            item.revenueDifference = revn != parseFloat(prev_revn) ? parseFloat(revn) > parseFloat(prev_revn) ? "up" : "down" : "";

            item.invoiceDifference =
              item.totalInvoice != parseFloat(prevItem[0].totalInvoice) ?
                parseFloat(item.totalInvoice) >
                  parseFloat(prevItem[0].totalInvoice) ?
                  "up" :
                  "down" :
                "";
          }
        }

      });
    },
    formatLineChartData(currentSnapshot) {
      let chartData = [];
      currentSnapshot.accruals.forEach(element => {
        const data = {
          Month: strToDate(element.interval_start, "YYYY-MM-DD"),
          Revenue: parseFloat(element.revenueCummulative).toFixed(2),
          Invoice: parseFloat(element.invoiceCummulative).toFixed(2)
        };
        chartData.push(data);
      });
      this.chartData = chartData;
      return chartData;
    },
    // arraySpanMethod({
    //   row,
    //   column,
    //   rowIndex,
    //   columnIndex
    // }) {
    //   if (rowIndex === this.snapshotCurrentData[0].accruals.length) {
    //     if (columnIndex === 0) {
    //       return [1,3];
    //     } else if (columnIndex === 1) {
    //       return [0, 0];
    //     }
    //   }
    // },
    getSummaries(param) {
      const {
        columns,
        data
      } = param;
      const sums = [];
      sums[0] = 'Total';
      sums[3] = `N/A`;
      sums[5] = 'N/A';
      this.updateTableFooter();
      return sums;
    },
    getFormattedCurrency(value) {
      value = parseFloat((Math.round(value * 100) / 100).toFixed(2));
      return "$" + value.toFixed(2).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")
    },
    clearFooterRowAndUpdateValues() {
      let footerRows = document.querySelectorAll('.el-table__footer-wrapper tr');
      if (footerRows.length > 1) {
        footerRows[1].remove();

      }
      this.updateTableFooter();
    },
    updateTableFooter() {
      setTimeout(() => {
        let footerRows = document.querySelectorAll('.el-table__footer-wrapper tr');
        if (footerRows.length === 1 && this.projectSummary) {
          this.projectSummary.budgeted_revenue = this.projectSummary.budgeted_revenue ? this.projectSummary.budgeted_revenue : 0;
          let footerFirstRow = footerRows[0]
          let footerFirstRowTdList = footerFirstRow.querySelectorAll('td');
          footerFirstRowTdList.forEach((item, index) => {
            item.setAttribute('class', 'table_footer_td')
            if (index === 0) {
              item.setAttribute('colspan', 3);
              // this.setInnerHTML(item, `Total <i class="flaticon-info"></i>`);
              this.setInnerHTML(item, `Total`);
            }
            if (index === 1) {
              let className
              if (this.projectSummary && this.chartData.length && parseFloat(this.projectSummary.budgeted_revenue) < parseFloat(this.chartData[this.chartData.length - 1].Revenue)) {
                className = 'table_footer_profit'
                this.setInnerHTML(item, `<span class="${className}">${this.getFormattedCurrency(this.chartData[this.chartData.length - 1].Revenue)} <i class="el-icon-caret-top"></i></span>`)
              } else if (this.projectSummary && this.chartData.length && parseFloat(this.projectSummary.budgeted_revenue) > parseFloat(this.chartData[this.chartData.length - 1].Revenue)) {
                className = 'table_footer_loss'
                this.setInnerHTML(item, `<span class="${className}">${this.getFormattedCurrency(this.chartData[this.chartData.length - 1].Revenue)} <i class="el-icon-caret-bottom"></i></span>`)
              } else if (this.chartData.length) {
                this.setInnerHTML(item, `<span>${this.getFormattedCurrency(this.chartData[this.chartData.length - 1].Revenue)}</span>`)
              }
            }
            if (index === 3 && this.chartData.length) {
              this.setInnerHTML(item, `${this.getFormattedCurrency(this.totalInvoiceSum)}`)
            }
            if (index > 3) {
              item.remove();
            }
          })
          let footerLastRow = document.createElement('tr');
          footerLastRow.innerHTML = footerFirstRow.innerHTML;
          footerFirstRow.parentNode.append(footerLastRow);
          let footerLastRowTdList = footerLastRow.querySelectorAll('td');
          footerLastRowTdList.forEach((item, index) => {
            if (index === 0) {
              // this.setInnerHTML(item, `Budgeted Revenue <i class="flaticon-info"></i>`)
              this.setInnerHTML(item, `Budgeted Revenue`)
            }
            if (index === 1) {
              let amount = this.projectSummary.budgeted_revenue ? this.getFormattedCurrency(this.projectSummary.budgeted_revenue) : '$0'
              this.setInnerHTML(item, `<span>${amount}</span>`)
            }
            if (index === 3) {
              this.setInnerHTML(item, "")
            }
          })
        }
      }, 100);
    },
    setInnerHTML(item, content) {
      let tdDiv = item.querySelector('div');
      tdDiv.innerHTML = "";
      tdDiv.innerHTML = content;
    },
    updateAccrual(accuralId, intervalStart, fieldValue, event, field) {
      if (!parseFloat(event.target.value)) {
        event.target.value = 0;
      }
      let body;
      if (field === 'revenueField') {
        this.checkForMaxValue(event.target.value, event);
        let revenue = event.target.value;
        body = {
          interval_start: intervalStart,
          revenue: revenue ? revenue : 0
        };
      } else {
        body = {
          interval_start: intervalStart,
          hours: fieldValue,
        };
      }

      this.snapshotService
        .updateAccrual(accuralId, body)
        .subscribe(response => {
          if (response.status) {
            EventBus.$emit("updateProjectSummary", null);
          }
        });
      this.snapshotTableKey++;
      this.updateCurSnapshotData();
    },
    setCharAt(str, index, chr) {
      if (index > str.length - 1) return str;
      return str.substr(0, index) + chr + str.substr(index + 1);
    },
    checkForMaxValue(value, event) {
      if (!parseFloat(event.target.value)) {
        event.target.value = "0";
      }
      let fullNum = event.target.value.toString().split(".")[0];
      let isValidNum = (/^[0-9]{0,10}$/).test(parseFloat(fullNum))
      if (!isValidNum && event.target.value.includes(".")) {
        event.target.value = this.setCharAt(event.target.value, event.target.value.indexOf(".") - 1, "")
      } else if (!isValidNum && !event.target.value.includes(".")) {
        event.target.value = event.target.value.slice(0, 10)
      }
    },
    createAccrual(snapshotId) {
      let body = {
        hours: 20,
        snapshot_id: snapshotId,
        revenue: 12000,
        interval_start: "01/01/2021"
      };

      this.snapshotService.createAccrual(this.projectId, body).subscribe();
    },
    deleteAccrual(accuralId) {
      this.snapshotService.deleteAccrual(accuralId).subscribe();
    },
    errorhandler() {
      bootstrapNotify("Error in loading the report.\n Please retry", "error");
    },
    getSnapshot(date) {
      this.selectedWeek = formatDateObject(date, "/");
      this.getAccrualSnapshotDetails(this.selectedWeek, this.selectedYear).subscribe(
        this.setSnapshotTable.bind(this),
        this.errorhandler.bind(this)
      );
    },
    tableRowClassName({
      row
    }) {
      if (!row.active) {
        return "disabled-row";
      }
      if (this.snapshotCurrentData[0].snapshot_details.snapshot_editable == 0) {
        return "readonly-row";
      }
      return "";
    },
    openInvoiceEditModal(invoice, accrualId) {
      this.accrualId = accrualId;
      this.editInvoiceObject = invoice;
      this.editInvoice = true;
    },
    updateInvoice(updatedInvoices) {
      this.currentWeekData[0].accruals.invoices = updatedInvoices;
      this.updateCurSnapshotData();
      EventBus.$emit("updateProjectSummary", null);
    },
    updateCurSnapshotData() {
      if (this.slides.has(this.selectedWeek)) {
        this.currentSnapshot = null;
        this.currentWeekData = [];
        this.currentSnapshot = this.slides.get(this.selectedWeek);
        this.currentWeekData.push(this.slides.get(this.selectedWeek));
        this.refresh()
      }
    }
    //commented below logic of adding/deleting month as this is being now handled from backend
    // addNewRow() {
    //   console.log(
    //     "accruals Table Data",
    //     this.accrualsTableData[this.swiper.activeIndex]
    //   );
    // },
    // createNextAccrualMonth() {
    //   const curAccrualLength = this.slides[this.swiper.activeIndex].accruals
    //     .length;
    //   const lastAccrualobj = this.slides[this.swiper.activeIndex].accruals[
    //     curAccrualLength - 1
    //   ];
    //   const lastAccrualMonth = new Date(lastAccrualobj.interval_start);
    //   const nextMonth = new Date(
    //       lastAccrualMonth.setMonth(lastAccrualMonth.getMonth() + 1)
    //     )
    //     .toISOString()
    //     .substring(0, 10);

    //   const newAccrualObj = {
    //     id: null,
    //     active: true,
    //     hours: 0,
    //     interval_start: nextMonth,
    //     interval_type: 2,
    //     invoices: [],
    //     project_accrual_id: this.projectId,
    //     revenue: 0,
    //     snapshot_id: lastAccrualobj.snapshot_id
    //   };
    //   this.slides[this.swiper.activeIndex].accruals.push(newAccrualObj);
    //   this.snapshotTableKey++;

    //   let accrualId = null;
    //   // const newAccrualLength = this.slides[this.swiper.activeIndex].accruals.length
    //   this.snapshotService
    //     .createSnapshotNextMonth(newAccrualObj)
    //     .subscribe(response => {
    //       accrualId = response.json.accural_id;
    //       if (accrualId) {
    //         this.slides[this.swiper.activeIndex].accruals[
    //           curAccrualLength
    //         ].id = accrualId;
    //         EventBus.$emit("updateProjectSummary", null); // update the project summary panel
    //       }
    //     });
    // },
    // removeLastAccrualMonth() {
    //   this.accrualDeleteVisible = false;
    //   let curAccrualLength = this.slides[this.swiper.activeIndex].accruals
    //     .length;
    //   let lastAccrualId = this.slides[this.swiper.activeIndex].accruals[
    //     curAccrualLength - 1
    //   ].id;

    //   this.slides[this.swiper.activeIndex].accruals.pop();
    //   this.snapshotService.deleteSnapshotLastMonth(lastAccrualId).subscribe();
    //   EventBus.$emit("updateProjectSummary", null);
    // }
  }
};
export default {
  name: 'critical-dates-panel',
  props: ['startDate', 'endDate', 'project_id'],
  mounted(){
    this.loadCriticalDates();
    this.$root.$on('updateDashboard', () => {
      this.loadCriticalDates();
    });
  },
  data() {
    return {
    	critical_dates_data		: [],
    	latest_critical_date	: '',
    	loading            		: true
    }
  },
  watch: {
    startDate(){
      this.loadCriticalDates();
    },
    endDate(){
      this.loadCriticalDates();
    }
  },
  methods: {
  	loadCriticalDates: function () {
      var vm          = this;
      vm.loading 			= true;
      vm.$http.get('/api/v1/project_plans/'+vm.project_id+'/critical_dates',{
        params: {
          start_date: this.startDate.toLocaleDateString('en-US'),
          end_date: this.endDate.toLocaleDateString('en-US')
        }
      })
      .then((response) => {
        this.critical_dates_data   	= response.body.body.critical_dates;
        this.latest_critical_date  	= response.body.body.latest_critical_date;
        this.loading 								= false;
        setTimeout(() => {
        	this.scrollToLatest();
        }, 0);
      });
  	},
  	formatDates(date) {
  		return date.split("-")[1] + "/" + date.split("-")[2] + "/" + date.split("-")[0].slice(-2);
  	},
    valueBasedCricleClass(value){
    	// assigns the timeline cricles classes as per their grouping
      if (value == "high")
        return "fa fa-genderless m--font-danger";
      else if (value == "med")
      	return "fa fa-genderless m--font-warning";
     	else
     		return "fa fa-genderless m--font-success";
    },
    highlightKeywords(taskName, keywords){
    	// return the task name with highlighted keywords found in the name
    	var highlightedTaskName = taskName;
    	keywords.forEach(function(keyword) {
    		highlightedTaskName = highlightedTaskName.replace(keyword, "<span class='m--font-primary'>"+keyword+"</span>")
    	});
    	return highlightedTaskName;
    },
    scrollToLatest(){
    	// scroll to most recent critical date
    	var vm = this;
    	if (!!vm.latest_critical_date){
	    	var scrollTop  = $('#'+vm.latest_critical_date).offset().top - $('#critical_date_container').offset().top + $('#critical_date_container').scrollTop();
		  	 $('#critical_date_container').animate({
		        scrollTop: scrollTop
		      }, 500);
    	}
    }
  }
}
import Vue from 'vue/dist/vue.esm'
import TalentSkillAnalysis from './talent-skill-analysis'
import Axios from 'axios-observable';
import VXETable from "vxe-table";
import "vxe-table/lib/index.css";
import XEUtils from 'xe-utils'
import enUS from 'vxe-table/lib/locale/lang/en-US';
import { Popover } from 'element-ui';

Vue.use(VXETable);
VXETable.setup({
    i18n: (key, value) => XEUtils.get(enUS, key)
})
Vue.use(Popover);

// Axios.defaults.baseURL = 'https://api.example.com';
Axios.defaults.headers.common['Content-Type'] = 'application/json';
Axios.defaults.headers.post['Content-Type'] = 'application/json';


if (document.getElementById("talent_skill_analysis")) {
    new Vue({
        el: '#talent_skill_analysis',
        render: h => h(TalentSkillAnalysis)
    })
}
import employeeSkillsetsService from "../services/employee-skillsets-service";


import SkillSetDialog from "../components/capability-set-dialog";
export default {
  name: "employee-skillsets",
  components: {
    SkillSetDialog
  },
  props: [],
  filters: {},
  data() {
    return {
      employeeSkillsetsService: employeeSkillsetsService,
      openSkillSetDialog: false,
      currentUser: null,
      counter: 0,
      skillDeleteVisible: false,
      skillId: "",
      skillNames: "",
      skillsetList: [],
      userId: null,
      userSkillSets: [],
      skillNote: "",
      formType: "",
      skillSet: null
    };
  },
  created() {
    this.userId = window.location.pathname.split('/')[2];
    this.getEmployeeSkillsList();
  },
  mounted() {

  },
  watch: {
    'openSkillSetDialog': function (newVal) {
      if(!newVal)
      this.getEmployeeSkillsList();
      this.allSkillsList();
    }
  },
  computed: {
  },
  methods: {
    allSkillsList() {
      if(this.userId) {
        return this.employeeSkillsetsService.getAllCapabilities(this.userId).subscribe(response => {
          this.skillsetList = response;
          this.counter++;
        }
        );
      }
      return this.skillsetList ? this.skillsetList : [];
    },
    getEmployeeSkillsList() {
      if (this.userId){
        return this.employeeSkillsetsService.getEmployeeCapabilities(this.userId)
        .subscribe( response => {
          this.userSkillSets = response;
        });
      }
    },
    removeCapability() {
      this.employeeSkillsetsService.deleteEmployeeCapability(this.userId, this.skillId)
        .subscribe(
          response => {
            this.skillDeleteVisible = false;
            this.getEmployeeSkillsList(); // to update my skills list
            this.allSkillsList(); // to update all skills list
            
            bootstrapNotify("A Capability has been removed successfully.", "success");
          },
          error => {
            bootstrapNotify("Error in removing Capability.\n Please retry", "error");
          }
        )
    },
    skillSetDialog(formType, item){
      this.formType = formType;
      this.openSkillSetDialog = true;
      if(formType === "edit"){
        this.skillSet = item;
      }
    }
  }
};
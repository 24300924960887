import PipelineImpactService from "../../pipeline-impact-service";

export default {
  name: 'sync-hubspot-deal',
  props: {
    open: {
      type: Boolean,
      required: true,
      default: false
    },
  },
  data() {
    return {
      open_: false,
      PipelineImpactService: PipelineImpactService
    }
  },
  watch: {
    open(value) {
      this.open_ = value;
      if(value) {
        this.syncHubspotDeal();
      }
    },
  },
  created() {
  },
  mounted() {
  },
  computed: {},
  methods: {
    emitClose(event) {
      this.open_ = false;
      this.$emit('update:open', this.open_);
    },
    errorMsg() {
      this.open_ = false;
      this.$emit('update:open', this.open_);
      bootstrapNotify(msg, 'error');
    },
    successMsg(msg) {
      this.open_ = false;
      this.$emit('update:open', this.open_);
      bootstrapNotify(msg, 'success');
    },
    syncHubspotDeal() {
      this.PipelineImpactService.getSyncHubspotDeal().subscribe((response) => {
        if(response.status_code == 200) {
         this.successMsg('Hubspot deals and cash data synced.')
        }
        else{
          this.successMsg('Something went wrong. Please try to sync again!!')
        }
      });
    }
  }
}

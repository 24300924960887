import Axios from "axios-observable";
import { BehaviorSubject, of, throwError } from "rxjs";
import { map, catchError } from "rxjs/operators";
import employeeService from "../../../services/employee.service";

class accrualsDashboardService {
  yearlyRevenueDataloading = new BehaviorSubject(false);
  projectSummaryDataLoading = new BehaviorSubject(false);
  projectListDataLoading = new BehaviorSubject(false);

  apiUrlConstants = {
    currentUserApiUrl: "/employees/fetch_current_employee.json",
    pmFilterApiUrl: "api/v1/accruals/project_managers/options_for_select",
    // pmFilterApiUrl: 'https://21236999-d0d4-4521-8515-b62f142d9b8d.mock.pstmn.io/project_managers/options_for_select',
    yearlyRevenueApiUrl: "api/v1/accruals/project_managers/monthly_breakdown",
    // yearlyRevenueApiUrl: 'https://116c19df-dc1c-4d31-a0a4-f16c57dd78e9.mock.pstmn.io/accruals/project_managers/monthly_breakdown',
    projectSummaryApiUrl: "api/v1/accruals/project_managers/project_breakdown",
    projectListApiUri: "api/v1/project_accrual/project_list_view",
    syncAccrualSheetUri: 'api/v1/accrual_report/gsheet/sync'

    // projectSummaryApiUrl: 'https://67752188-e154-405e-a9bf-4bd27f700b25.mock.pstmn.io//accruals/pm/project_breakdown'
  };

  constructor() {}

  getProjectManagersList() {
    return Axios.get(this.apiUrlConstants.pmFilterApiUrl).pipe(
      map(response => {
        return response.data;
      })
    );
  }

  getCurrentUser() {
    return employeeService.getCurrentUserDetails();
  }

  getYearlyRevenueData(selectedYear, projectType, pmId) {
    this.yearlyRevenueDataloading.next(true);
    return Axios.get(this.apiUrlConstants.yearlyRevenueApiUrl, {
      params: {
        start_date: selectedYear,
        contract_type: projectType,
        pm_id: pmId
      }
    }).pipe(
      map(response => {
        this.yearlyRevenueDataloading.next(false);
        return response.data.monthly_breakdown;
      }),
      catchError(error => {
        this.yearlyRevenueDataloading.next(false);
        return throwError(`Error: ${error}`);
      })
    );
  }

  getProjectSummaryData(pm, startDate, pageNum) {
    this.projectSummaryDataLoading.next(true);
    return Axios.get(this.apiUrlConstants.projectSummaryApiUrl, {
      params: {
        pm_id: pm.id,
        start_date: startDate,
        page_no: pageNum
      }
    }).pipe(
      map(response => {
        this.projectSummaryDataLoading.next(false);
        return response.data.results;
      }),
      catchError(error => {
        this.projectSummaryDataLoading.next(false);
        return throwError(`Error: ${error}`);
      })
    );
  }

  getProjectListData(pm, pageNum, itemsPerPage, filterParams) {
    this.projectSummaryDataLoading.next(true);
    let obj = {
      page: pageNum,
      pm_id: pm.id,
      limit: itemsPerPage,
      q: filterParams
    };

    return Axios.get(this.apiUrlConstants.projectListApiUri, {
      params: obj
    }).pipe(
      map(response => {
        this.projectListDataLoading.next(false);
        return response.data;
      }),
      catchError(error => {
        this.projectListDataLoading.next(false);
        return throwError(`Error: ${error}`);
      })
    );
  }

  getSyncAccrualToSheet() {
    return Axios.get(this.apiUrlConstants.syncAccrualSheetUri)
    .pipe(map(response => response.data));
  }

  errorHandler() {
    return of(`Bad Promise: ${error}`);
  }
}

export default new accrualsDashboardService();

import Axios from 'axios-observable';
import { BehaviorSubject, of } from 'rxjs';
import { map, concatMap, tap } from 'rxjs/operators';
import { formatDateObject } from "../utils/utils";
// import { saveAs } from 'file-saver';
let baseUrl = '';

class TransactionService {
    activeToken = null;
    apiUrlConstants = {
        getToken: '/employees/generate_token.json',
        getCurrentUser: '/employees/fetch_current_employee.json',
        getAllUri: 'transactions',
        getStaticDataUri: 'site-content',
        updateUri: 'transactions',
        uploadAttachmentsUri: 'attachments',
        downloadAttachmentUri: 'download-attachments',
        generateUrlForAttachments: 'generate-url-for-attachment',
        bulkUploadUri: 'bulk-upload',
        queryTransactions: 'transactions/query',
        saveAttachmentData: 'attachments/data'
    }

    constructor() {
        const host = window.location.host;
        if (host == 'bitool.softwaystaging.com') {
            baseUrl = 'https://ldk0xc1ted.execute-api.us-east-1.amazonaws.com/stage/';
        } else if (host == 'bitool.softwaysolutions.com') {
            baseUrl = 'https://14gv0a3g6d.execute-api.us-east-1.amazonaws.com/prod/';
        } else {
            baseUrl = 'http://localhost:2017/';
        }
    }

    getToken() {
        const currDate = Date.now();
        if (this.activeToken && ((this.activeToken.exp * 1000 - 1000) > currDate)) {
            return of(this.activeToken.jwt);
        }
        return Axios.get(this.apiUrlConstants.getToken).pipe(
            tap(response => {
                this.activeToken = response.data;
            }),
            map(response => response.data.jwt)
        )
    };

    getAll() {
        return this.getToken()
            .pipe(
                concatMap((token) => {
                    return Axios.get(baseUrl + this.apiUrlConstants.getAllUri, {
                        headers: {
                            token
                        }
                    })
                }),
                map(response => {
                    return response.data.data;
                })
            );
    }

    getCurrentUser() {
        return Axios.get(this.apiUrlConstants.getCurrentUser)
            .pipe(map(res => res.data));
    }

    query(body) {
        return this.getToken().pipe(
            concatMap(token => {
                return Axios.post(baseUrl + this.apiUrlConstants.queryTransactions, body, {
                    headers: {
                        token
                    }
                });
            }),
            map(response => {
                return response.data.data;
            })
        );
    }

    getStaticData() {
        return this.getToken().pipe(
            concatMap(token => {
                return Axios.get(baseUrl + this.apiUrlConstants.getStaticDataUri, {
                    headers: {
                        token
                    }
                });
            }),
            map(response => {
                return response.data.data;
            })
        );
    }

    updateTransaction(body) {
        return this.getToken().pipe(
            concatMap(token => {
                return Axios.put(baseUrl + this.apiUrlConstants.updateUri, body, {
                    headers: {
                        token
                    }
                });
            }),
            map(response => {
                return response.data;
            })
        );
    }

    uploadAttachmentsThroughSignedUrl(file, filename) {
        const urlContants = this.apiUrlConstants;
        const self = this;
        return this.getToken().pipe(
            concatMap(token => {
                return Axios.get(`${baseUrl}${urlContants.generateUrlForAttachments}?file=${filename}&task=upload`, {
                    headers: {
                        token
                    }
                })
            }),
            map(response => response.data.data),
            concatMap(presignedUrl => {
                return Axios.put(`${presignedUrl}`, file);
            }),
            concatMap(res => {
                return self.saveAttachmentData([filename])
            })
        )
    };

    downloadAttachmentsThroughSignedUrl(filename) {
        const urlContants = this.apiUrlConstants;
        return this.getToken().pipe(
            concatMap(token => {
                return Axios.get(`${baseUrl}${urlContants.generateUrlForAttachments}?file=${filename}&task=download`, {
                    headers: {
                        token
                    }
                })
            }),
            map(response => response.data.data),
            tap(presignedUrl => {
                window.open(`${presignedUrl}`, '_blank');
            })
        )
    };

    saveAttachmentData(attachments) {
        const urlContants = this.apiUrlConstants;
        return this.getToken().pipe(
            concatMap(token => {
                return Axios.post(`${baseUrl}${urlContants.saveAttachmentData}`, attachments, {
                    headers: {
                        token
                    }
                });
            }),
            map(response => {
                return response.data.data[0];
            })
        );
    };

    bulkUpload(file) {
        var body = new FormData();
        body.append('file', file);
        return this.getToken().pipe(
            concatMap(token => {
                return Axios.post(baseUrl + this.apiUrlConstants.bulkUploadUri, body, {
                    headers: {
                        token
                    }
                });
            }),
            map(response => {
                return response.data.data;
            })
        );
    };

}

const transactionService = new TransactionService();

export { transactionService, baseUrl }
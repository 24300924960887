import moment from "moment";

function formatSnapshotName(dateString, format) {
  if (!dateString) return "";

  const momentObj = moment(dateString, format);
  return "Week" + momentObj.format(" of MMM' DD");
}

function strToDate(dateString, format) {
  if (!dateString) return new Date();

  const momentObj = moment(dateString, format);
  return momentObj._d;
}

function formatDateObj(date, format) {
  return moment(date).format(format);
}

export { formatSnapshotName, strToDate, formatDateObj };

export default {
  name: 'project-summary-panel',
  props: ['project_id'],
  mounted() {
    this.loadProjectSummaryData();
    // reload project summary panel to fetch the latest information
    this.$root.$on('updateDashboard', () => {
      this.loadProjectSummaryData();
    });
  },
  data() {
    return {
      project_name      : '',
      contract_type     : '',
      hours             : '',
      project_managers  : [],
      account_managers  : [],
      start_date        : '',
      end_date          : ''
    }
  },
  methods: {
    loadProjectSummaryData() {
      this.$http.get('/api/v1/project_plans/' + this.project_id + '/project_summary')
      .then(function(response){
        if (!response.body.body.error){
          var result                    = response.body.body;
          this.project_name             = result.project_name;
          this.hours                    = result.hours;
          this.project_managers         = result.project_manager;
          this.account_managers         = result.account_manager;
          this.start_date               = result.start_date;
          this.end_date                 = result.end_date;
          this.contract_type            = result.contract_type;
          this.$parent.project_name     = result.project_name;
          this.$parent.jira_project_key = result.jira_project_key;
        }
      });
    }
  }
}
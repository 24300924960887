export default {
  name: 'sidebar',
  props: ['project_id'],
  mounted(){
    this.getGsheetUrl();
    this.loadProjectSummaryData();
    this.check_autherization();
  },
  data() {
    return {
      jira_project_key      : '',
      gsheet_url            : '',
      project_name          : '',
      modify_permission     : false,
      active_page           : this.$route.name
    }
  },
  computed: {
    talent_forecast_url() {
      return "/talent_forecasts?nameFilters=" + this.project_id + "&reportTypeFilter=Project";
    },
    burn_report_url() {
      return "/project_burn_report#/" + this.project_id;
    },
    gantt_charts_url() {
      return "/estimates/" + this.project_id + "/gantt_charts#/";
    },
    dashboard_url() {
      return "/estimates/" + this.project_id + "#/";
    },
    jira_project_url() {
      return "//softwaysolutions.atlassian.net/browse/" + this.jira_project_key;
    },
    planning_url() {
      return "https://bitool-v2.softway.com/project-details/" + this.project_id;
    }
  },
  watch: {
    project_id(newVal, oldVal){
      this.getGsheetUrl();
      this.loadProjectSummaryData();
    }
  },
  methods: {
    getGsheetUrl() {
      this.$http.get('/api/v1/project_plans/' + this.project_id + '/fetch_gsheet_url')
      .then(function(response) {
        if(typeof(response.body.body) == 'string')
          // get the gsheet edit url
          this.gsheet_url = response.body.body.replace("pubhtml","edit");
        else
          this.gsheet_url = "";
      });
    },
    check_autherization(){
      this.$http.get('/authorization/api_autherization/check_permissions', {
        params: {
          page: "estimo, estimate",
          function: "create?"
        }
      })
      .then(function(response) {
        this.modify_permission = response.body.permit;
      });
    },
    loadProjectSummaryData() {
      this.$http.get('/api/v1/project_plans/' + this.project_id + '/project_summary')
      .then(function(response){
        if (!response.body.body.error){
          var result            = response.body.body;
          this.project_name     = result.project_name;
          this.jira_project_key = result.jira_project_key;
        }
      });
    },
    syncProject() {
      var vm = this;
      vm.$parent.dialogFormVisible = true;
      vm.$parent.syncActive = true;
      $('#pulsate-crazy-target').pulsate({repeat:2});
      vm.$http.get('/api/v1/project_plans/' + this.project_id + '/sync_project_plan', {})
      .then(function(response) {
        var response_body = response.body.body;
        var status = response_body.success;
        var error = response_body.error;
        vm.$parent.loading = true;
        if (status == true) {
          bootstrapNotify('Project Plan sync successfull for ' + this.project_name,'success');
          vm.$parent.syncActive= false;
          vm.$parent.dialogFormVisible= false;
          vm.$parent.loading = false;
          this.$root.$emit('projectSynced');
        }
        else if (error == "sync_running"){
          vm.$parent.syncActive= false;
          vm.$parent.dialogFormVisible= false;
          bootstrapNotify('Sync already in progress. Please try after some time.','error');
          vm.$parent.loading = false;
        }
        else {
          vm.$parent.syncActive= false;
          vm.$parent.dialogFormVisible= false;
          bootstrapNotify('T&M sheet could not be synced.','error');
          vm.$parent.loading = false;
        }
      });
    },
  }
}
import Vue from 'vue/dist/vue.esm'
import { array_of_objects_sum } from '../utils/utils'

Vue.filter('capitalize', function (value) {
  if (!value) return ''
  value = value.toString()
  return value.charAt(0).toUpperCase() + value.slice(1)
})

Vue.filter('bit_date_format', function (value) {
  if (!value) return ''
  let start = moment(value);
  return start.format('MM/DD/YYYY');
})

Vue.filter('decimal', function (value, fixedTo) {
  if (typeof value !== "number") {
    value = parseFloat(value);
  }
  return value.toFixed(fixedTo);
})

Vue.filter('number', function (value) {
  if (!value) {
    return value;
  }
  return parseFloat(value);
})

Vue.filter('percentage', function(value, total, decimals) {
	if(!value) value = 0;
	if(!decimals) decimals = 0;

	value = (value / total) * 100;
  return (isNaN(value) ? 0 : value).toFixed(decimals) + '%';
})

Vue.filter('array_of_objects_sum', array_of_objects_sum)

Vue.filter('date', function (value, format) {
  if (!value) return ''
  let start = moment(value);
  return start.format(format);
})
import { render, staticRenderFns } from "./card-transactions.vue?vue&type=template&id=50f32773&scoped=true&"
import script from "./card-transactions.js?vue&type=script&lang=js&"
export * from "./card-transactions.js?vue&type=script&lang=js&"
import style0 from "./card-transactions.css?vue&type=style&index=0&id=50f32773&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "50f32773",
  null
  
)

export default component.exports
<template>
  <div class="col-md-6 no_data" style="">
    <i class="fa fa-thumbs-o-down warning_icon"></i>
    <span class="warning_text">No Data Available</span>
  </div>
</template>

<script>
export default {
  name: 'no-data-handler'
}
</script>

<style>
  .no_data {
    margin-left: 42%;
    margin-top: 20%;
  }
  .no_data > .warning_text {
    font-size:    19px;
    color:        grey;
    padding-left: 2%;
    font-style:   italic;
  }
  .no_data > .warning_icon {
    font-size: 2em;
    color:     grey;
  }
</style>
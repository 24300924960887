import employeeSkillsetsService from "../services/employee-skillsets-service";
export default {
  name: "skill-set-dialog",
  props: {
    open: {
      type: Boolean,
      required: true,
      default: false
    },
    formType: '',
    skillSet: null,
    skillsetList: null,
  },
  data() {
    var isUniqueSkillName = (rule, value, callback) => {
      setTimeout(() => {
        if (value.search(/[a-zA-Z]/) == -1) {
          return callback(new Error('Skill name must contain alteast one alphabets'))
        }
        callback();
      }, 200);
    };
    return {
      employeeSkillsetsService: employeeSkillsetsService,
      dialogFormVisible: false,
      currentUser: null,
      counter: 0,
      skillDeleteVisible: false,
      skillId: '',
      userId: null,
      userSkillSets: [],
      allowCreateSkill: false,
      skillSetFormData: {
        skillName: '', 
        skillNote: ''
      },
      skillsetFormRules: {
        skillName: [{
          required: true,
          message: 'Please select skill name',
          trigger: 'blur'
        },
        {
            validator: isUniqueSkillName,
            trigger: 'blur'
        }
      ],
        skillNote: [
        {
          min: 1,
          max: 500,
          message: 'Note must not exceed 500 characters',
          trigger: 'blur'
        }]
      }
    };
  },
  created() {
    this.userId = window.location.pathname.split('/')[2];
    this.employeeSkillsetsService.getCurrentUser()
      .subscribe(response => {
        this.currentUser = response;
        if(this.currentUser.permissions["SkillPolicy"] === "modify"){
          this.allowCreateSkill = true;
        }
      });
  },
  watch: {
    open(value) {
      this.dialogFormVisible = value;
      if (this.formType === "add") {
        this.skillSetFormData.skillName = "";
        this.skillSetFormData.skillNote = "";
      }
      if (this.formType === "edit") {
        this.skillSetFormData.skillName = this.skillSet.skill_name
        this.skillSetFormData.skillNote = this.skillSet.skill_note
      }
    }
  },
  computed: {
    pageTitle() {
      return this.formType ? this.formType === 'add' ? "Add new Skill" : "Edit skill" : '';
    },
    elSelectPlaceholder(){
      if (this.allowCreateSkill){
        return "Search for skills or add new (eg: ReactJs, Machine Learning)"
      }else{
        return "Search for skills (eg: ReactJs, Machine Learning)"
      }
    },
    elInputPlaceholder(){
      return "Please enter some details about this skill you acquired. For eg. Name of the project where you used it last, or any certifications etc"
    }
  },
  mounted() {
  },
  methods: {
    addEmployeeSkill() {
      this.$refs['skillSetForm'].validate((valid) => {
        if (valid) {
          if(this.formType === 'add'){
            this.employeeSkillsetsService
            .addEmployeeSkill(this.userId, this.skillSetFormData.skillName, this.skillSetFormData.skillNote)
            .subscribe(
              response => {
                this.$refs['skillSetForm'].resetFields();
                this.dialogFormVisible = false;
                bootstrapNotify("New skill has been added successfully.", "success");
              },
              error => {
                bootstrapNotify("Error in saving skills.\n Please retry", "error");
              }
            );
          }
          if(this.formType === 'edit'){
            this.employeeSkillsetsService
            .updateEmployeeSkill(this.userId, this.skillSet.id, this.skillSetFormData.skillNote)
            .subscribe(
              response => {
                this.$refs['skillSetForm'].resetFields();
                this.dialogFormVisible = false;
                bootstrapNotify("Skill has been updated successfully.", "success");
              },
              error => {
                bootstrapNotify("Error in saving skills.\n Please retry", "error");
              }
            );
          }  
        } else {
          return false;
        }
      });
        
    },
    resetSkillSetForm() {
      this.$refs['skillSetForm'].resetFields();
      this.dialogFormVisible = false;
      this.$emit('update:open', this.dialogFormVisible);
    }
  }
}
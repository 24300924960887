import Axios from "axios-observable";
import {
  BehaviorSubject
} from "rxjs";
import {
  map
} from "rxjs/operators";
import {
  formatDateObject
} from "../../../utils/utils";

class ProjectAccrualCreateService {
  utilizationSummaryDataLoading = new BehaviorSubject(false);

  apiUrlConstants = {
    employeeFilterApiUrl: "/api/v1/employees/options_for_select",
    projectPlanFilterApiUrl: "/api/v1/project_plans/options_for_select",
    // projectFilterApiUrl: '/api/v1/project_plans/options_for_select',
    projectAccrualCreateApiUrl: "/api/v1/project_accrual/create",
    projectAccrualUpdateApiUrl: "/api/v1/project_accrual/PROJECT_ACCRUAL_ID/update",
    companyDepartmentOptions: "/api/v1/company_departments/options_for_select",
    getJiraProjectCodesUri: "/api/v1/jira_codes/options_for_select",
    getJiraProjectsUri: "/estimates/fetch_jira_projects",
    getProjectAccrualInfoUri: "/api/v1/project_accrual/PROJECT_ACCRUAL_ID/show",
    checkUniqueDataUri: "/api/v1/project_accrual/validations/check_for_uniqueness",
    getProjectPlanDatesUri: "/api/v1/project_accrual/ESTIMATE_ID/fetch_dates"
  };

  constructor() {}

  getEmployeeFilterOptions() {
    return Axios.get(this.apiUrlConstants.employeeFilterApiUrl).pipe(
      map(response => response.data.body)
    );
  }

  getProjectPlanFilterOptions() {
    let uri = this.apiUrlConstants.projectPlanFilterApiUrl;

    return Axios.get(uri, {params: { type: "wip"}}).pipe(
      map(response => response.data.body)
    );
  }
  checkDataIsUnique(data) {
    return Axios.post(this.apiUrlConstants.checkUniqueDataUri, data).pipe(
      map(response => response.data)
    );
  }

  // getProjectFilterOptions() {
  //   return Axios.get(this.apiUrlConstants.projectFilterApiUrl)
  //   .pipe(
  //     map(response => response.data.body)
  //   );
  // }

  getCompanyDepartmentOptions() {
    return Axios.get(this.apiUrlConstants.companyDepartmentOptions).pipe(
      map(response => response.data.body)
    );
  }

  createProjectAccrual(formData) {
    return Axios.post(this.apiUrlConstants.projectAccrualCreateApiUrl, {
      name: formData.name,
      contract_type: formData.contract_type,
      project_manager_id: formData.project_manager_id,
      account_manager_id: formData.account_manager_id,
      jira_code: formData.jira_code,
      estimate_id: formData.estimate_id,
      company_department_id: formData.company_department_id,
      start_date: formData.start_date,
      end_date: formData.end_date,
      budgeted_revenue: formData.budgeted_revenue,
    }).pipe(map(response => response.data));
  }

  updateProjectAccrual(projectId, formData) {
    let uri = this.apiUrlConstants.projectAccrualUpdateApiUrl.replace(
      "PROJECT_ACCRUAL_ID",
      projectId
    );
    let obj;
    if (formData.hasOwnProperty('active')) {
      obj = {
        'active': formData.active
      };
    } else {
      obj = {
        'name': formData.name ? formData.name : "",
        'contract_type': formData.contract_type ? formData.contract_type : 0,
        'project_manager_id': formData.project_manager_id ?
          formData.project_manager_id :
          "",
        'account_manager_id': formData.account_manager_id ?
          formData.account_manager_id :
          "",
        'jira_code': formData.jira_code ? formData.jira_code : "",
        'estimate_id': formData.estimate_id ? formData.estimate_id : "",
        'company_department_id': formData.company_department_id ?
          formData.company_department_id :
          "",
        'start_date': formData.start_date ? formData.start_date : "",
        'end_date': formData.end_date ? formData.end_date : "",
        'budgeted_revenue': formData.budgeted_revenue ? formData.budgeted_revenue : ""
      }
    }
    return Axios.post(uri, obj).pipe(map(response => response));
  }

  getJiraProjectCodes() {
    return Axios.get(this.apiUrlConstants.getJiraProjectCodesUri).pipe(
      map(response => response.data.body)
    );
  }

  getJiraProjects() {
    return Axios.get(this.apiUrlConstants.getJiraProjectsUri).pipe(
      map(response => response.data.results)
    );
  }

  getProjectAccrualInfo(projectId) {
    let uri = this.apiUrlConstants.getProjectAccrualInfoUri.replace(
      "PROJECT_ACCRUAL_ID",
      projectId
    );
    return Axios.get(uri).pipe(map(response => response.data));
  }

  getProjectPlanDates(estimateId) {
    if (estimateId) {
      let uri = this.apiUrlConstants.getProjectPlanDatesUri.replace(
        "ESTIMATE_ID",
        estimateId
      );
      return Axios.get(uri).pipe(map(response => response.data));
    }
  }
}

export default new ProjectAccrualCreateService();
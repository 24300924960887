import TasksPanel from '../tasks/tasks.vue'
import AccrualsReportPanel from '../accruals-report/accruals-report.vue'
import ForecastPanel from './forecast-panel/forecast-panel.vue'
import CriticalDatesPanel from '../critical-dates/critical-dates.vue'
import ResourceSummaryPanel from '../resource-summary/resource-summary.vue'
import ProjectSummaryPanel from '../project-summary/project-summary.vue'
import BurnReportPanel from './burn-report-panel/burn-report-panel.vue'
// import Sidebar from '../sidebar/sidebar.vue'
import Sidebar  from '../../components/sidebar/sidebar'
import ProjectAccrualCreate  from '../../finance/accruals/project-accrual-create/project-accrual-create'

export default {
  name: 'Dashboard',
  components: { TasksPanel, AccrualsReportPanel, ForecastPanel, CriticalDatesPanel, ResourceSummaryPanel, ProjectSummaryPanel, BurnReportPanel, Sidebar, ProjectAccrualCreate},

  created: function() {
    var vm        = this;
    vm.initDateRangePicker();
    // vm.getGsheetUrl();
    vm.check_autherization();

    vm.$root.$on('projectSynced', () => {
      vm.$root.$emit('updateDashboard');
    });
  },
  mounted() {
    this.getGsheetUrl();
    this.loadProjectSummaryData();
    this.check_autherization();
  },
  methods: {
    initDateRangePicker: function(){
      var vm      = this;
      if ($('#project_dashboard_daterangepicker').length == 0) {
          return;
      }
      var picker  = $('#project_dashboard_daterangepicker');
      var start   = moment().startOf('week');
      var end     = moment().endOf('week');

      function cb(start, end, label) {
        var title = '';
        var range = '';

        // set the startDate and endDate filed of the vue component with updated dates
        vm.startDate  = start._d;
        vm.endDate    = end._d;

        if ((end - start) < 100 || label == 'Today') {
            title     = 'Today:';
            range     = start.format('MMM D');
        } else if (label == 'Yesterday') {
            title     = 'Yesterday:';
            range     = start.format('MMM D');
        } else {
            range     = start.format('MMM D') + ' - ' + end.format('MMM D');
        }
        picker.find('.m-subheader__daterange-date').html(range);
        picker.find('.m-subheader__daterange-title').html(title);
      }

      picker.daterangepicker({
          direction: mUtil.isRTL(),
          startDate: start,
          endDate: end,
          opens: 'left',
          ranges: {
              'This Week'   : [moment().startOf('week'), moment().endOf('week')],
              'Last Week'   : [moment().subtract(1, 'weeks').startOf('week'), moment().subtract(1, 'weeks').endOf('week')],
              'This Month'  : [moment().startOf('month'), moment().endOf('month')],
              'Last Month'  : [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
              'Lifetime'    : [this.projectLifetimeRange[0],this.projectLifetimeRange[1]]
          }
      }, cb);

      cb(start, end, '');

    },
    getGsheetUrl() {
      this.$http.get('/api/v1/project_plans/' + this.project_id + '/fetch_gsheet_url')
        .then(function (response) {
          if (typeof (response.body.body) == 'string')
            // get the gsheet edit url
            this.gsheet_url = response.body.body.replace("pubhtml", "edit");
          else
            this.gsheet_url = "";
        });
    },
    check_autherization() {
      this.$http.get('/authorization/api_autherization/check_permissions', {
        params: {
          page: "estimo, estimate",
          function: "create?"
        }
      })
        .then(function (response) {
          this.modify_permission = response.body.permit;
        });
    },
    loadProjectSummaryData() {
      this.$http.get('/api/v1/project_plans/' + this.project_id + '/project_summary')
        .then(function (response) {
          if (!response.body.body.error) {
            var result = response.body.body;
            this.project_name = result.project_name;
            this.jira_project_key = result.jira_project_key;
          }
        });
    },
    syncProject() {
      var vm = this;
      vm.dialogFormVisible = true;
      vm.syncActive = true;
      $('#pulsate-crazy-target').pulsate({ repeat: 2 });
      vm.$http.get('/api/v1/project_plans/' + this.project_id + '/sync_project_plan', {})
        .then(function (response) {
          var response_body = response.body.body;
          var status = response_body.success;
          var error = response_body.error;
          vm.loading = true;
          if (status == true) {
            bootstrapNotify('Project Plan sync successfull for ' + this.project_name, 'success');
            vm.syncActive = false;
            vm.dialogFormVisible = false;
            vm.loading = false;
            this.$root.$emit('projectSynced');
          }
          else if (error == "sync_running") {
            vm.syncActive = false;
            vm.dialogFormVisible = false;
            bootstrapNotify('Sync already in progress. Please try after some time.', 'error');
            vm.loading = false;
          }
          else {
            vm.syncActive = false;
            vm.dialogFormVisible = false;
            bootstrapNotify('T&M sheet could not be synced.', 'error');
            vm.loading = false;
          }
        });
    },
  },
  computed: {
    talent_forecast_url() {
      return "/talent_forecasts?nameFilters=" + this.project_id + "&reportTypeFilter=Project";
    },
    burn_report_url() {
      return "/project_burn_report#/" + this.project_id;
    },
    gantt_charts_url() {
      return "/estimates/" + this.project_id + "/gantt_charts#/";
    },
    dashboard_url() {
      return "/estimates/" + this.project_id + "#/";
    },
    jira_project_url() {
      return "//softwaysolutions.atlassian.net/browse/" + this.jira_project_key;
    },
    planning_url() {
      return "https://bitool-v2.softway.com/project-details/" + this.project_id;
    }
  },
  data() {
    return {
      startDate             : '',
      endDate               : '',
      project_id            : Number(window.location.pathname.split('/')[2]),
      jira_project_key      : '',
      syncActive            : false,
      dialogFormVisible     : false,
      project_name          : '',
      gsheet_url            : '',
      projectLifetimeRange  : [],
      modify_permission     : false,
      openProjectAccrualCreate: false,
      active_page: this.$route.name
    }
  },
  watch: {
    projectLifetimeRange: function(){
      if(this.projectLifetimeRange.length != 0)
        this.initDateRangePicker();
    },
    project_id(newVal, oldVal) {
      this.getGsheetUrl();
      this.loadProjectSummaryData();
    }
  }
}